import Action from "./Action";
import Route from "../Main/Route";
import {Serialization} from "../Main/Serializer";

export default class RouteIntermediatesChangeStartNumber implements Action {

    private readonly oldStartNumber: number;

    constructor(private route: Route, private newStartNumber: number) {
        this.oldStartNumber = route.getIntermediates().getStartNumber();
    }

    public apply() {
        this.route.getIntermediates().setStartNumber(this.newStartNumber);
    }

    public revert() {
        this.route.getIntermediates().setStartNumber(this.oldStartNumber);
    }

    public merge(newAction: Action): boolean {
        if(!(newAction instanceof RouteIntermediatesChangeStartNumber) || newAction.oldStartNumber !== this.newStartNumber) {
            return false;
        }

        if((this.newStartNumber > this.oldStartNumber) !== (newAction.newStartNumber > newAction.oldStartNumber)) {
            return false;
        }

        this.newStartNumber = newAction.newStartNumber;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldStartNumber: this.oldStartNumber,
            newStartNumber: this.newStartNumber,
        };
    }
}
