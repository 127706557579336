import Action from "./Action";
import {Coordinate as olCoordinate} from "ol/coordinate";
import RouteCoordinateMutationAction from "./RouteCoordinateMutationAction";
import RouteSideRoad from "../Main/RouteSideRoad";
import {Serialization} from "../Main/Serializer";

export default class RouteSideRoadCoordinateMutationAction implements Action {
    private mutationIndex: number;
    private oldSlice: olCoordinate[];
    private newSlice: olCoordinate[];

    constructor(readonly routeSideRoad: RouteSideRoad, oldCoordinates: olCoordinate[], readonly ensureFocused) {
        this.determineSlices(oldCoordinates, this.routeSideRoad.getCoordinates());
    }

    public apply(initial: boolean) {
        const coordinates = this.routeSideRoad.getCoordinates().slice();
        if (!initial) {
            coordinates.splice(this.mutationIndex, this.oldSlice.length, ...this.newSlice);
        }
        this.routeSideRoad.setCoordinates(coordinates, this.ensureFocused);
    }

    public revert() {
        const coordinates = this.routeSideRoad.getCoordinates().slice();
        coordinates.splice(this.mutationIndex, this.newSlice.length, ...this.oldSlice);
        this.routeSideRoad.setCoordinates(coordinates, this.ensureFocused);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    private determineSlices(a: olCoordinate[], b: olCoordinate[]) {
        const [i, j] = RouteCoordinateMutationAction.determineDiff(a, b);

        this.mutationIndex = i;
        this.oldSlice = a.slice(i, a.length - j);
        this.newSlice = b.slice(i, b.length - j);
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.routeSideRoad.intermediate.intermediates.route.id,
            index: this.routeSideRoad.intermediate.index,
            intermediateNumber: this.routeSideRoad.intermediate.getIntermediateNumber(),
            mutationIndex: this.mutationIndex,
            oldSlice: this.oldSlice,
            newSlice: this.newSlice,
            ensureFocused: this.ensureFocused,
        };
    }
}
