// https://inria.hal.science/inria-00518005/document
// https://github.com/jbuckmccready/CavalierContours

/**
 * We follow an adaption of the approach used in CavalierContours.
 * The input is:
 *  - a graph G = (V, E) of line segments, each segment being a straight line from `start` to `end`
 *  - an offset R
 *
 *  1. Generate raw offset polylines ROP from the input line segment graph.
 *    a. Starting at the graph `startPoint`, traverse the graph, always following the 'rightmost' (counter-clockwise)
 *       neighbouring edge, until a leaf in the graph is encountered. While traversing, compute raw offset segments
 *       by offsetting each edge.
 *    b. Trim/join adjacent raw offset segments to form each polyline.
 *
 *  2. Find all self-intersects within ROP. Also find intersects between the ROP and circles at the end vertex points at
 *     the leafs of the graph with radius equal to the offset R. Create a set of sliced polylines SROP by slicing ROP at
 *     all the found intersect points.
 *
 *  3. Discard all segments in SROP whose minimum distance to E is less than the offset R.
 *
 *  4. Stitch together the remaining SROPs (Not implemented here)
 */

import {
    ArcIntersection,
    ArcSegment,
    Circle, distancePointLines,
    Drawing, intersectArcs,
    IntersectionPointInfo,
    IntersectionPointType, intersectLineAndArc,
    intersectLines,
    LineSegment, normalize,
    Point,
    PolyLine, samePoint,
    Segment, Vector
} from "./DrawingGeometry";

export type GraphPoint = {
    x: number,
    y: number,
    neighbours: GraphPoint[],
    flags: Record<string, any>,
};

export function offset(startPoint: GraphPoint, width: number): Drawing {
    // Init flags
    bfsGraphVertices(startPoint, () => {});

    const graphEdges: LineSegment[] = [];
    bfsGraphEdges(startPoint, (p, n) => {
        graphEdges.push(new LineSegment({
            x: p.x,
            y: p.y,
        }, {
            x: n.x,
            y: n.y,
        }).setAttr('originPoint1', p).setAttr('originPoint2', n));
    });

    const graphEndPoints: Point[] = [];
    bfsGraphVertices(startPoint, p => {
        if (p.neighbours.length === 1) {
            graphEndPoints.push({
                x: p.x,
                y: p.y,
            });
        }
    });

    let drawing = convertGraphToOffsets(startPoint, width / 2);

    drawing = slice(drawing, graphEndPoints, width / 2);

    drawing = prune(drawing, graphEdges, width / 2);

    // drawing = stitch(drawing);

    return drawing;
}

function convertGraphToOffsets(startPoint: GraphPoint, offsetWidth: number): Drawing
{
    const drawing: Drawing = {
        polylines: [],
    };

    startPoint.flags.convertWalkPasses = -1;

    let i = 0;
    do {
        if (i++ > 10000) {
            throw new Error('Caught infinite loop in calculating polylines');
        }

        let polyLine;
        [polyLine, startPoint] = walkGraphToOffsetPolyline(startPoint, offsetWidth);
        drawing.polylines.push(polyLine);
    } while (startPoint.neighbours.filter(n => n.flags.convertWalkPasses === undefined || n.flags.convertWalkPasses < n.neighbours.length).length > 0);

    return drawing;
}

function walkGraphToOffsetPolyline(startPoint: GraphPoint, offsetWidth: number): [PolyLine, GraphPoint]
{
    const points = [startPoint];

    let previousPoint = null;
    let point = startPoint;
    let i = 0;
    while (true) {
        if (i++ > 10000) {
            throw new Error('Caught infinite loop in calculating polyline');
        }

        const candidates = point.neighbours.filter(n => n !== previousPoint && (n.flags.convertWalkPasses === undefined || n.flags.convertWalkPasses < n.neighbours.length));
        point.flags.convertWalkPasses = (point.flags.convertWalkPasses || 0) + 1;

        if (candidates.length === 0) {
            break;
        }

        previousPoint = point;

        point = candidates[0];
        points.push(point);

        // Move previous point to end
        const index = point.neighbours.indexOf(previousPoint);
        if (index > -1) {
            point.neighbours.splice(index, 1);
            point.neighbours.push(previousPoint);
        }
    }
    const lastGraphPoint = point;

    const tempLineSegments = <LineSegment[]>[];
    for (let i = 0; i < points.length - 1; i++) {
        tempLineSegments.push(computeLineSegment(points[i], points[i + 1], offsetWidth));
    }

    let lastPoint: Point = tempLineSegments[0].start;
    const segments = <Segment[]>[];
    for (let i = 0; i < tempLineSegments.length - 1; i++) {
        const segment1 = tempLineSegments[i];
        const segment2 = tempLineSegments[i + 1];

        const intersectionPointInfo = <IntersectionPointInfo>{};
        const intersectionPoint = intersectLines(segment1, segment2, intersectionPointInfo);

        if (samePoint(segment1.end, segment2.start)) {
            segments.push(new LineSegment(lastPoint, segment1.end).attrsFrom(segment1));
            lastPoint = segment1.end;
            continue;
        }

        if (intersectionPoint === null) {
            const v1 = segment1.vector();
            const v2 = segment2.vector();

            // Check base line going straight back
            if (v1.dy * v2.dy < 0 || v1.dx * v2.dx < 0) {
                segments.push(new LineSegment(lastPoint, segment1.end).attrsFrom(segment1));
                segments.push(ArcSegment.betweenLineSegments(segment1, segment2).setAttr('arcOriginPoint', segment1.getAttr('originPoint2')));
                lastPoint = segment2.start;
                continue;
            }

            throw new Error('Unexpected null intersection point');
        }

        if (intersectionPointInfo.type1 === IntersectionPointType.TIP && intersectionPointInfo.type2 === IntersectionPointType.TIP) {
            segments.push(new LineSegment(lastPoint, intersectionPoint).attrsFrom(segment1));
            lastPoint = intersectionPoint;
            continue;
        }

        if (intersectionPointInfo.type1 !== IntersectionPointType.TIP && intersectionPointInfo.type2 !== IntersectionPointType.TIP) {
            if (intersectionPointInfo.type1 === IntersectionPointType.PFIP) {
                segments.push(new LineSegment(lastPoint, segment1.end).attrsFrom(segment1));
                segments.push(ArcSegment.betweenLineSegments(segment1, segment2).setAttr('arcOriginPoint', segment1.getAttr('originPoint2')));
                lastPoint = segment2.start;
            } else {
                segments.push(new LineSegment(lastPoint, segment1.end).attrsFrom(segment1));
                segments.push(new LineSegment(segment1.end, segment2.start));
                lastPoint = segment2.start;
            }
            continue;
        }

        segments.push(new LineSegment(lastPoint, segment1.end).attrsFrom(segment1));
        segments.push(new LineSegment(segment1.end, segment2.start));
        lastPoint = segment2.start;
    }

    const lastSegment = tempLineSegments[tempLineSegments.length - 1];
    segments.push(new LineSegment(lastPoint, lastSegment.end).attrsFrom(lastSegment));

    const polyline = <PolyLine>{
        segments: segments,
    };

    return [polyline, lastGraphPoint];
}

function computeLineSegment(point1: GraphPoint, point2: GraphPoint, offsetWidth: number): LineSegment {
    const vector: Vector = {
        dx: point2.x - point1.x,
        dy: point2.y - point1.y,
    };
    const perpendicular = normalize({
        dx: vector.dy,
        dy: -vector.dx,
    });

    return new LineSegment({
        x: point1.x + perpendicular.dx * offsetWidth,
        y: point1.y + perpendicular.dy * offsetWidth,
    }, {
        x: point2.x + perpendicular.dx * offsetWidth,
        y: point2.y + perpendicular.dy * offsetWidth,
    }).setAttr('originPoint1', point1).setAttr('originPoint2', point2);
}

function slice(rawDrawing: Drawing, graphEndPoints: Point[], offsetWidth: number): Drawing {
    const slicedDrawing: Drawing = {
        polylines: [],
    };

    for (const rawPolyline of rawDrawing.polylines) {
        const slicedSegments: Segment[] = [];

        for (const rawSegment of rawPolyline.segments) {
            const segmentSlices = sliceSegment(rawSegment, rawDrawing, graphEndPoints, offsetWidth);

            slicedSegments.push(...segmentSlices);
        }

        slicedDrawing.polylines.push({
            segments: slicedSegments
        });
    }

    return slicedDrawing;
}

function sliceSegment(segment: Segment, drawing: Drawing, graphEndPoints: Point[], offsetWidth: number): Segment[] {
    const intersections = [];

    for (const polyline of drawing.polylines) {
        for (const otherSegment of polyline.segments) {
            if (segment === otherSegment) {
                continue;
            }

            if (segment instanceof LineSegment && otherSegment instanceof LineSegment) {
                let intersectionPoint: Point|null;
                const intersectionPointInfo = <IntersectionPointInfo>{};

                intersectionPoint = intersectLines(segment, otherSegment, intersectionPointInfo);

                if (
                    intersectionPoint === null
                    || intersectionPointInfo.type === IntersectionPointType.FIP
                    || (samePoint(otherSegment.start, segment.end) && intersectionPointInfo.line2Frac < 10**-8)
                    || (samePoint(otherSegment.end, segment.start) && intersectionPointInfo.line1Frac < 10**-8)
                ) {
                    continue;
                }

                intersections.push({
                    point: intersectionPoint,
                    frac: intersectionPointInfo.line1Frac,
                });
            } else if (segment instanceof ArcSegment && otherSegment instanceof LineSegment) {
                for (const intersection of intersectLineAndArc(otherSegment, segment)) {
                    if (
                        (samePoint(otherSegment.start, segment.end) && intersection.segment1Frac < 10**-8)
                        || (samePoint(otherSegment.end, segment.start) && intersection.segment2Frac < 10**-8)
                    ) {
                        continue;
                    }

                    intersections.push({
                        point: intersection.point,
                        frac: intersection.segment2Frac,
                    });
                }
            } else if ((segment instanceof LineSegment || segment instanceof ArcSegment) && otherSegment instanceof ArcSegment) {
                const arcIntersections = segment instanceof LineSegment
                    ? intersectLineAndArc(segment, otherSegment)
                    : intersectArcs(segment, otherSegment);

                for (const intersection of arcIntersections) {
                    if (
                        (samePoint(otherSegment.start, segment.end) && intersection.segment2Frac < 10**-8)
                        || (samePoint(otherSegment.end, segment.start) && intersection.segment1Frac < 10**-8)
                    ) {
                        continue;
                    }

                    intersections.push({
                        point: intersection.point,
                        frac: intersection.segment1Frac,
                    });
                }
            } else {
                throw new Error('Unexpected segment composition');
            }
        }
    }

    for (const graphEndPoint of graphEndPoints) {
        let endIntersections: ArcIntersection[];
        if (segment instanceof LineSegment) {
            endIntersections = intersectLineAndArc(segment, new Circle(graphEndPoint, offsetWidth));
        } else if (segment instanceof ArcSegment) {
            endIntersections = intersectArcs(segment, new Circle(graphEndPoint, offsetWidth));
        } else {
            throw new Error();
        }

        for (const intersection of endIntersections) {
            intersections.push({
                point: intersection.point,
                frac: intersection.segment1Frac,
            })
        }
    }

    intersections.sort((a, b) => a.frac - b.frac);

    const slicedSegments: Segment[] = [];

    let lastPoint = segment.start;
    let usedArcAngle = 0;

    for (const intersection of intersections) {
        if (intersection.frac === 0 || intersection.frac === 1 || samePoint(lastPoint, intersection.point)) {
            continue;
        }

        if (segment instanceof LineSegment) {
            slicedSegments.push(new LineSegment(lastPoint, intersection.point).attrsFrom(segment));
        } else if (segment instanceof ArcSegment) {
            const caa = intersection.frac * segment.centralArcAngle - usedArcAngle;

            slicedSegments.push(Segment.create(lastPoint, intersection.point, caa).attrsFrom(segment));

            usedArcAngle += caa;
        } else {
            throw new Error();
        }

        lastPoint = intersection.point;
    }

    if (!samePoint(lastPoint, segment.end)) {
        if (segment instanceof LineSegment) {
            slicedSegments.push(new LineSegment(lastPoint, segment.end).attrsFrom(segment));
        } else if (segment instanceof ArcSegment) {
            slicedSegments.push(new ArcSegment(
                lastPoint,
                segment.end,
                segment.centralArcAngle - usedArcAngle
            ).attrsFrom(segment));
        } else {
            throw new Error();
        }
    }

    return slicedSegments;
}

function prune(slicedDrawing: Drawing, graphEdges: LineSegment[], offsetWidth: number): Drawing
{
    const prunedDrawing: Drawing = {
        polylines: [],
    };

    for (const slicedPolyline of slicedDrawing.polylines) {
        const prunedSegments: Segment[] = [];

        for (const slicedSegment of slicedPolyline.segments) {

            const pruneEdges = graphEdges.filter(graphLineSegment => {
                if (slicedSegment instanceof ArcSegment) {
                    if (
                        graphLineSegment.getAttr('originPoint1') === slicedSegment.getAttr('arcOriginPoint')
                        || graphLineSegment.getAttr('originPoint2') === slicedSegment.getAttr('arcOriginPoint')
                    ) {
                        return false;
                    }

                    return true;
                }

                if (
                    graphLineSegment.getAttr('originPoint1') === slicedSegment.getAttr('originPoint1')
                    && graphLineSegment.getAttr('originPoint2') === slicedSegment.getAttr('originPoint2')
                ) {
                    return false;
                }

                if (
                    graphLineSegment.getAttr('originPoint1') === slicedSegment.getAttr('originPoint2')
                    && graphLineSegment.getAttr('originPoint2') === slicedSegment.getAttr('originPoint1')
                ) {
                    return false;
                }

                return true;
            });

            if (pruneEdges.length === 0 || distancePointLines(slicedSegment.midPoint(), pruneEdges) >= offsetWidth) {
                prunedSegments.push(slicedSegment);
            }
        }

        prunedDrawing.polylines.push({
            segments: prunedSegments,
        });
    }

    return prunedDrawing;
}

let flagKeyValue = 0;
function bfsGraphVertices(startPoint: GraphPoint, callback: (p: GraphPoint) => boolean|void): void {
    const flagKey = '_flag' + flagKeyValue++;

    const backlog = [startPoint];
    const visited = [];
    while (backlog.length > 0) {
        const point = backlog.shift();

        if (!point.flags) {
            point.flags = {};
        }

        point.flags[flagKey] = true;
        visited.push(point);

        if (callback(point) === false) {
            continue;
        }

        point.neighbours.forEach((p => {
            if (!p.flags || !p.flags[flagKey]) {
                backlog.push(p);
            }
        }));
    }

    visited.forEach(p => delete p.flags[flagKey]);
}

function bfsGraphEdges(startPoint: GraphPoint, callback: (p: GraphPoint, n: GraphPoint) => boolean|void): void {
    bfsGraphVertices(startPoint, (p) => {
        p.neighbours.forEach(n => {
            if (callback(p, n) === false) {
                return false;
            }
        });
    });
}
