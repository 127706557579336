
export type WeightItem = {
    i: number,
    j: number,
    weight: number,
};

export default class Sparse2DDiscreteWeightSpan {

    private readonly nonzeroWeights: WeightItem[];

    constructor(
        private readonly n: number,
        private readonly weightFn: (a: number, b: number) => number
    ) {
        this.nonzeroWeights = [];

        for (let i = 0; i < n; i++) {
            for (let j = 0; j < i; j++) {
                const weight = weightFn(i, j);

                if (weight == 0) {
                    continue;
                }

                this.nonzeroWeights.push({
                    i: i,
                    j: j,
                    weight: weight,
                });
            }
        }
    }

    getMaxWeight(): WeightItem|null {
        let iMax = null, wMax = null;

        for (let i = 0; i < this.nonzeroWeights.length; i++) {
            if (wMax === null || this.nonzeroWeights[i].weight > wMax) {
                iMax = i;
                wMax = this.nonzeroWeights[i].weight;
            }
        }

        return iMax === null ? null : this.nonzeroWeights[iMax];
    }

    update(k: number) {
        const presentProducts = {};

        for (let wi = 0; wi < this.nonzeroWeights.length; wi++) {
            const weightItem = this.nonzeroWeights[wi];

            if (weightItem.i === k) {
                presentProducts[weightItem.j] = wi;
            }

            if (weightItem.j === k) {
                presentProducts[weightItem.i] = wi;
            }
        }

        const disappearedProducts = {};

        for (let m = 0; m < this.n; m++) {
            if (m === k) {
                continue;
            }

            const i = Math.max(k, m);
            const j = Math.min(k, m);

            const weight = this.weightFn(i, j);

            if (weight == 0) {
                if (typeof presentProducts[m] !== 'undefined') {
                    disappearedProducts[m] = true;
                }
            } else {
                if (typeof presentProducts[m] !== 'undefined') {
                    this.nonzeroWeights[presentProducts[m]].weight = weight;
                } else {
                    this.nonzeroWeights.push({
                        i: i,
                        j: j,
                        weight: weight,
                    });
                }
            }
        }

        for (let wi = this.nonzeroWeights.length - 1; wi >= 0; wi--) {
            const weightItem = this.nonzeroWeights[wi];

            if (weightItem.i === k && typeof disappearedProducts[weightItem.j] !== 'undefined') {
                this.nonzeroWeights.splice(wi, 1);
            }

            if (weightItem.j === k && typeof disappearedProducts[weightItem.i] !== 'undefined') {
                this.nonzeroWeights.splice(wi, 1);
            }
        }
    }
}
