import Coordinate from "../Coordinates/Coordinate";

export type Confidence = 'definite'|'possible';

export default class CoordinateParseResult<C extends Coordinate>
{
    constructor(readonly coordinate: C, readonly confidence: Confidence)
    {

    }
}
