import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import UserError from "../Util/UserError";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";

type GradenConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
};

export default class Graden extends ImageGeneratingTechnique<GradenConfig>
{
    public static readonly TECHNIQUE_NAME = 'graden';
    public static readonly TECHNIQUE_TITLE = 'Gradenroute';

    protected readonly requiresIntermediates = true;

    protected getDefaultConfig(): GradenConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.5,
            fillStyle: 'solid',
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        const lineHeight = 10; // mm

        const lines = intermediatesMeta.intermediateMetas.length;

        const height = lines * lineHeight + 10;
        const width = 40;
        const centerX = width / 2;

        const svg = createSvgEl('svg', {
            width: width,
            height: height,
            viewBox: "0 0 " + width + " " + height,
        });

        svg.appendChild(createSvgEl('line', {
            x1: centerX,
            y1: height - 10,
            x2: centerX,
            y2: 5,
            stroke: '#000000',
            strokeWidth: 0.5,
        }));

        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (intermediate.pathInitialBearingFormatted === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            const baseY = height - 10 - i * lineHeight;

            svg.appendChild(createSvgEl('circle', {
                cx: centerX,
                cy: baseY,
                r: 1,
                stroke: 'none',
                fill: '#000000',
            }));

            const text = createSvgEl('text', {
                x: centerX + 3,
                y: baseY,
                textAnchor: 'start',
                dominantBaseline: 'central',
            });
            text.textContent = intermediate.pathInitialBearingFormatted;
            text.style.font = '4px sans-serif';
            svg.appendChild(text);
        }

        // Arrow
        svg.appendChild(createSvgEl('line', {
            x1: centerX - 10,
            y1: height - 5,
            x2: centerX - 10,
            y2: height - 15,
            stroke: '#000000',
            strokeWidth: 0.5,
        }));

        svg.appendChild(createSvgEl('polyline', {
            points: [
                (centerX - 13) + ',' + (height - 12),
                (centerX - 10) + ',' + (height - 15),
                (centerX - 7) + ',' + (height - 12),
            ].join(' '),
            stroke: '#000000',
            strokeWidth: 0.5,
            fill: 'none',
        }));

        return svg;
    }
}
