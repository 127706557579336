import Action from "./Action";
import Cutout from "../Main/Cutout";
import Coordinate from "../Coordinates/Coordinate";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Grid from "../Main/Grid";
import OlProjection from "../Projection/OlProjection";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutCoordinateSystemAction implements Action {

    private readonly oldCoordinateSystem: CoordinateSystem<Coordinate>;
    private readonly oldZoom: number|null;
    private readonly newZoom: number|null;

    constructor(private cutout: Cutout<any, any, any>, private newCoordinateSystem: CoordinateSystem<Coordinate>) {
        this.oldCoordinateSystem = this.cutout.getProjection().coordinateSystem;

        const projection = this.cutout.getProjection();
        if (projection instanceof OlProjection) {
            const mip = projection.mapImageProvider;
            this.oldZoom = projection.getZoom();
            this.newZoom = this.oldZoom + mip.getZoomOffset(this.oldCoordinateSystem) - mip.getZoomOffset(this.newCoordinateSystem);
        }
    }

    public apply() {
        const projection = this.cutout.getProjection();

        projection.setCoordinateSystem(this.newCoordinateSystem);
        this.cutout.setGrid(new Grid(this.newCoordinateSystem));

        if (projection instanceof OlProjection) {
            projection.setZoom(this.newZoom);
        }
    }

    public revert() {
        const projection = this.cutout.getProjection();

        projection.setCoordinateSystem(this.oldCoordinateSystem);
        this.cutout.setGrid(new Grid(this.oldCoordinateSystem));

        if (projection instanceof OlProjection) {
            projection.setZoom(this.oldZoom);
        }
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldCoordinateSystem: this.oldCoordinateSystem.code,
            newCoordinateSystem: this.newCoordinateSystem.code,
            oldZoom: this.oldZoom,
            newZoom: this.newZoom,
        };
    }
}
