import Action from "./Action";
import RouteCoordinateMutationAction from "./RouteCoordinateMutationAction";

export default class RouteStartSketchAction extends RouteCoordinateMutationAction implements Action {

    public apply(initial: boolean) {
        super.apply(initial);

        if (initial) {
            return;
        }

        this.route.routeCollection.focusRoute(this.route);
    }

    public revert() {
        super.revert();

        this.route.routeCollection.unfocus();
    }

}
