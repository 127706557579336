import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";

type StripkaartConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
};

type IntermediateBox = {
    lines: [number, number][],
    height: number,
};

export default class Stripkaart extends ImageGeneratingTechnique<StripkaartConfig>
{
    public static readonly TECHNIQUE_NAME = 'stripkaart';
    public static readonly TECHNIQUE_TITLE = 'Stripkaart';

    protected readonly requiresIntermediates = true;

    static readonly MIN_BOX_HEIGHT = 10;
    static readonly BOX_MARGIN = 2;

    protected getDefaultConfig(): StripkaartConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.6,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const leftRights = this.computeSideRoadsLeftRight();

        this.checkSideRoads(leftRights);

        const intermediateBoxes: IntermediateBox[] = [];
        let totalHeight = 20;

        for (let i = 0; i < leftRights.length; i++) {
            const {left, right} = leftRights[i];

            const leftBox = this.computeIntermediateBox(-1, left);
            const rightBox = this.computeIntermediateBox(1, right);

            const box: IntermediateBox = {
                lines: [...leftBox.lines, ...rightBox.lines],
                height: Math.max(leftBox.height, rightBox.height),
            };

            intermediateBoxes.push(box);

            box.height = Math.max(box.height, Stripkaart.MIN_BOX_HEIGHT - 2 * Stripkaart.BOX_MARGIN);

            totalHeight += box.height + 2 * Stripkaart.BOX_MARGIN;
        }

        const width = 40;
        const height = Math.ceil(totalHeight);
        const centerX = width / 2;

        const svg = createSvgEl('svg', {
            width: width,
            height: height,
            viewBox: "0 0 " + width + " " + height,
        });

        svg.appendChild(createSvgEl('line', {
            x1: centerX,
            y1: height - 5,
            x2: centerX,
            y2: 5,
            stroke: '#000000',
            strokeWidth: 0.5,
        }));

        svg.appendChild(createSvgEl('circle', {
            cx: centerX,
            cy: height - 5,
            r: this.config.useRoughJs ? .5 : 2.5,
            stroke: this.config.useRoughJs ? '#000000' : 'none',
            strokeWidth: this.config.useRoughJs ? 3 : 0,
            fill: '#000000',
        }));

        let boxBottom = height - 10;
        for (const box of intermediateBoxes) {
            this.drawBox(svg, [centerX, boxBottom - Stripkaart.BOX_MARGIN - box.height / 2], box);

            boxBottom -= box.height + 2 * Stripkaart.BOX_MARGIN;
        }

        return svg;
    }

    private computeIntermediateBox(direction: -1|1, count: number): IntermediateBox {
        const baseBearing = direction === 1 ? 0 : 180;
        const deltaBearing = 180 / (count + 1);

        const intermediateBox: IntermediateBox = {
            lines: [],
            height: 0,
        };

        for (let i = 0; i < count; i++) {
            const bearing = baseBearing + (i + 1) * deltaBearing;

            const angle = (90 - bearing) / 180 * Math.PI;

            const x = 10 * Math.cos(angle);
            const y = 10 * Math.sin(angle);

            intermediateBox.lines.push([x, y]);

            intermediateBox.height = Math.max(intermediateBox.height, 2 * Math.abs(y));
        }

        return intermediateBox;
    }

    private drawBox(svg: SVGSVGElement, origin: [number, number], box: IntermediateBox): void {

        for (const [dx, dy] of box.lines) {
            svg.appendChild(createSvgEl('line', {
                x1: origin[0],
                y1: origin[1],
                x2: origin[0] + dx,
                y2: origin[1] + dy,
                stroke: '#000000',
                strokeWidth: 0.5,
            }));
        }
    }

    private checkSideRoads(leftRights: {left: number, right: number}[]): void {
        const intermediateKeysWithoutSideRoads = [];
        const intermediateKeysWithStraySideRoads = [];

        for (let i = 0; i < leftRights.length; i++) {
            const {left, right, stray} = leftRights[i];

            if (left === 0 && right === 0) {
                intermediateKeysWithoutSideRoads.push(i);
            }

            if (stray > 0) {
                intermediateKeysWithStraySideRoads.push(i);
            }
        }

        if (intermediateKeysWithoutSideRoads.length > 0) {
            const intermediates = this.getIntermediatesMeta();

            const intermediateNumbers = intermediateKeysWithoutSideRoads.map(i => intermediates.intermediateMetas[i].intermediateNumber);

            this.route.routeCollection.userInterface.toasts.addCategoricalToast(
                'routeTechniqueStripkaartMissingSideRoads',
                'Missende zijwegen',
                'Bij beslispunt(en) ' + intermediateNumbers.join(', ') + ' zijn geen zijwegen ingevoerd.'
            );
        }

        if (intermediateKeysWithStraySideRoads.length > 0) {
            const intermediates = this.getIntermediatesMeta();

            const intermediateNumbers = intermediateKeysWithStraySideRoads.map(i => intermediates.intermediateMetas[i].intermediateNumber);

            this.route.routeCollection.userInterface.toasts.addCategoricalToast(
                'routeTechniqueStripkaartStraySideRoads',
                'Kruisende zijwegen',
                'Beslispunt(en) ' + intermediateNumbers.join(', ') + ' bevat(ten) verlengde zijweg(en), kruisend met de route. Controleer de route en de resulterende stripkaart.'
            );
        }
    }
}
