import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Coordinate from "../Coordinates/Coordinate";
import MapImageProvider from "./MapImageProvider";

export default class EmptyMip implements MapImageProvider {

    constructor(
        readonly name: string,
        readonly title: string,
        private compatibleCoordinateSystems: CoordinateSystem<Coordinate>[],
        private defaultScale: number,
    ) {
    }

    getCopyright(): string {
        return '';
    }

    isCompatibleWithCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        for (const cs of this.compatibleCoordinateSystems) {
            if (cs.code === coordinateSystem.code) {
                return true;
            }
        }
        return false;
    }

    getCompatibleCoordinateSystems(): CoordinateSystem<Coordinate>[] {
        return this.compatibleCoordinateSystems;
    }

    getDefaultCoordinateSystem(): CoordinateSystem<any> {
        return this.compatibleCoordinateSystems[0];
    }

    getDefaultScale(): number {
        return this.defaultScale;
    }

    getBoundingPolygon<C extends Coordinate>(coordinateSystem: CoordinateSystem<C>): Promise<C[]|null> {
        return Promise.resolve(null);
    }

    downloadLegend() {
        alert('Could not find legend URL');
    }
}
