import CoordinateConverter from "../Util/CoordinateConverter";
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import {DutchGridSystem} from "../Coordinates/DutchGrid";
import {UTMSystem} from "../Coordinates/UTM";
import Container from "./Container";
import Wms from "../Projection/Wms";
import CutoutTemplate from "./CutoutTemplate";
import {A3L, A3P, A4L, A4P, A5L, A5P} from "../Util/Paper";
import WmsProjection from "../Projection/WmsProjection";
import Wmts from "../Projection/Wmts";
import WmtsProjection from "../Projection/WmtsProjection";
import {register} from "ol/proj/proj4";
import OlMip from "../Projection/OlMip";
import TileLayer from "ol/layer/Tile";
import {StadiaMaps} from "ol/source";
import OSM from "ol/source/OSM";
import EmptyMip from "../Projection/EmptyMip";
import {transformExtent} from "ol/proj";
import OlVectorMip from "../Projection/OlVectorMip";
import OlVectorProjection from "../Projection/OlVectorProjection";
import proj4 from "proj4";
import LuchtfotoMip from "../Projection/Specialized/LuchtfotoMip";

proj4.defs("EPSG:28992","+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.417,50.3319,465.552,-0.398957,0.343988,-1.8774,4.0725 +units=m +no_defs");
proj4.defs("EPSG:25832","+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

register(proj4);

CoordinateConverter.registerCoordinateSystem(new WGS84System());
CoordinateConverter.registerCoordinateSystem(new DutchGridSystem());
CoordinateConverter.registerCoordinateSystem(new UTMSystem(32, 1));

Container.registerSystemPaper(new A3L());
Container.registerSystemPaper(new A3P());
Container.registerSystemPaper(new A4L());
Container.registerSystemPaper(new A4P());
Container.registerSystemPaper(new A5L());
Container.registerSystemPaper(new A5P());

Container.registerMapImageProvider(new EmptyMip(
    'empty_mip',
    'Geen',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992', 'EPSG:25832']),
    25000,
));

// NL, https://www.pdok.nl/geo-services/-/article/basisregistratie-topografie-brt-topnl
Container.registerMapImageProvider(new Wms(
    'nl_kad_10',
    'Kadaster (NL) 1:10.000 TOP10NL',
    'https://service.pdok.nl/brt/top10nl/wms/v1_0',
    'Bron: Kadaster NL TOP10NL (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    10000,
    {
        CRS: 'EPSG:28992',
        layers: 'top10nl',
    }
));

// NL, https://www.pdok.nl/geo-services/-/article/dataset-basisregistratie-topografie-brt-topraster
Container.registerMapImageProvider(new Wms(
    'nl_kad_25',
    'Kadaster (NL) 1:25.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP25Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    25000,
    {
        CRS: 'EPSG:28992',
        layers: 'top25raster',
    }
).includesDrawnGrid(0, 1000, 0, 1000));

Container.registerMapImageProvider(new Wms(
    'nl_kad_50',
    'Kadaster (NL) 1:50.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP50Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    50000,
    {
        CRS: 'EPSG:28992',
        layers: 'top50raster',
    }
).includesDrawnGrid(0, 1000, 0, 1000).setDefaultDpi(270));

Container.registerMapImageProvider(new Wms(
    'nl_kad_100',
    'Kadaster (NL) 1:100.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP100Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    100000,
    {
        CRS: 'EPSG:28992',
        layers: 'top100raster',
    }
).includesDrawnGrid(0, 2000, 1000, 2000).setDefaultDpi(270));

Container.registerMapImageProvider(new Wms(
    'nl_kad_250',
    'Kadaster (NL) 1:250.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP250Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    250000,
    {
        CRS: 'EPSG:28992',
        layers: 'top250raster',
    }
).includesDrawnGrid(0, 10000, 5000, 10000).setDefaultDpi(270));

Container.registerMapImageProvider(new Wms(
    'nl_kad_500',
    'Kadaster (NL) 1:500.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP500Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    500000,
    {
        CRS: 'EPSG:28992',
        layers: 'top500raster',
    }
).includesDrawnGrid(10000, 25000, 0, 25000).setDefaultDpi(270));

Container.registerMapImageProvider(new Wms(
    'nl_kad_1000',
    'Kadaster (NL) 1:1.000.000',
    'https://service.pdok.nl/brt/topraster/wms/v1_0',
    'Bron: Kadaster NL TOP1000Raster (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    1000000,
    {
        CRS: 'EPSG:28992',
        layers: 'top1000raster',
    }
).setDefaultDpi(270));

// https://www.pdok.nl/-/nieuw-luchtfoto-2022-nu-beschikbaar-bij-pdok
// https://www.pdok.nl/introductie/-/article/luchtfoto-pdok
// https://opendata.beeldmateriaal.nl/
Container.registerMapImageProvider(new LuchtfotoMip(
    'nl_luchtfoto_beeldmateriaal_actueel_8_rgb_wmts',
    'Luchtfoto (Beeldmateriaal actueel 8cm RGB, voorjaar) (NL)',
    'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0',
    'Bron: pdok.nl & beeldmateriaal.nl (CC-BY)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    {
        tilematrixset: 'EPSG:28992',
        layer: 'Actueel_orthoHR',
    }
));

Container.registerMapImageProvider(new LuchtfotoMip(
    'nl_luchtfoto_beeldmateriaal_actueel_25_rgb_wmts',
    'Luchtfoto (Beeldmateriaal actueel 25cm RGB, zomer) (NL)',
    'https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0',
    'Bron: pdok.nl & beeldmateriaal.nl (CC-BY)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    {
        tilematrixset: 'EPSG:28992',
        layer: 'Actueel_ortho25',
    }
));

Container.registerMapImageProvider(new Wmts(
    'nl_luchtfoto_lufolabels_wmts',
    'Luchtfoto Labels (NL)',
    'https://service.pdok.nl/bzk/luchtfotolabels/wmts/v1_0',
    'Bron: pdok.nl & beeldmateriaal.nl (CC-BY)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    {
        tilematrixset: 'EPSG:28992',
        layer: 'lufolabels',
    }
));

Container.registerMapImageProvider(new Wmts(
    'nl_opentopo_wmts',
    'OpenTopo (NL) (Niet meer beschikbaar)',
    'https://geodata.nationaalgeoregister.nl/tiles/service/wmts',
    'Bron: J.W. van Aalst, www.opentopo.nl',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992']),
    {
        tilematrixset: 'EPSG:28992',
        layer: 'opentopo',
    }
).includesDrawnGrid((tileMatrixId: string) => {
    switch (tileMatrixId) {
        case '00': return null;
        case '01': return null;
        case '02': return null;
        case '03': return null;
        case '04': return null;
        case '05': return null;
        case '06': return {type: null, base_x: 0, delta_x: 10000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 10000, mul_y: 1, add_y: 0};
        case '07': return {type: null, base_x: 0, delta_x: 10000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 10000, mul_y: 1, add_y: 0};
        case '08': return {type: null, base_x: 0, delta_x: 10000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 10000, mul_y: 1, add_y: 0};
        case '09': return {type: null, base_x: 0, delta_x: 1000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 1000, mul_y: 1, add_y: 0};
        case '10': return {type: null, base_x: 0, delta_x: 1000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 1000, mul_y: 1, add_y: 0};
        case '11': return {type: null, base_x: 0, delta_x: 1000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 1000, mul_y: 1, add_y: 0};
        case '12': return {type: null, base_x: 0, delta_x: 1000, mul_x: 1, add_x: 0, base_y: 0, delta_y: 1000, mul_y: 1, add_y: 0};
        case '13': return null;
        case '14': return null;
        default: return null;
    }
}));

// DE RP, https://lvermgeo.rlp.de/de/geodaten-geoshop/opendata/

// Wanted to use EPSG:4258 (ETRS89) here as that is used in the original maps in germany. However,
// the WMS data is returned in a rectangular grid while the original maps obey the distortion introduced
// by the ETRS89 system; making the map 1mm smaller in both the top left and top right corner when compared to
// the bottom left and bottom right corner. The WMS point seems to return exactly the same area, but in a
// rectangular fashion instead of the trapezium(-ish?) shape we desire. This may distort our maps, so we do
// not (yet) do down that path.

// Instead, we choose to use EPSG:25832 (UTM) as our base, making the maps more like the dutch maps; rectangular maps
// containing a grid parallel to the map.
Container.registerMapImageProvider(new Wms(
    'de_rp_5',
    'Rheinland-Pfalz (DE) 1:5.000',
    'https://geo4.service24.rlp.de/wms/dtk5_rp.fcgi',
    '©GeoBasis-DE / LVermGeoRP'+((new Date()).getFullYear())+', dl-de/by-2-0, www.lvermgeo.rlp.de [RP DTK5]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    5000,
    {
        CRS: 'EPSG:25832',
        layers: 'rp_dtk5',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_rp_25',
    'Rheinland-Pfalz (DE) 1:25.000',
    'https://geo4.service24.rlp.de/wms/rp_dtk25.fcgi',
    '©GeoBasis-DE / LVermGeoRP'+((new Date()).getFullYear())+', dl-de/by-2-0, www.lvermgeo.rlp.de [RP DTK25]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    25000,
    {
        CRS: 'EPSG:25832',
        layers: 'rp_dtk25',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_rp_50',
    'Rheinland-Pfalz (DE) 1:50.000',
    'https://geo4.service24.rlp.de/wms/rp_dtk50.fcgi',
    '©GeoBasis-DE / LVermGeoRP'+((new Date()).getFullYear())+', dl-de/by-2-0, www.lvermgeo.rlp.de [RP DTK50]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    50000,
    {
        CRS: 'EPSG:25832',
        layers: 'rp_dtk50',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_rp_100',
    'Rheinland-Pfalz (DE) 1:100.000',
    'https://geo4.service24.rlp.de/wms/rp_dtk100.fcgi',
    '©GeoBasis-DE / LVermGeoRP'+((new Date()).getFullYear())+', dl-de/by-2-0, www.lvermgeo.rlp.de [RP DTK100]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    100000,
    {
        CRS: 'EPSG:25832',
        layers: 'rp_dtk100',
    }
));

// DE NRW, https://www.bezreg-koeln.nrw.de/geobasis-nrw/produkte-und-dienste/topographische-karten/aktuelle-topographische-karten
Container.registerMapImageProvider(new Wms(
    'de_nrw_10',
    'Nordrhein-Westfalen (DE) 1:10.000',
    'https://www.wms.nrw.de/geobasis/wms_nw_dtk10',
    'Quelle: NRW, dl-zero-de/2.0 [NRW DTK10]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    10000,
    {
        CRS: 'EPSG:25832',
        layers: 'nw_dtk10_col',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_nrw_25',
    'Nordrhein-Westfalen (DE) 1:25.000',
    'https://www.wms.nrw.de/geobasis/wms_nw_dtk25',
    'Quelle: NRW, dl-zero-de/2.0 [NRW DTK25]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    25000,
    {
        CRS: 'EPSG:25832',
        layers: 'nw_dtk25_col',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_nrw_50',
    'Nordrhein-Westfalen (DE) 1:50.000',
    'https://www.wms.nrw.de/geobasis/wms_nw_dtk50',
    'Quelle: NRW, dl-zero-de/2.0 [NRW DTK50]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    50000,
    {
        CRS: 'EPSG:25832',
        layers: 'nw_dtk50_col',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_nrw_100',
    'Nordrhein-Westfalen (DE) 1:100.000',
    'https://www.wms.nrw.de/geobasis/wms_nw_dtk100',
    'Quelle: NRW, dl-zero-de/2.0 [NRW DTK100]',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    100000,
    {
        CRS: 'EPSG:25832',
        layers: 'nw_dtk100_col',
    }
));

// DE Bayern, https://geodaten.bayern.de/opengeodata/
Container.registerMapImageProvider(new Wms(
    'de_by_10',
    'Bayern (DE) 1:10.000',
    'https://geoservices.bayern.de/od/wms/dtk/v1/dok',
    'Quelle: geodatenonline.bayern.de (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    10000,
    {
        CRS: 'EPSG:25832',
        layers: 'by_dok',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_by_25',
    'Bayern (DE) 1:25.000',
    'https://geoservices.bayern.de/od/wms/dtk/v1/dtk25',
    'Quelle: geodatenonline.bayern.de (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    25000,
    {
        CRS: 'EPSG:25832',
        layers: 'by_dtk25',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_by_50',
    'Bayern (DE) 1:50.000',
    'https://geoservices.bayern.de/od/wms/dtk/v1/dtk50',
    'Quelle: geodatenonline.bayern.de (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    50000,
    {
        CRS: 'EPSG:25832',
        layers: 'by_dtk50',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_by_100',
    'Bayern (DE) 1:100.000',
    'https://geoservices.bayern.de/od/wms/dtk/v1/dtk100',
    'Quelle: geodatenonline.bayern.de (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    100000,
    {
        CRS: 'EPSG:25832',
        layers: 'by_dtk100',
    }
));

Container.registerMapImageProvider(new Wms(
    'de_by_500',
    'Bayern (DE) 1:500.000',
    'https://geoservices.bayern.de/od/wms/dtk/v1/dtk500',
    'Quelle: geodatenonline.bayern.de (CC BY 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    500000,
    {
        CRS: 'EPSG:25832',
        layers: 'by_dtk500',
    }
));

// LU, https://data.public.lu/en/datasets/cartes-topographiques-services-wms-et-wmts/
Container.registerMapImageProvider(new Wms(
    'lu_topo_5',
    'Luxemburg (LU) 1:5.000',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    5000,
    {
        CRS: 'EPSG:25832',
        layers: 'topo_5k',
    }
));

Container.registerMapImageProvider(new Wms(
    'lu_topo_20',
    'Luxemburg (LU) 1:20.000',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    20000,
    {
        CRS: 'EPSG:25832',
        layers: 'topo_20k',
    }
));

Container.registerMapImageProvider(new Wms(
    'lu_topo_50',
    'Luxemburg (LU) 1:50.000',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    50000,
    {
        CRS: 'EPSG:25832',
        layers: 'topo_50k',
    }
));

Container.registerMapImageProvider(new Wms(
    'lu_topo_100',
    'Luxemburg (LU) 1:100.000',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    100000,
    {
        CRS: 'EPSG:25832',
        layers: 'topo_100k',
    }
));

Container.registerMapImageProvider(new Wms(
    'lu_topo_250',
    'Luxemburg (LU) 1:250.000',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    250000,
    {
        CRS: 'EPSG:25832',
        layers: 'topo_250k',
    }
));

Container.registerMapImageProvider(new Wms(
    'lu_topo_ortho_latest',
    'Luxemburg (LU) Luchtfoto',
    'https://wmts1.geoportail.lu/opendata/service',
    'Quelle: CC0 data.public.lu',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    25000,
    {
        CRS: 'EPSG:25832',
        layers: 'ortho_latest',
    }
));

const olBounds = {
    'EPSG:28992': {
        minX: -7000,
        maxX: 300000,
        minY: 289000,
        maxY: 629000,
    },
    'EPSG:25832': {
        minX: -1877994.66,
        maxX: 3932281.56,
        minY: 836715.13,
        maxY: 9440581.95,
    },
};

Container.registerMapImageProvider((new OlMip(
    'osm',
    'OpenStreetMap',
    () => [
        new TileLayer({
            source: new OSM(),
        }),
    ],
    '© OpenStreetMap.org bijdragers',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992', 'EPSG:25832']),
    olBounds,
    0,
    20
)).setHardTileLimit(250)); // https://operations.osmfoundation.org/policies/tiles/

Container.registerMapImageProvider(new OlMip(
    'stamen_watercolor',
    'Stamen Watercolor',
    () => [
        new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_watercolor',
            }),
        }),
    ],
    '© Tiles: Stadia Maps; Stamen Design (CC BY 3.0); Data: OpenStreetMap (CC BY SA)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992', 'EPSG:25832']),
    olBounds,
    0,
    18
));

Container.registerMapImageProvider(new OlMip(
    'stamen_terrain',
    'Stamen Terrain',
    () => [
        new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_terrain',
            }),
        }),
    ],
    '© Tiles: Stadia Maps; Stamen Design (CC BY 3.0); OpenMapTiles; Data: OpenStreetMap (ODbL)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992', 'EPSG:25832']),
    olBounds,
    0,
    18
));

Container.registerMapImageProvider(new OlMip(
    'stamen_toner',
    'Stamen Toner',
    () => [
        new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner',
            }),
        }),
    ],
    '© Tiles: Stadia Maps; Stamen Design (CC BY 3.0); OpenMapTiles; Data: OpenStreetMap (ODbL)',
    CoordinateConverter.getCoordinateSystems(['EPSG:28992', 'EPSG:25832']),
    olBounds,
    0,
    20
));

const basemapAtExtent = transformExtent([
    8.8587,
    46.3723,
    17.1608,
    49.0205,
], 'EPSG:4326', 'EPSG:25832');
// https://basemap.at/#lizenz
Container.registerMapImageProvider(new OlVectorMip(
    'austria_basemap_vector',
    'Österreich basemap.at vektor (AT)',
    [
        {
            tileUrl: 'https://maps.wien.gv.at/basemapv/bmapv/3857/tile/{z}/{y}/{x}.pbf',
            styleUrl: 'https://maps.wien.gv.at/basemapv/bmapv/3857/resources/styles/root.json'
        },
        {
            tileUrl: 'https://maps.wien.gv.at/basemapv/bmapvhl/3857/tile/{z}/{y}/{x}.pbf',
            styleUrl: 'https://maps.wien.gv.at/basemapv/bmapvhl/3857/resources/styles/root.json'
        }
    ],
    'Datenquelle: basemap.at (CC-BY- 4.0)',
    CoordinateConverter.getCoordinateSystems(['EPSG:25832']),
    {
        'EPSG:25832': {
            minX: basemapAtExtent[0], //8.8587,
            maxX: basemapAtExtent[2], //17.1608,
            minY: basemapAtExtent[1], //46.3723,
            maxY: basemapAtExtent[3], //49.0205,
        },
    },
    0,
    19
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(5, 52),
    new WGS84System(),
    new WmsProjection('nl_kad_25'),
    null,
    '(NL) Kadaster 1:25.000'
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(7, 51),
    new WGS84System(),
    new WmsProjection('de_nrw_25'),
    null,
    '(DE) Nordrhein-Westfalen 1:25.000'
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(7, 50),
    new WGS84System(),
    new WmsProjection('de_rp_25'),
    null,
    '(DE) Rheinland-Pfalz 1:25.000'
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(12, 49),
    new WGS84System(),
    new WmsProjection('de_by_25'),
    null,
    '(DE) Bayern 1:25.000'
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(6, 50),
    new WGS84System(),
    new WmsProjection('lu_topo_20'),
    null,
    '(LU) Luxemburg 1:20.000'
));

Container.registerSystemCutoutTemplate(new CutoutTemplate<any, any, any>(
    new A4L(),
    new WGS84(13, 47),
    new WGS84System(),
    new OlVectorProjection('austria_basemap_vector'),
    null,
    '(AT) Österreich Basemap 1:25.000'
));

Container.registerCustomCutoutTemplates();
