import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Coordinate from "../Coordinates/Coordinate";
import MapImageProvider from "./MapImageProvider";
import {Layer} from "ol/layer";
import OlMap from "ol/Map";
import View from "ol/View";
import {Projection} from "ol/proj";
import * as olExtent from 'ol/extent';
import {getForProjection as getTileGridForProjection} from "ol/tilegrid";
import {calculateSourceExtentResolution} from "ol/reproj";
import {getIntersection} from "ol/extent";
import OlTileLoader from "../Util/OlTileLoader";

import $ from "jquery";

export type Bounds = { minX: number, maxX: number, minY: number, maxY: number };

const MM_PER_INCH = 25.4;

export default class OlMip implements MapImageProvider {

    static readonly mapPixelWidth = 1000;
    private hardTileLimit: number|null = null;
    private basePixelsPerUnit: Record<string, number> = {};
    private zoomOffset: Record<string, number> = {};

    private static instanceCounter = 0;

    constructor(
        readonly name: string,
        readonly title: string,
        readonly olLayers: () => Layer<any>[],
        readonly copyright: string,
        private compatibleCoordinateSystems: CoordinateSystem<Coordinate>[],
        readonly bounds: Record<string, Bounds>,
        readonly minZoom: number,
        readonly maxZoom: number,
    ) {
    }

    public setHardTileLimit(limit: number) {
        this.hardTileLimit = limit;

        return this;
    }

    public getHardTileLimit(): number {
        return this.hardTileLimit;
    }

    getEmptyOlMap(coordinateSystem: CoordinateSystem<Coordinate>): OlMap {
        const bounds = this.bounds[coordinateSystem.code];

        return this.getOlMap(coordinateSystem, 0, [
            (bounds.minX + bounds.maxX) / 2,
            (bounds.minY + bounds.maxY) / 2,
        ], null);
    }

    getOlMap(coordinateSystem: CoordinateSystem<Coordinate>, zoom: number, center: number[], tileLoader: OlTileLoader|null): OlMap {
        const target = 'ol_mip_' + OlMip.instanceCounter++;

        const $olMip = $('<div class="invisible ol-mip" id="' + target + '"></div>');
        $olMip.width(OlMip.mapPixelWidth);
        $olMip.height(OlMip.mapPixelWidth);
        $('body').append($olMip);

        const projection = new Projection({
            code: coordinateSystem.code,
            extent: this.getExtent(coordinateSystem),
        });

        let layers = [];
        if (tileLoader) {
            layers = (this.olLayers)();
            layers.forEach((layer: Layer<any>) => {
                layer.getSource().setTileLoadFunction(tileLoader.getTileLoadFunction());
            });
        }

        return new OlMap({
            layers: layers,
            target: target,
            view: new View({
                projection: projection,
                center: center,
                zoom: zoom,
                constrainResolution: true,
            }),
        });
    }

    disposeOlMap(olMap: OlMap) {
        $(olMap.getTargetElement()).remove();
    }

    hasDrawnGrid(): boolean {
        return false;
    }

    getCopyright(): string {
        return this.copyright;
    }

    isCompatibleWithCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        for (const cs of this.compatibleCoordinateSystems) {
            if (cs.code === coordinateSystem.code) {
                return true;
            }
        }
        return false;
    }

    getCompatibleCoordinateSystems(): CoordinateSystem<Coordinate>[] {
        return this.compatibleCoordinateSystems;
    }

    getDefaultCoordinateSystem(): CoordinateSystem<any> {
        return this.compatibleCoordinateSystems[0];
    }

    getBasePixelsPerUnit(coordinateSystem: CoordinateSystem<Coordinate>): number {
        if (this.basePixelsPerUnit[coordinateSystem.code] !== undefined) {
            return this.basePixelsPerUnit[coordinateSystem.code];
        }

        const olMap = this.getEmptyOlMap(coordinateSystem);
        this.basePixelsPerUnit[coordinateSystem.code] = 1 / olMap.getView().getResolutionForZoom(0);
        this.disposeOlMap(olMap);

        return this.basePixelsPerUnit[coordinateSystem.code];
    }

    /**
     * The OlProjection.zoom property refers to the zoom of the target projection (i.e., the projection
     * used on the PDF map). This zoom in general is not the same as the zoom of the source projection
     * (i.e., projection used by Stamen & OSM). This function determines the difference, i.e. what source
     * projection zoom level is equivalent to target zoom level 0.
     */
    getZoomOffset(coordinateSystem: CoordinateSystem<Coordinate>): number {
        if (this.zoomOffset[coordinateSystem.code] !== undefined) {
            return this.zoomOffset[coordinateSystem.code];
        }

        const map = this.getEmptyOlMap(coordinateSystem);
        const targetView = map.getView();
        const layers = (this.olLayers)();

        const targetProjection = targetView.getProjection();
        const sourceProjection = layers[0].getSource().getProjection();
        const targetZoom = targetView.getZoom();
        const sourceTileGrid = getTileGridForProjection(sourceProjection);

        // Based on ReprojTile class and its call in TileImage::getTile()
        const tileRange = sourceTileGrid.getTileRangeForExtentAndZ(
            targetProjection.getExtent(),
            targetZoom
        );

        const targetTileGrid = getTileGridForProjection(targetProjection);
        const sourceResolution = calculateSourceExtentResolution(
            sourceProjection,
            targetProjection,
            getIntersection(
                targetTileGrid.getTileCoordExtent([
                    targetZoom,
                    tileRange.minX,
                    tileRange.minY,
                ]),
                targetTileGrid.getExtent(),
            ),
            targetView.getResolution()
        );

        this.zoomOffset[coordinateSystem.code] = sourceTileGrid.getZForResolution(sourceResolution) - targetZoom;
        this.disposeOlMap(map);

        return this.zoomOffset[coordinateSystem.code];
    }

    computeDpi(coordinateSystem: CoordinateSystem<Coordinate>, scale: number, zoom: number): number {
        const realMmPerPaperMm = scale;

        const realMmPerPx = 1000 / this.getBasePixelsPerUnit(coordinateSystem) / (2 ** zoom);

        const pxPerPaperMm = realMmPerPaperMm / realMmPerPx;

        return pxPerPaperMm * MM_PER_INCH;
    }

    getPxPerKm(coordinateSystem: CoordinateSystem<Coordinate>, zoom: number): number {
        const pxPerUnit = this.getBasePixelsPerUnit(coordinateSystem) * (2 ** zoom);

        return pxPerUnit * 1000;
    }

    private computeScale(coordinateSystem: CoordinateSystem<Coordinate>, dpi: number, zoom: number): number {
        const pxPerPaperMm = dpi / MM_PER_INCH;

        const realMmPerPx = 1000 / this.getBasePixelsPerUnit(coordinateSystem) / (2 ** zoom);

        const realMmPerPaperMm = pxPerPaperMm * realMmPerPx;

        return realMmPerPaperMm;
    }

    getZoomClosestToScale(coordinateSystem: CoordinateSystem<Coordinate>, scale: number, desiredDpi: number = 300): number {
        let bestZoom = null;
        let bestRatio = null;

        const zoomOffset = this.getZoomOffset(coordinateSystem);
        for (let zoom = this.minZoom; zoom <= this.maxZoom - zoomOffset; zoom++) {
            const dpi = this.computeDpi(coordinateSystem, scale, zoom);
            let ratio = dpi / desiredDpi;
            ratio = Math.max(ratio, 1/ratio);
            if (bestRatio === null || ratio < bestRatio) {
                bestRatio = ratio;
                bestZoom = zoom;
            }
        }

        return bestZoom;
    }

    getScaleClosestToZoom(coordinateSystem: CoordinateSystem<Coordinate>, zoom: number): number {
        const desiredDpi = 300;
        return this.computeScale(coordinateSystem, desiredDpi, zoom);
    }

    getZoomLevelList(coordinateSystem: CoordinateSystem<Coordinate>): number[] {
        const levels = [];

        const zoomOffset = this.getZoomOffset(coordinateSystem);
        for (let zoom = this.minZoom; zoom <= this.maxZoom - zoomOffset; zoom++) {
            levels.push(zoom);
        }

        return levels;
    }

    getExtent(coordinateSystem: CoordinateSystem<Coordinate>): olExtent.Extent {
        const bounds = this.bounds[coordinateSystem.code];

        return [
            bounds.minX,
            bounds.minY,
            bounds.maxX,
            bounds.maxY,
        ];
    }

    getBoundingPolygon<C extends Coordinate>(coordinateSystem: CoordinateSystem<C>): Promise<C[]|null> {
        return Promise.resolve(null);
    }

    downloadLegend() {
        alert('Could not find legend URL');
    }
}
