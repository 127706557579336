import {findMinLexicographicalLCS} from "@algorithm.ts/lcs";

export default function predictName(names: string[]): string|null {
    if (names.length === 0) {
        return null;
    } else if (names.length === 1) {
        return predictNextFromOne(names[0]);
    }

    let result;

    result = predictNextAfterTwo(names[names.length - 2], names[names.length - 1]);
    if (result) {
        return result;
    }

    if (names.length > 2) {
        result = predictNextAfterTwo(names[names.length - 3], names[names.length - 1]);
        if (result) {
            return result;
        }
    }

    result = predictNextFromOne(names[names.length - 1]);
    if (result) {
        return result;
    }

    if (names.length > 2) {
        result = predictNextAfterTwo(names[names.length - 3], names[names.length - 2]);
        if (result) {
            return result;
        }
    }

    return null;
}

function predictNextFromOne(name: string): string|null {
    const parts = split(name);

    for (let i = parts.length - 1; i >= 0; i--) {
        const prediction = attemptPredictInitial(parts[i]);
        if (prediction !== null) {
            parts[i] = prediction;
            return parts.join('');
        }
    }

    return null;
}

function predictNextAfterTwo(prevName: string, currName: string): string|null {
    const previous = split(prevName);
    const current = split(currName);

    const lcs = findMinLexicographicalLCS(previous.length, current.length, (x, y) => previous[x] === current[y]);

    for (let l = lcs.length - 1; l >= -1; l--) {
        const [i, j] = l > -1 ? lcs[l] : [-1, -1];
        if (i === previous.length - 1 || j === current.length - 1) {
            continue;
        }

        const prediction = attemptPredictSequential(previous[i + 1], current[j + 1]);
        if (prediction !== null) {
            let predictIndex = j + 1;
            let predictValue = prediction;

            // Start from the slice [j, j+1], and extend to left/right
            // as far as the strings are equal
            let p, q;
            let start = Math.max(0, j);
            for (
                p = i - 1, q = j - 1;
                p >= 0 && q >= 0 && previous[p] === current[q];
                p--, q--
            ) {
                start--;
            }

            let end = j + 2;
            for (
                p = i + 2, q = j + 2;
                p < previous.length && q < current.length;
                p++, q++
            ) {
                if (previous[p] === current[q]) {
                    end++;
                    continue;
                } else {
                    // Make sure e.g. 2.5.2 -> 3.0.0 will result in 3.0.1
                    const prediction = attemptPredictInitial(current[q]);
                    if (prediction !== null) {
                        predictIndex = q;
                        predictValue = prediction;
                        end++;
                        continue;
                    }
                }
                break;
            }

            current[predictIndex] = predictValue;

            const result = current.slice(start, end).join('').trim();
            if (result.length > predictValue.length || result.length >= currName.length) {
                return result;
            }
        }
    }

    return null;
}

function split(subject: string): string[] {
    const segments: string[] = subject.split(/((?:[a-zA-Z]+(?![a-zA-Z]))|(?:[0-9]+(?![0-9])))/);
    const parts: string[] = [];

    for (const segment of segments) {
        if (segment === '') {
            continue;
        }

        if (segment.match(/^[a-zA-Z]+$/)) {
            parts.push(segment);
        } else if (segment.match(/^[0-9]+$/)) {
            parts.push(segment);
        } else {
            const chars = segment.split('');
            for (const char of chars) {
                parts.push(char);
            }
        }
    }

    return parts;
}

function attemptPredictInitial(segment: string): string|null {
    if (segment === 'a') {
        return 'b';
    } else if (segment === 'A') {
        return 'B';
    }

    const c = segment.charCodeAt(0);
    if (48 <= c && c <= 57) {
        return incrementNumeric(segment);
    }

    return null;
}

function attemptPredictSequential(prev: string, curr: string): string|null {
    const ci = prev.toLowerCase().charCodeAt(0);
    const cj = curr.toLowerCase().charCodeAt(0);

    if (97 <= ci && ci <= 122 && 97 <= cj && cj <= 122 && curr === incrementAlpha(prev)) {
        return incrementAlpha(curr);
    }

    if (48 <= ci && ci <= 57 && 48 <= cj && cj <= 57 && curr === incrementNumeric(prev)) {
        return incrementNumeric(curr);
    }

    return null;
}

function incrementAlpha(str: string): string {
    const last = str.substring(str.length - 1);
    if (last === '') {
        return '';
    } else if (last === 'z' || last === 'Z') {
        const a = (last === 'z' ? 'a' : 'A');

        if (str.length === 1) {
            return a + a;
        } else {
            return incrementAlpha(str.substring(0, str.length - 1)) + a;
        }
    } else {
        return str.substring(0, str.length - 1) + String.fromCharCode(last.charCodeAt(0) + 1);
    }
}

function incrementNumeric(str: string): string {
    return '' + (+str + 1);
}
