import Action from "./Action";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class LocationChangeColorAction implements Action {

    private readonly oldColor: string;

    constructor(private location: Location, private newColor: string) {
        this.oldColor = this.location.getColor();
    }

    public apply() {
        this.location.setColor(this.newColor);
    }

    public revert() {
        this.location.setColor(this.oldColor);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            oldColor: this.oldColor,
            newColor: this.newColor,
        };
    }
}
