import {Fill, Icon, Style, Text} from 'ol/style';
import {Point} from 'ol/geom';
import {Coordinate as olCoordinate} from "ol/coordinate";
import {Feature} from "ol";
import {fromLonLat, toLonLat} from "ol/proj";
import {Serialization} from "./Serializer";
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import ConversionComposition from "../Conversion/ConversionComposition";
import CoordinateConverter from "../Util/CoordinateConverter";
import LocationCollection from "./LocationCollection";
import mapMarker from "../img/marker.svg";
import LocationMoveAction from "../ActionHistory/LocationMoveAction";
import {WGS84System} from "../Coordinates/WGS84";
import $ from "jquery";
import {toTurfPolygon} from "../Util/Math";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Coordinate from "../Coordinates/Coordinate";
import {getMarkerTextOffset, MarkerType} from "./Map";
import {reactive} from "vue";

export type LocationReactiveProps = {
    type: 'location',
    id: number,
    revision: number,
    name: string,
    color: string,
    markerType: MarkerType,
    drawMarker: boolean,
    drawName: boolean,
    visibleOnMap: boolean,
    quickEditingName: boolean,
};

export default class Location {

    static idIncrement: number = 0;

    reactiveProps: LocationReactiveProps;

    private static createColor = 'blue'; // Blue color from UserInterface.colors
    private static createMarkerType = MarkerType.MapMarker;

    readonly id: number;

    private oldCoordinate: olCoordinate = null;

    private markerType: MarkerType = Location.createMarkerType;
    private name = null;
    private color = Location.createColor;

    private drawMarker: boolean = true;
    private drawName: boolean = true;

    private styles;

    private mapFeature: Feature<any>;
    private listeners: Record<string, (() => void)[]> = {};

    private visibleOnMap = true;
    private hasMouseover = false;

    constructor(readonly locationCollection: LocationCollection, coordinate: olCoordinate|null = null) {
        this.id = Location.idIncrement++;

        this.reactiveProps = <LocationReactiveProps>reactive({
            type: 'location',
            id: this.id,
            revision: 0,
            name: this.name,
            color: this.color,
            markerType: this.markerType,
            drawMarker: this.drawMarker,
            drawName: this.drawName,
            visibleOnMap: this.visibleOnMap,
            quickEditingName: false,
        });

        this.initStyles();
        this.initInteraction(coordinate);
    }

    private initStyles() {
        this.styles = [];
        const map = this.locationCollection.userInterface.getMap();

        const downloaded = map.markerDownloaded();
        if (!downloaded) {
            this.styles = [new Style({
                image: new Icon({
                    anchor: [12, 41],
                    anchorXUnits: 'pixels',
                    anchorYUnits: 'pixels',
                    scale : 1,
                    opacity: 1,
                    src: mapMarker
                }),
            })];
        }

        const revision = this.reactiveProps.revision;
        map.getMapMarker(this.markerType, this.color, this.hasMouseover).then((icon) => {
            if (revision < this.reactiveProps.revision) {
                // May happen on loading workspace
                return;
            }

            const [offsetX, offsetY] = getMarkerTextOffset(this.markerType);

            this.styles = [new Style({
                image: icon,
                text: new Text({
                    text: this.name,
                    textAlign: 'left',
                    offsetX: offsetX,
                    offsetY: offsetY,
                    backgroundFill: new Fill({
                        color: 'rgba(255, 255, 255, 0.4)',
                    }),
                    padding: [2, 2, 2, 2],
                })
            })];

            this.mapFeature.changed();
        });
    }

    private initInteraction(coordinate: olCoordinate) {
        const styleFunction = (feature) => {
            if (!this.visibleOnMap) {
                return [new Style({})];
            }

            return this.styles.slice();
        }

        if (coordinate === null) {
            coordinate = this.locationCollection.userInterface.getMap().getCenter().toOpenLayersCoordinate();
        }

        this.mapFeature = new Feature({
            geometry: new Point(coordinate),
            location: this,
        });

        this.mapFeature.setStyle(styleFunction);
        this.triggerChanged(false);
    }

    on(key: string, callback: () => void) {
        if(this.listeners[key] === undefined) {
            this.listeners[key] = [];
        }
        this.listeners[key].push(callback);
    }

    off(key: string, callback: () => void) {
        if(this.listeners[key] === undefined) {
            return;
        }

        const index = this.listeners[key].indexOf(callback);
        if(index === -1) {
            return;
        }

        this.listeners[key].splice(index, 1);
    }

    trigger(key: string) {
        if(this.listeners[key] === undefined) {
            return;
        }

        for(const listener of this.listeners[key]) {
            listener();
        }
    }

    public addToMap()
    {
        this.locationCollection.mapSource.addFeature(this.mapFeature);
    }

    public removeFromMap()
    {
        this.mouseout(false);
        this.locationCollection.mapSource.removeFeature(this.mapFeature);
    }

    public updateAfterMove(): void
    {
        const newCoordinate = this.getCoordinate();

        if (this.oldCoordinate && newCoordinate[0] === this.oldCoordinate[0] && newCoordinate[1] === this.oldCoordinate[1]) {
            return;
        }

        this.triggerChanged(true);
    }

    public triggerChanged(addAction)
    {
        this.reactiveProps.revision++;

        if (addAction) {
            this.locationCollection.userInterface.actionHistory.addAction(new LocationMoveAction(this, this.oldCoordinate));
        }
        this.oldCoordinate = this.getCoordinate();

        this.trigger('change');
    }

    public getCoordinate(): olCoordinate
    {
        return this.mapFeature.getGeometry().getCoordinates();
    }

    public getGeometry()
    {
        return this.mapFeature.getGeometry();
    }

    public getFeature()
    {
        return this.mapFeature;
    }

    public getName()
    {
        return this.name;
    }

    public getColor()
    {
        return this.color;
    }

    public setCoordinate(coordinate: olCoordinate, addAction: boolean = false)
    {
        this.mapFeature.getGeometry().setCoordinates(coordinate);
        this.triggerChanged(addAction);
    }

    public setColor(color: null|string)
    {
        this.setColorAndMarkerType(color, undefined);
    }

    public getMarkerType(): MarkerType
    {
        return this.markerType;
    }

    public setMarkerType(markerType: MarkerType)
    {
        this.setColorAndMarkerType(undefined, markerType);
    }

    public setColorAndMarkerType(color: null|string|undefined, markerType: MarkerType|undefined)
    {
        if (color !== undefined) {
            this.reactiveProps.color = this.color = color;
            Location.createColor = color;
        }

        if (markerType !== undefined) {
            this.reactiveProps.markerType = this.markerType = markerType;
            Location.createMarkerType = markerType;
        }

        this.initStyles();
        this.mapFeature.changed();
    }

    public setName(name: string)
    {
        this.reactiveProps.name = this.name = name;
        this.initStyles();
        this.mapFeature?.changed();
    }

    public getDrawMarker(): boolean
    {
        return this.drawMarker;
    }

    public setDrawMarker(drawMarker: boolean)
    {
        this.reactiveProps.drawMarker = this.drawMarker = drawMarker;
    }

    public getDrawName(): boolean
    {
        return this.drawName;
    }

    public setDrawName(drawName: boolean)
    {
        this.reactiveProps.drawName = this.drawName = drawName;
    }

    serialize(): Serialization {
        return {
            name: this.name,
            color: this.color,
            markerType: this.markerType,
            coordinate: toLonLat(this.getCoordinate()),
            visibleOnMap: this.visibleOnMap,
            drawMarker: this.drawMarker,
            drawName: this.drawName,
        };
    }

    static unserialize(serialized: Serialization, locationCollection: LocationCollection): Location {
        const location = new Location(locationCollection);

        location.reactiveProps.name = location.name = serialized.name;
        location.setColorAndMarkerType(
            serialized.color,
            serialized.markerType !== undefined ? serialized.markerType : MarkerType.MapMarker
        );
        location.reactiveProps.visibleOnMap = location.visibleOnMap = serialized.visibleOnMap;
        location.setCoordinate(fromLonLat(serialized.coordinate));

        location.reactiveProps.drawMarker = location.drawMarker = serialized.drawMarker !== undefined ? serialized.drawMarker : true;
        location.reactiveProps.drawName = location.drawName = serialized.drawName !== undefined ? serialized.drawName : true;

        return location;
    }

    public toggleVisibility(visible: boolean = null) {
        if (visible === null) {
            visible = !this.visibleOnMap;
        }

        this.reactiveProps.visibleOnMap = this.visibleOnMap = visible;

        this.mapFeature.changed();
    }

    public isVisible(): boolean {
        return this.visibleOnMap;
    }

    public isMouseOver(): boolean {
        return this.hasMouseover;
    }

    public mouseover(notifyLayer: boolean = true) {
        this.hasMouseover = true;
        $('#location_' + this.id).addClass('hover');
        this.initStyles();
        if (notifyLayer) {
            this.mapFeature.changed();
        }
    }

    public mouseout(notifyLayer: boolean = true) {
        this.hasMouseover = false;
        $('#location_' + this.id).removeClass('hover');
        this.initStyles();
        if (notifyLayer) {
            this.mapFeature.changed();
        }
    }

    public getFormattedCoordinate(coordinateSystem: CoordinateSystem<Coordinate>, format: string): string
    {
        this.reactiveProps.revision; // Trigger vue update

        return Location.formatOlCoordinate(this.getCoordinate(), coordinateSystem, format);
    }

    public static formatOlCoordinate(sourceCoordinate: olCoordinate, coordinateSystem: CoordinateSystem<Coordinate>, format: string): string
    {
        const coordinate = CoordinateConverter.convert(
            (new WGS84System()).fromOpenLayersCoordinate(sourceCoordinate),
            coordinateSystem
        );
        const formats = coordinate.formats();

        if (formats[format] === undefined) {
            format = coordinate.defaultFormat();
        }

        return formats[format]();
    }

    public generateKmlNode(doc: XMLDocument): HTMLElement
    {
        // https://developers.google.com/kml/documentation/kml_tut
        // https://developers.google.com/kml/documentation/kmlreference

        const placemark = doc.createElement('Placemark');

        const placemarkName = doc.createElement('name');
        placemarkName.textContent = this.name;
        placemark.appendChild(placemarkName);

        const point = doc.createElement('Point');
        placemark.appendChild(point);

        const coordinates = doc.createElement('coordinates');
        const lonLat = toLonLat(this.getCoordinate());
        coordinates.textContent = lonLat[0] + ',' + lonLat[1];
        point.appendChild(coordinates);

        return placemark;
    }

    public generateGpxNode(doc: XMLDocument): HTMLElement
    {
        // http://www.topografix.com/gpx/1/1/

        const wpt = doc.createElement('wpt');

        const lonLat = toLonLat(this.getCoordinate());
        wpt.setAttribute('lat', lonLat[1].toString());
        wpt.setAttribute('lon', lonLat[0].toString());

        const name = doc.createElement('name');
        name.textContent = this.name;
        wpt.appendChild(name);

        return wpt;
    }

    private drawOnPdfComputations(cutout: Cutout<any, any, any>)
    {
        // First determine whether to draw the point
        const wgsSystem = CoordinateConverter.getCoordinateSystem('EPSG:4326');
        const paperCoordinateConversion = cutout.getProjection().paperCoordinateConversion();
        const toPaperCoord = new ConversionComposition(
            CoordinateConverter.conversion(wgsSystem, cutout.getProjection().coordinateSystem),
            paperCoordinateConversion
        );

        const paperPolygon = CoordinateConverter.convertPolygonUsingConversion(cutout.mapPolygonProjection, paperCoordinateConversion);
        const turfPaperPolygon = toTurfPolygon(paperPolygon);

        const paperCoordinate = toPaperCoord.convert(wgsSystem.make(...toLonLat(this.getCoordinate())));
        const inPolygon = booleanPointInPolygon([paperCoordinate.getX(), paperCoordinate.getY()], turfPaperPolygon);

        if (!inPolygon) {
            return null;
        }

        return {turfPaperPolygon, paperCoordinate};
    }

    public drawMarkerOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        if (!this.drawMarker) {
            return;
        }

        const computation = this.drawOnPdfComputations(cutout);
        if (computation === null) {
            return;
        }

        const {turfPaperPolygon, paperCoordinate} = computation;

        const MM_PER_INCH = 25.4;

        // Use a minimum decent DPI value
        const dpi = Math.max(144, cutout.getProjection().getDpi());

        const markerWidth = 5; // mm
        const pxPerPaperMm = dpi / MM_PER_INCH;
        const markerPxWidth = markerWidth * pxPerPaperMm;

        // Draw the marker
        const [image, imageSize] = this.locationCollection.userInterface.getMap().getImmediateColoredMarkerImage(this.markerType, this.color);

        const canvas = document.createElement('canvas');
        canvas.width = markerPxWidth;
        canvas.height = imageSize[1] / imageSize[0] * markerPxWidth;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        const png = canvas.toDataURL('image/png');

        const paperImageSize = [
            markerWidth,
            imageSize[1] / imageSize[0] * markerWidth,
        ];

        const offsetY = (
            this.markerType === MarkerType.LocationCircle
            || this.markerType === MarkerType.LocationCross
        ) ? paperImageSize[1] / 2: paperImageSize[1]

        doc.addImage(
            png,
            'PNG',
            paperCoordinate.getX() - paperImageSize[0] / 2,
            paperCoordinate.getY() - offsetY,
            paperImageSize[0],
            paperImageSize[1]
        );
    }

    public drawNameOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        if (!this.drawName) {
            return;
        }

        const computation = this.drawOnPdfComputations(cutout);
        if (computation === null) {
            return;
        }

        const {turfPaperPolygon, paperCoordinate} = computation;

        // Draw the name
        const fontSize = 8;
        const mmPerPt = 25.4 / 72;

        let x = paperCoordinate.getX();
        let y = paperCoordinate.getY();

        const strHeight = fontSize * mmPerPt;
        const strWidth = doc.getStringUnitWidth(this.name) * strHeight;

        if (this.drawMarker) {
            const [textOffsetX, textOffsetY] = getMarkerTextOffset(this.markerType);

            x += textOffsetX / 5;
            y += textOffsetY / 5;

            if (y - strHeight < cutout.options.margin_top_printable + cutout.options.margin_top_nonprintable) {
                y = cutout.options.margin_top_printable + cutout.options.margin_top_nonprintable + strHeight;
            }

            const textInPolygon = booleanPointInPolygon([x, y], turfPaperPolygon)
                && booleanPointInPolygon([x, y - strHeight], turfPaperPolygon)
                && booleanPointInPolygon([x + strWidth, y], turfPaperPolygon)
                && booleanPointInPolygon([x + strWidth, y - strHeight], turfPaperPolygon);

            if (!textInPolygon) {
                x -= textOffsetX / 5 * 2 + strWidth;
            }
        } else {
            x -= 0.5 * strWidth;
            y += 0.5 * strHeight;
        }

        doc.setFontSize(fontSize);
        doc.setDrawColor(255, 255, 255);
        doc.setTextColor(0, 0, 0);

        doc.saveGraphicsState();
        doc.setGState(new doc.GState({opacity: 0.9}));
        doc.context2d.strokeText(this.name, x, y);
        doc.restoreGraphicsState();

        doc.text(this.name, x, y);
    }
}
