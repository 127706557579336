import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import Sparse2DDiscreteWeightSpan from "../Util/Sparse2DDiscreteWeightSpan";
import {IntermediateMeta, IntermediatesMeta} from "../Main/RouteIntermediates";
import {Point, randomInt, toTurfPolygon} from "../Util/Math";
import intersect from "@turf/intersect";
import {polygonArea} from "geometric/src/polygons/polygonArea";
import {Options} from "roughjs/bin/core";
import UserError from "../Util/UserError";
import {createSvgEl} from "./Util/Svg";
import {toLonLat} from "ol/proj";

type HelikopterConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    displayScale: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
    rotation: number,
};

type Net = {
    intermediates: IntermediateMeta[],
    arrowProps: ArrowProps,
};

type ArrowProps = {
    distance: number,
    angle: number,
    tipX: number,
    tipY: number,
    lineStartX: number,
    lineStartY: number,
    arrowLeftX: number,
    arrowLeftY: number,
    arrowRightX: number,
    arrowRightY: number,
    textX: number,
    textY: number,
};

export default class Helikopter extends ImageGeneratingTechnique<HelikopterConfig>
{
    public static readonly TECHNIQUE_NAME = 'helikopter';
    public static readonly TECHNIQUE_TITLE = 'Helikopterroute';

    protected readonly requiresIntermediates = true;

    static readonly CIRCLE_RADIUS = 0.015;

    protected getDefaultConfig(): HelikopterConfig {
        return {
            format: 'png',
            transparent: false,
            displayScale: true,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
            rotation: randomInt(0, 360),
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 1.5,
            fillStyle: 'solid',
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        let maxD = 0;
        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (intermediate.directDistance > maxD) {
                maxD = intermediate.directDistance;
            }
        }

        const imageSize = 2.2 * maxD; // Twice radius + 10% padding
        const center = imageSize / 2;

        const svg = createSvgEl('svg', {
            width: '1000px',
            height: '1000px',
            viewBox: "0 0 " + imageSize + " " + imageSize,
        });

        this.drawNorthArrow(svg, maxD, center);

        svg.appendChild(createSvgEl('circle', {
            cx: center,
            cy: center,
            r: Helikopter.CIRCLE_RADIUS * maxD,
            stroke: '#000000',
            strokeWidth: 0.01 * maxD,
            fill: 'none',
        }));

        const nets = this.computeNets(intermediatesMeta, maxD, center);

        nets.sort((a: Net|null, b: Net|null) => {
            if (a === null && b === null) {
                return 0;
            }

            if (a === null) {
                return 1;
            }

            if (b === null) {
                return -1;
            }

            return -(a.arrowProps.distance - b.arrowProps.distance);
        });

        for (const net of nets) {
            if (net === null) {
                continue;
            }

            const arrowProps = net.arrowProps;

            svg.appendChild(createSvgEl('line', {
                x1: arrowProps.lineStartX,
                y1: arrowProps.lineStartY,
                x2: arrowProps.tipX,
                y2: arrowProps.tipY,
                stroke: '#000000',
                strokeWidth: 0.01 * maxD,
            }));

            svg.appendChild(createSvgEl('polyline', {
                points: [
                    arrowProps.arrowLeftX + ',' + arrowProps.arrowLeftY,
                    arrowProps.tipX + ',' + arrowProps.tipY,
                    arrowProps.arrowRightX + ',' + arrowProps.arrowRightY,
                ].join(' '),
                stroke: '#000000',
                strokeWidth: 0.01 * maxD,
                fill: 'none',
            }));
        }

        for (const net of nets) {
            if (net === null) {
                continue;
            }

            const arrowProps = net.arrowProps;

            const angle = (arrowProps.angle + 360) % 360;

            const text = createSvgEl('text', {
                x: arrowProps.textX,
                y: arrowProps.textY,
                textAnchor: angle < -45 || angle > 135 ? 'end' : 'start',
                dominantBaseline: 'central',
                color: 'black',
                stroke: 'white',
                strokeWidth: (maxD * 0.002),
            });
            text.textContent = net.intermediates.map(intermediate => intermediate.intermediateNumber).sort((a, b) => a - b).join(',');
            text.style.font = (maxD * 0.07) + 'px sans-serif';
            svg.appendChild(text);
        }

        if (this.config.displayScale) {
            let scaleWidth = 10 ** Math.floor(Math.log10(maxD / 4));
            if (scaleWidth < maxD / 20) {
                scaleWidth *= 5;
            } else if (scaleWidth < maxD / 8) {
                scaleWidth *= 2;
            }

            const scaleRef = imageSize - 0.1 * maxD;
            svg.appendChild(createSvgEl('polyline', {
                points: [
                    (scaleRef - scaleWidth) + ',' + (scaleRef - 0.02 * maxD),
                    (scaleRef - scaleWidth) + ',' + (scaleRef),
                    (scaleRef) + ',' + (scaleRef),
                    (scaleRef) + ',' + (scaleRef - 0.02 * maxD),
                ].join(' '),
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.005 * maxD,
            }));

            const scaleText = createSvgEl('text', {
                x: scaleRef - 0.5 * scaleWidth,
                y: scaleRef - 0.01 * maxD,
                textAnchor: 'middle',
                fill: '#000000',
            });
            scaleText.textContent = scaleWidth + 'm';
            scaleText.style.font = (maxD * 0.04) + 'px sans-serif';
            svg.appendChild(scaleText);
        }

        this.route.routeCollection.userInterface.toasts.addRouteTechniqueScaleToast('Helikopterroute', imageSize);

        return svg;
    }

    drawNorthArrow(svg: SVGSVGElement, maxD: number, center: number) {
        const c = Math.cos(this.config.rotation / 180 * Math.PI);
        const s = Math.sin(this.config.rotation / 180 * Math.PI);

        const to = [
            center + 0.5 * maxD * s,
            center - 0.5 * maxD * c,
        ];

        this.drawDoubleArrow(svg, [center, center], to, {
            stroke: '#aaaaaa',
            strokeWidth: 0.007 * maxD,
            fill: 'none',
        }, {
            closedBase: false,
            arrowWidth: Helikopter.CIRCLE_RADIUS * maxD,
        });
    }

    computeNets(intermediatesMeta: IntermediatesMeta, maxD: number, center: number): Net[] {
        const computeArrowProps = (intermediates: IntermediateMeta[]): ArrowProps => {
            let sumX = 0;
            let sumY = 0;

            for (const intermediate of intermediates) {
                const angle = 90 - (intermediate.directBearing + this.config.rotation);

                const tipUnitX = Math.cos(angle / 180 * Math.PI);
                const tipUnitY = -Math.sin(angle / 180 * Math.PI);

                const tipX = center + tipUnitX * intermediate.directDistance;
                const tipY = center + tipUnitY * intermediate.directDistance;

                sumX += tipX;
                sumY += tipY;
            }

            let tipX = sumX / intermediates.length;
            let tipY = sumY / intermediates.length;
            let distance = Math.sqrt((tipX - center) ** 2 + (tipY - center) ** 2);
            const angle = Math.atan2(-(tipY - center), tipX - center) / Math.PI * 180;

            const tipUnitX = (tipX - center) / distance;
            const tipUnitY = (tipY - center) / distance;

            distance = Math.max(distance, Helikopter.CIRCLE_RADIUS * 2 * maxD);
            tipX = center + tipUnitX * distance;
            tipY = center + tipUnitY * distance;

            const perpUnitX = -tipUnitY;
            const perpUnitY = tipUnitX;

            const arrowBaseX = tipX - tipUnitX * maxD * 0.08;
            const arrowBaseY = tipY - tipUnitY * maxD * 0.08;

            return {
                distance,
                angle,
                tipX,
                tipY,
                lineStartX: center + Helikopter.CIRCLE_RADIUS * maxD * tipUnitX,
                lineStartY: center + Helikopter.CIRCLE_RADIUS * maxD * tipUnitY,
                arrowLeftX: arrowBaseX + perpUnitX * maxD * 0.06,
                arrowLeftY: arrowBaseY + perpUnitY * maxD * 0.06,
                arrowRightX: arrowBaseX - perpUnitX * maxD * 0.06,
                arrowRightY: arrowBaseY - perpUnitY * maxD * 0.06,
                textX: tipX - tipUnitX * maxD * 0.02 + perpUnitX * maxD * 0.05,
                textY: tipY - tipUnitY * maxD * 0.02 + perpUnitY * maxD * 0.07,
            };
        };

        const nets: (Net|null)[] = [];

        for (const intermediate of intermediatesMeta.intermediateMetas) {
            if (intermediate.directBearing === null || intermediate.directDistance === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            nets.push({
                intermediates: [intermediate],
                arrowProps: computeArrowProps([intermediate])
            });
        }

        const dws = new Sparse2DDiscreteWeightSpan(nets.length, (a, b) => {
            if (nets[a] === null || nets[b] === null) {
                return 0;
            }

            for (const intermediateA of nets[a].intermediates) {
                for (const intermediateB of nets[b].intermediates) {
                    if ((Math.abs(intermediateA.directBearing - intermediateB.directBearing) + 360) % 360 > 10) {
                        return 0;
                    }
                }
            }

            const arrowPropsA = nets[a].arrowProps;
            const arrowPropsB = nets[b].arrowProps;

            const intersection = intersect(toTurfPolygon([
                new Point(...toLonLat([arrowPropsA.arrowLeftX, arrowPropsA.arrowLeftY])),
                new Point(...toLonLat([arrowPropsA.tipX, arrowPropsA.tipY])),
                new Point(...toLonLat([arrowPropsA.arrowRightX, arrowPropsA.arrowRightY])),
            ]), toTurfPolygon([
                new Point(...toLonLat([arrowPropsB.arrowLeftX, arrowPropsB.arrowLeftY])),
                new Point(...toLonLat([arrowPropsB.tipX, arrowPropsB.tipY])),
                new Point(...toLonLat([arrowPropsB.arrowRightX, arrowPropsB.arrowRightY])),
            ]));

            if (intersection === null || intersection.geometry.coordinates.length !== 1) {
                return 0;
            }

            return polygonArea(intersection.geometry.coordinates[0]);
        });

        while (true) {
            const weightItem = dws.getMaxWeight();
            if (weightItem === null) {
                break;
            }

            const netI = nets[weightItem.i];
            const netJ = nets[weightItem.j];

            for (const intermediate of netJ.intermediates) {
                netI.intermediates.push(intermediate);
            }
            nets[weightItem.j] = null;

            netI.arrowProps = computeArrowProps(netI.intermediates);

            dws.update(weightItem.i);
            dws.update(weightItem.j);
        }

        return nets;
    }
}
