import Action from "./Action";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class LocationAddAction implements Action {

    constructor(readonly location: Location, readonly position: number) {

    }

    public apply() {
        this.location.locationCollection.attachLocation(this.location, this.position);
    }

    public revert() {
        this.location.locationCollection.detachLocation(this.location);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            position: this.position,
        };
    }
}
