import Action from "./Action";
import Route from "../Main/Route";
import {Serialization} from "../Main/Serializer";

export default class RouteDeleteAction implements Action {

    readonly position: number;

    constructor(readonly route: Route) {
        this.position = route.routeCollection.getRoutes().indexOf(route);

        if(this.position === -1) {
            throw new Error('Invalid position');
        }
    }

    public apply() {
        this.route.routeCollection.detachRoute(this.route);
    }

    public revert() {
        this.route.routeCollection.attachRoute(this.route, this.position);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            position: this.position,
        };
    }
}
