import Action from "./Action";
import Cutout from "../Main/Cutout";
import Coordinate from "../Coordinates/Coordinate";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import {Serialization} from "../Main/Serializer";

export default class MoveCutoutAction<C extends Coordinate & OLConvertibleCoordinate> implements Action {

    private readonly oldCoordinate: C;

    constructor(private cutout: Cutout<C, any, any>, private newCoordinate: C) {
        this.oldCoordinate = this.cutout.anchorWorkspaceCoordinate;
    }

    public apply() {
        this.cutout.setAnchorWorkspaceCoordinate(this.newCoordinate);
        this.cutout.updateMap();
    }

    public revert() {
        this.cutout.setAnchorWorkspaceCoordinate(this.oldCoordinate);
        this.cutout.updateMap();
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldCoordinate: [this.oldCoordinate.getX(), this.oldCoordinate.getY()],
            newCoordinate: [this.newCoordinate.getX(), this.newCoordinate.getY()],
        };
    }
}
