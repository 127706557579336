import CoordinateConverter from "../Util/CoordinateConverter";
import Location from "../Main/Location";
import {SheetData} from "write-excel-file";
import {IntermediatesMeta} from "../Main/RouteIntermediates";
import {Coordinate as olCoordinate} from "ol/coordinate";

export default class XlsxExport
{
    constructor(
        private readonly intermediatesMeta: IntermediatesMeta,
    ) {
    }

    public downloadXlsx()
    {
        const coordinateSystemCodes = [
            'EPSG:28992',
            'EPSG:25832',
            'EPSG:4326',
        ];

        const formatColumns = [];

        const dummyCoord = CoordinateConverter.getCoordinateSystem('EPSG:4326').make(0, 0);

        for (const code of coordinateSystemCodes) {
            const coordinateSystem = CoordinateConverter.getCoordinateSystem(code);
            const systemCoord = CoordinateConverter.convert(dummyCoord, coordinateSystem);
            const formats = systemCoord.formats();

            for (const format in formats) {
                formatColumns.push({
                    name: coordinateSystem.name + ' (' + format + ')',
                    formatter: (coordinate: olCoordinate) => {
                        try {
                            return Location.formatOlCoordinate(coordinate, coordinateSystem, format);
                        } catch (e) {
                            return '-';
                        }
                    }
                });
            }
        }

        const data: SheetData = [
            [
                {
                    value: 'Sectie',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
                {
                    value: 'Richting hemelsbreed',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
                {
                    value: 'Afstand hemelsbreed',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
                {
                    value: 'Startrichting route',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
                {
                    value: 'Afstand route',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
            ]
        ];
        const columnConfig = [{width: 20},{width: 20},{width: 20},{width: 20},{width: 20}];
        for (const column of formatColumns) {
            data[0].push({
                value: column.name,
                fontWeight: 'bold',
                backgroundColor: '#eeeeee',
                align: 'center',
            });
            columnConfig.push({width: 20});
        }

        if (this.intermediatesMeta.startCoordinate) {
            const row = [
                {value: 'Start'},
                {value: '-'},
                {value: '-'},
                {value: '-'},
                {value: '-'},
            ];
            for (const column of formatColumns) {
                row.push({value: column.formatter(this.intermediatesMeta.startCoordinate)});
            }
            data.push(row);
        }

        for (let i = 0; i < this.intermediatesMeta.intermediateMetas.length; i++) {
            const intermediateMeta = this.intermediatesMeta.intermediateMetas[i];
            const intermediateCoordinate = this.intermediatesMeta.intermediateCoordinates[i];

            const endName = i === this.intermediatesMeta.intermediateMetas.length - 1 ? 'Eind' : intermediateMeta.intermediateNumber + 1;

            const row = [
                {value: intermediateMeta.intermediateNumber + ' - ' + endName},
                {value: intermediateMeta.directBearingFormatted},
                {value: intermediateMeta.directDistanceFormatted},
                {value: intermediateMeta.pathInitialBearingFormatted},
                {value: intermediateMeta.pathDistanceFormatted},
            ];
            for (const column of formatColumns) {
                row.push({value: column.formatter(intermediateCoordinate)});
            }
            data.push(row);
        }

        if (this.intermediatesMeta.endCoordinate) {
            const row = [
                {value: 'Eind'},
                {value: '-'},
                {value: '-'},
                {value: '-'},
                {value: '-'},
            ];
            for (const column of formatColumns) {
                row.push({value: column.formatter(this.intermediatesMeta.endCoordinate)});
            }
            data.push(row);
        }

        import('write-excel-file').then(({default: writeXlsxFile}) => {
            return writeXlsxFile(data, {
                columns: columnConfig,
                fileName: 'route.xlsx',
            });
        })
    }
}
