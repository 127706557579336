import Action from "./Action";
import Cutout from "../Main/Cutout";
import OlProjection from "../Projection/OlProjection";
import OlVectorProjection from "../Projection/OlVectorProjection";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutOLZoomAction implements Action {

    private readonly oldZoom: number;

    constructor(private cutout: Cutout<any, any, any>, private newZoom: number) {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof OlProjection || projection instanceof OlVectorProjection)) {
            throw new Error('Can only change zoom for OpenLayers projection');
        }
        this.oldZoom = projection.getZoom();
    }

    public apply() {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof OlProjection || projection instanceof OlVectorProjection)) {
            throw new Error('Can only set zoom for OpenLayers projection');
        }
        projection.setZoom(this.newZoom);
    }

    public revert() {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof OlProjection || projection instanceof OlVectorProjection)) {
            throw new Error('Can only set zoom for OpenLayers projection');
        }
        projection.setZoom(this.oldZoom);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldZoom: this.oldZoom,
            newZoom: this.newZoom,
        };
    }
}
