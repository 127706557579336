import Action from "./Action";
import Route from "../Main/Route";
import RouteIntermediates from "../Main/RouteIntermediates";
import {Serialization} from "../Main/Serializer";

export default class RouteInitIntermediatesAction implements Action {

    private readonly intermediates: RouteIntermediates;

    constructor(readonly route: Route) {
        this.intermediates = new RouteIntermediates(route);
    }

    public apply() {
        this.route.setIntermediates(this.intermediates);
    }

    public revert() {
        this.route.setIntermediates(null);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
        };
    }
}
