import Action from "./Action";
import Route from "../Main/Route";
import {Serialization} from "../Main/Serializer";

export default class RouteChangeNameAction implements Action {

    private readonly oldName: string;

    constructor(private route: Route, private newName: string) {
        this.oldName = this.route.getName();
    }

    public apply() {
        this.route.setName(this.newName);
    }

    public revert() {
        this.route.setName(this.oldName);
    }

    public merge(newAction: Action): boolean {
        if(!(newAction instanceof RouteChangeNameAction) || newAction.oldName !== this.newName) {
            return false;
        }

        if((this.newName.length > this.oldName.length) !== (newAction.newName.length > newAction.oldName.length)) {
            return false;
        }

        this.newName = newAction.newName;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldName: this.oldName,
            newName: this.newName,
        };
    }
}
