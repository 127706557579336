<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      id="general_settings_modal"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Applicatie instellingen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" id="gsm_tabbar-tablist">
            <li class="nav-item">
              <a
                  class="nav-link active"
                  id="gsm_tabbar_general-tab"
                  role="tab"
                  data-bs-toggle="tab"
                  aria-controls="gsm_tabbar_general"
                  v-bind:data-bs-target="'#gsm_tabbar_general'"
              >Algemeen</a>
            </li>
            <li class="nav-item">
              <a
                  class="nav-link"
                  id="gsm_tabbar_paper-tab"
                  role="tab"
                  data-bs-toggle="tab"
                  aria-controls="gsm_tabbar_paper"
                  v-bind:data-bs-target="'#gsm_tabbar_paper'"
              >Papier formaten</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
                class="tab-pane show active"
                id="gsm_tabbar_general"
                role="tabpanel"
                aria-labelledby="gsm_tabbar_general-tab"
            >
              <div class="form-group">
                <label for="gsm_neighbour_overlap" class="form-label">Buurkaart overlap (mm)</label>
                <input type="number" min="0" class="form-control" id="gsm_neighbour_overlap" v-bind:value="neighbourOverlap">
              </div>
            </div>

            <div class="tab-pane"
                 id="gsm_tabbar_paper"
                 role="tabpanel"
                 aria-labelledby="gsm_tabbar_paper-tab"
            >

              <div class="table-responsive">
                <table class="table gsm-paper-list">
                  <thead>
                  <tr>
                    <th>Naam</th>
                    <th>Breedte (mm)</th>
                    <th>Hoogte (mm)</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="paper in paperList"
                      :key="paper.name"
                      v-bind:id="'gsm-paper-' + paper.name"
                  >
                    <td>{{ paper.title }}</td>
                    <td>{{ paper.width }}</td>
                    <td>{{ paper.height }}</td>
                    <td>
                      <div class="text-center" v-if="isCustomPaper(paper)">
                        <button class="btn btn-sm btn-outline-secondary" type="button" v-on:click="deletePaper(paper)">
                          <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr
                      v-if="addingPaper"
                      id="gsm-new-paper"
                  >
                    <td>
                      <input
                          class="form-control gsm-paper-title"
                          type="text"
                      />
                    </td>
                    <td>
                      <input
                          class="form-control gsm-paper-width"
                          type="number"
                          min="50"
                          max="2000"
                      />
                    </td>
                    <td>
                      <input
                          class="form-control gsm-paper-height"
                          type="number"
                          min="50"
                          max="2000"
                      />
                    </td>
                    <td class="text-center">
                      <div class="btn-group" role="group">
                        <button class="btn btn-sm btn-outline-secondary" type="button" v-on:click="cancelAdd">
                          <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-x-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>
                        </button>
                        <button class="btn btn-sm btn-primary" type="button" v-on:click="saveAdd">
                          <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-check-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr v-if="!addingPaper">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">
                      <button class="btn btn-sm btn-primary" type="button" v-on:click="addPaper">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                      </button>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Container from "../Main/Container";
import $ from "jquery";
import UserInterface from "../Main/UserInterface";
import Paper, {CustomPaper} from "../Util/Paper";
import {bsModal, strSlug} from "../Util/functions";
import {Tab} from "bootstrap";

export default {
  props: {
    listenId: String,
    userInterface: UserInterface,
  },
  data () {
    this.userInterface.on('storage-reset', () => {
      this.paperListRecomputeCounter++;
    });

    Container.getPaperList().on('paper-list-update', () => {
      this.paperListRecomputeCounter++;
    });

    $(() => {
      $('#' + this.listenId).on('click', (e, options) => {
        options = options || {};

        bsModal('#general_settings_modal').show();

        if(options.tab && ['paper'].indexOf(options.tab) > -1) {
          Tab.getOrCreateInstance(document.querySelector('#gsm_tabbar_' + options.tab + '-tab')).show();
        }
      });

      $('#gsm_neighbour_overlap').on('change keyup input blur', function() {
        const newOverlap = parseInt($(this).val());
        Container.setNeighbourOverlap(newOverlap);
      });

    });

    return {
      paperListRecomputeCounter: 0,
      container: Container,
      addingPaper: false,
    };
  },
  watch: {

  },
  computed: {
    paperList: function() {
      this.paperListRecomputeCounter;
      return Container.getPaperList().getPaperList();
    },
    neighbourOverlap: function() {
      return Container.getNeighbourOverlap();
    }
  },
  methods: {
    isCustomPaper(paper: Paper): boolean {
      return paper instanceof CustomPaper;
    },
    addPaper() {
      this.addingPaper = true;
    },
    cancelAdd() {
      this.addingPaper = false;
    },
    saveAdd() {
      const $tr = $('#gsm-new-paper');

      const title = $tr.find('.gsm-paper-title').val();
      if(title.length < 1) {
        alert('Vul een naam in');
        return;
      }

      const width = $tr.find('.gsm-paper-width').val();
      if(!width || width < 50 || width > 2000 || !/^\d+$/.test(width)) {
        alert('Vul een breedte in tussen 50 en 2000 mm');
        return;
      }

      const height = $tr.find('.gsm-paper-height').val();
      if(!height || height < 50 || height > 2000 || !/^\d+$/.test(height)) {
        alert('Vul een hoogte in tussen 50 en 2000 mm');
        return;
      }

      // Larger then 1m^2 seems unreasonable. Do give some (1%) slack on this limit
      if(height * width > 1010000) {
        alert('Vul een breedte en hoogte in die samen niet meer dan één vierkante meter vormen');
        return;
      }

      const name = strSlug(title);
      if(Container.getPaperList().hasSystemPaper(name) || Container.getPaperList().hasCustomPaper(name)) {
        alert('Er bestaat al een papierformaat met deze naam, vul een andere in');
        return;
      }

      Container.getPaperList().addCustomPaper(new CustomPaper(
          parseInt(width),
          parseInt(height),
          title,
          name,
      ));

      this.addingPaper = false;
      $tr.find('input').each(function() {
        $(this).val('');
      });
    },
    deletePaper(paper: Paper) {
      if(confirm('Wil je dit papierformaat verwijderen?')) {
        Container.getPaperList().deleteCustomPaper(paper.name, this.userInterface);
      }
    }
  }
};
</script>

<style scoped>

</style>
