import Conversion from "./Conversion";
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import DutchGrid, {DutchGridSystem} from "../Coordinates/DutchGrid";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import proj4 from "proj4";

export default class WGS84_DutchGrid implements Conversion<WGS84, DutchGrid> {

    sourceSystem(): CoordinateSystem<WGS84> {
        return new WGS84System();
    }

    targetSystem(): CoordinateSystem<DutchGrid> {
        return new DutchGridSystem();
    }

    inverse(source: DutchGrid): WGS84 {
        const wgs84 = proj4('EPSG:28992').inverse([source.getX(), source.getY()]);
        return new WGS84(wgs84[0], wgs84[1]);
    }

    convert(source: WGS84): DutchGrid {
        const dutch = proj4('EPSG:28992').forward([source.getX(), source.getY()]);
        return new DutchGrid(dutch[0], dutch[1]);
    }

}
