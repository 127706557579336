import Coordinate from "../Coordinates/Coordinate";
import Cutout from "../Main/Cutout";
import Cache from "../Util/Cache";
import {jsPDF} from "jspdf";
import Paper from "../Util/Paper";
import Container from "../Main/Container";
import Projection from "./Projection";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import EmptyMip from "./EmptyMip";
import CoordinateConverter from "../Util/CoordinateConverter";

export default class EmptyProjection<C extends Coordinate> extends Projection<C, EmptyMip> {

    constructor(mipName: string, scale: number = null) {
        super(Container.emptyMip(mipName), scale, {});
    }

    initialize(): Promise<void> {
        if(this.scale === null) {
            this.scale = this.mapImageProvider.getDefaultScale();
        }

        return Promise.resolve();
    }

    clone(): EmptyProjection<C> {
        const projection = new EmptyProjection<C>(
            this.mapImageProvider.name,
            this.getScale(),
        );
        projection.coordinateSystem = this.coordinateSystem;
        return projection;
    }

    serialize(): Serialization {
        return {
            type: 'empty',
            mip: this.mapImageProvider.name,
            csCode: this.coordinateSystem.code,
            scale: this.scale,
        };
    }

    static unserialize(serialized: Serialization): EmptyProjection<Coordinate> {
        const projection = new EmptyProjection(serialized.mip, serialized.scale);
        projection.coordinateSystem = CoordinateConverter.getCoordinateSystem(serialized.csCode);
        return projection;
    }

    attach(cutout: Cutout<any, C, any>) {
        super.attach(cutout);

        // Preload capabilities upon attaching
        this.initialize();
    }

    getMipDrawnGrid(): GridSpec | null {
        return null;
    }

    getDpi(): number {
        return 300; // Required for location rendering
    }

    projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void)|null): Promise<void> {
        return Promise.resolve();
    }
}
