import UserInterface from "./UserInterface";
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer'; // VectorImage as VectorLayer? https://www.gitmemory.com/issue/openlayers/openlayers/12562/887423168
import Route, {RouteReactiveProps} from "./Route";
import {Coordinate as olCoordinate} from "ol/coordinate";
import RouteStartSketchAction from "../ActionHistory/RouteStartSketchAction";
import RouteAddAction from "../ActionHistory/RouteAddAction";
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import {isMobile} from "../Util/functions";
import {MapBrowserEvent} from "ol";
import {primaryAction} from "ol/events/condition";
import predictName from "../Util/NamePredictor";
import {reactive} from "vue";
import AddCutoutAction from "../ActionHistory/AddCutoutAction";
import RouteIntermediates from "./RouteIntermediates";

export type RouteCollectionReactiveProps = {
    focusedRouteRP: RouteReactiveProps|null,
    sketchRouteRP: RouteReactiveProps,
    rouTechSelectedRouteRP: RouteReactiveProps|null,
};

export default class RouteCollection {

    private reactiveRoutesProps: RouteReactiveProps[];
    reactiveProps : RouteCollectionReactiveProps;

    readonly mainLayer: VectorLayer<any>;
    readonly mainSource: VectorSource<any>;

    readonly modifyLayer: VectorLayer<any>;
    readonly modifySource: VectorSource<any>;

    readonly drawLayer: VectorLayer<any>;
    readonly drawSource: VectorSource<any>;

    readonly intermediatesLayer: VectorLayer<any>;
    readonly intermediatesSource: VectorSource<any>;

    readonly sideRoadsMainLayer: VectorLayer<any>;
    readonly sideRoadsMainSource: VectorSource<any>;

    readonly sideRoadsModifyLayer: VectorLayer<any>;
    readonly sideRoadsModifySource: VectorSource<any>;

    readonly sideRoadsDrawLayer: VectorLayer<any>;
    readonly sideRoadsDrawSource: VectorSource<any>;

    private routes: Route[] = [];
    private focusedRoute: Route|null = null;
    private sketchRoute: Route;

    constructor(readonly userInterface: UserInterface) {
        this.reactiveRoutesProps = reactive([]);
        this.reactiveProps = reactive({
            focusedRouteRP: null,
            sketchRouteRP: null,
            rouTechSelectedRouteRP: null,
        });

        const olMap = userInterface.getMap().getOpenlayersMap();

        this.mainSource = new VectorSource();
        this.modifySource = new VectorSource();
        this.drawSource = new VectorSource();
        this.intermediatesSource = new VectorSource();
        this.sideRoadsMainSource = new VectorSource();
        this.sideRoadsModifySource = new VectorSource();
        this.sideRoadsDrawSource = new VectorSource();

        this.mainLayer = new VectorLayer({
            source: this.mainSource,
        });
        this.modifyLayer = new VectorLayer({
            source: this.modifySource,
        });
        this.drawLayer = new VectorLayer({
            source: this.drawSource,
        });
        this.intermediatesLayer = new VectorLayer({
            source: this.intermediatesSource,
        });
        this.sideRoadsMainLayer = new VectorLayer({
            source: this.sideRoadsMainSource,
        });
        this.sideRoadsModifyLayer = new VectorLayer({
            source: this.sideRoadsModifySource,
        });
        this.sideRoadsDrawLayer = new VectorLayer({
            source: this.sideRoadsDrawSource,
        });

        olMap.addLayer(this.mainLayer);
        olMap.addLayer(this.modifyLayer);
        olMap.addLayer(this.drawLayer);
        olMap.addLayer(this.sideRoadsMainLayer);
        olMap.addLayer(this.sideRoadsModifyLayer);
        olMap.addLayer(this.sideRoadsDrawLayer);
        olMap.addLayer(this.intermediatesLayer);

        this.sketchRoute = new Route(this, true);
        this.reactiveProps.sketchRouteRP = this.sketchRoute.reactiveProps;

        document.addEventListener('keydown', (evt) => {
            if (this.focusedRoute) {
                this.focusedRoute.handleKeyDown(evt);
            }

            const routechRoute = this.userInterface.getOpenObjectTypeTab() === 'route-intermediates'
                ? this.getRouTechSelectedRoute()
                : this.focusedRoute;
            if (routechRoute) {
                routechRoute.handleKeyDownForIntermediates(evt);
            }
        });

        if (!isMobile()) {
            olMap.on('singleclick', (e: MapBrowserEvent<any>) => {
                if (e.defaultPrevented) {
                    return;
                }

                if (!primaryAction(e)) {
                    return;
                }

                if (this.userInterface.isLocked()) {
                    return;
                }

                const routeFeature = olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                    if (feature && feature.get('route')) {
                        return feature;
                    }
                }, {
                    layerFilter: (layer) => {
                        return layer == this.mainLayer;
                    }
                });

                if (routeFeature) {
                    const route: Route = routeFeature.get('route');

                    if (!route.isSketch) {
                        e.preventDefault();
                        e.stopPropagation();

                        this.focusRoute(route);
                    }
                }
            });
        }
    }

    public getOpenlayersMap()
    {
        return this.userInterface.getMap().getOpenlayersMap();
    }

    public getRoutes()
    {
        return this.routes;
    }

    public find(id: number)
    {
        return this.routes.find(route => route.id === id);
    }

    public getReactiveRoutesProps(): RouteReactiveProps[]
    {
        return this.reactiveRoutesProps;
    }

    public updateReactiveRoutesProps(): void
    {
        this.reactiveRoutesProps.splice(0, this.reactiveRoutesProps.length, ...this.routes.map(route => route.reactiveProps));
    }

    public getSketchRoute()
    {
        return this.sketchRoute;
    }

    public hasFocusedRoute()
    {
        return this.focusedRoute !== null;
    }

    public getFocusedRoute(): Route|null
    {
        return this.focusedRoute;
    }

    public unfocus()
    {
        if (this.focusedRoute) {
            this.focusedRoute.setFocus(false);
            this.reactiveProps.focusedRouteRP = this.focusedRoute = null;
        }
    }

    public focusRoute(route: Route)
    {
        this.unfocus();

        route.setFocus(true);
        this.focusedRoute = route;
        this.reactiveProps.focusedRouteRP = this.focusedRoute.reactiveProps;

        if (this.reactiveProps.focusedRouteRP !== this.reactiveProps.sketchRouteRP && this.reactiveProps.focusedRouteRP.hasInitializedIntermediates) {
            this.reactiveProps.rouTechSelectedRouteRP = this.reactiveProps.focusedRouteRP;
        } else {
            this.reactiveProps.rouTechSelectedRouteRP = null;
        }
    }

    public setRouTechSelectedRoute(id: number|null): void
    {
        this.reactiveProps.rouTechSelectedRouteRP = id ? this.find(id).reactiveProps : null;
    }

    public getRouTechSelectedRoute(): Route|null
    {
        if (!this.reactiveProps.rouTechSelectedRouteRP) {
            return null;
        }

        return this.find(this.reactiveProps.rouTechSelectedRouteRP.id);
    }

    public addRoute()
    {
        const route = new Route(this);
        route.setColor(this.userInterface.newColor());
        route.setName(predictName(this.routes.map((route) => route.getName())) || 'Route ' + route.id);

        this.userInterface.actionHistory.addAction(new RouteAddAction(
            route,
            this.routes.length
        ));

        return route;
    }

    public addRouteWithRoutech(): Route
    {
        const route = this.addRoute();

        route.setIntermediates(new RouteIntermediates(route));

        this.userInterface.showObjectListPanel('route-intermediates');

        return route;
    }

    public attachRoute(route: Route, position: number)
    {
        route.mouseout(false);
        route.addToMap();
        this.routes.splice(position, 0, route);
        this.updateReactiveRoutesProps();

        if (this.userInterface.getOpenObjectTypeTab() !== 'route-intermediates' || route.getIntermediates() === null) {
            this.userInterface.showObjectListPanel('route');
        }
    }

    public detachRoute(route: Route)
    {
        const index = this.routes.indexOf(route);
        if(index === -1) {
            throw new Error('Invalid route');
        }

        if (route.hasFocus()) {
            this.unfocus();
        }

        if (this.reactiveProps.rouTechSelectedRouteRP?.id === route.id) {
            this.setRouTechSelectedRoute(null);
        }

        route.removeFromMap();
        this.routes.splice(index, 1);
        this.updateReactiveRoutesProps();

        if (this.userInterface.getOpenObjectTypeTab() !== 'route-intermediates' || route.getIntermediates() === null) {
            this.userInterface.showObjectListPanel('route');
        }
    }

    public moveRouteInList(route: Route, newIndex: number): void {
        const index = this.routes.indexOf(route);
        if (index === -1) {
            throw new Error('Invalid route');
        }

        this.routes.splice(index, 1);
        this.routes.splice(newIndex, 0, route);

        this.updateReactiveRoutesProps();
        this.userInterface.showObjectListPanel('route');
    }

    public checkCloseRouteIntermediatesPanel(): void
    {
        // If no route has intermediates anymore, close rou-tech panel
        if (this.getReactiveRoutesProps().filter(r => r.hasInitializedIntermediates).length === 0) {
            this.userInterface.showObjectListPanel('route');
        }
    }

    public addRouteFromSketch()
    {
        const coordinates = this.sketchRoute.getCoordinates();
        const route = this.addRoute();
        route.setCoordinates(coordinates);
    }

    public duplicateRoute(sourceRoute: Route)
    {
        const route = sourceRoute.clone();

        route.setColor(this.userInterface.newColor());
        route.setName(sourceRoute.getName() + ' (kopie)');

        this.userInterface.actionHistory.addAction(new RouteAddAction(
            route,
            this.routes.length
        ));
    }

    public startSketchRoute(coordinate: olCoordinate)
    {
        this.focusRoute(this.sketchRoute);
        this.sketchRoute.setCoordinates([coordinate], false);
        this.userInterface.actionHistory.addAction(new RouteStartSketchAction(this.sketchRoute, []));
    }

    public stopSketchRoute()
    {
        this.unfocus();
    }

    public drawOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        for (const route of this.routes) {
            if (route.isVisible()) {
                route.drawOnPdf(doc, cutout);
            }
        }
    }

    public drawIntermediatesOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        for (const route of this.routes) {
            if (route.isVisible() && route.getIntermediates()) {
                route.getIntermediates().drawOnPdf(doc, cutout);
            }
        }
    }
}
