import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Coordinate from "../Coordinates/Coordinate";
import MapImageProvider from "./MapImageProvider";
import OlMap from "ol/Map";
import View from "ol/View";
import {Projection, transformExtent} from "ol/proj";
import * as olExtent from 'ol/extent';
import {getIntersection} from "ol/extent";
import OlTileLoader from "../Util/OlTileLoader";
import Cache from "../Util/Cache";
import {FeatureLike} from "ol/Feature";
import {MVT} from "ol/format";
import {Feature} from "ol";
import {Vector as VectorSource, VectorTile} from "ol/source";
import {stringToUnit8array, uint8arrayToString} from "../Util/functions";
import {bbox} from "ol/loadingstrategy";
import {Vector as VectorLayer} from "ol/layer";
import {stylefunction} from "ol-mapbox-style";

import $ from "jquery";

export type Bounds = { minX: number, maxX: number, minY: number, maxY: number };

const MM_PER_INCH = 25.4;

export type VectorLayerUrlSet = {tileUrl: string, styleUrl: string};

export default class OlVectorMip implements MapImageProvider {

    static readonly mapPixelWidth = 1000;
    private basePixelsPerUnit: Record<string, number> = {};

    private static instanceCounter = 0;

    constructor(
        readonly name: string,
        readonly title: string,
        private urlSets: VectorLayerUrlSet[],
        readonly copyright: string,
        readonly compatibleCoordinateSystems: CoordinateSystem<Coordinate>[],
        readonly bounds: Record<string, Bounds>,
        readonly minTileZoom: number,
        readonly maxTileZoom: number,
    ) {
    }

    getEmptyOlMap(coordinateSystem: CoordinateSystem<Coordinate>): OlMap {
        const bounds = this.bounds[coordinateSystem.code];

        return this.getOlMap(coordinateSystem, 0, [
            (bounds.minX + bounds.maxX) / 2,
            (bounds.minY + bounds.maxY) / 2,
        ]);
    }

    getOlMap(coordinateSystem: CoordinateSystem<Coordinate>, renderZoom: number, center: number[]): OlMap {
        const target = 'ol_mip_' + OlVectorMip.instanceCounter++;

        const $olMip = $('<div class="invisible ol-mip" id="' + target + '"></div>');
        $olMip.width(OlVectorMip.mapPixelWidth);
        $olMip.height(OlVectorMip.mapPixelWidth);
        $('body').append($olMip);

        const projection = new Projection({
            code: coordinateSystem.code,
            extent: this.getExtent(coordinateSystem),
        });

        return new OlMap({
            target: target,
            view: new View({
                projection: projection,
                center: center,
                zoom: renderZoom,
            }),
        });
    }

    disposeOlMap(olMap: OlMap) {
        $(olMap.getTargetElement()).remove();
    }

    hasDrawnGrid(): boolean {
        return false;
    }

    getCopyright(): string {
        return this.copyright;
    }

    isCompatibleWithCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        for (const cs of this.compatibleCoordinateSystems) {
            if (cs.code === coordinateSystem.code) {
                return true;
            }
        }
        return false;
    }

    getCompatibleCoordinateSystems(): CoordinateSystem<Coordinate>[] {
        return this.compatibleCoordinateSystems;
    }

    getDefaultCoordinateSystem(): CoordinateSystem<any> {
        return this.compatibleCoordinateSystems[0];
    }

    getBasePixelsPerUnit(coordinateSystem: CoordinateSystem<Coordinate>): number {
        if (this.basePixelsPerUnit[coordinateSystem.code] !== undefined) {
            return this.basePixelsPerUnit[coordinateSystem.code];
        }

        const olMap = this.getEmptyOlMap(coordinateSystem);
        this.basePixelsPerUnit[coordinateSystem.code] = 1 / olMap.getView().getResolutionForZoom(0);
        this.disposeOlMap(olMap);

        return this.basePixelsPerUnit[coordinateSystem.code];
    }

    getPxPerKm(coordinateSystem: CoordinateSystem<Coordinate>, zoom: number): number {
        // https://wiki.openstreetmap.org/wiki/Zoom_levels
        const meterPerPixelLevel0 = 156412;

        const pxPerUnit = 1 / meterPerPixelLevel0 * (2 ** zoom);

        return pxPerUnit * 1000;
    }

    computeRenderZoom(coordinateSystem: CoordinateSystem<Coordinate>, scale: number, dpi: number): number {
        const meterPerPixelLevel0 = 1 / this.getBasePixelsPerUnit(coordinateSystem);

        // The resolution is the size of 1 pixel in map units (meters).

        const paperMmPerPx = MM_PER_INCH / dpi;
        const realMmPerPaperMm = scale;
        const realMmPerPx = paperMmPerPx * realMmPerPaperMm;

        const renderZoom = Math.log2(meterPerPixelLevel0 * 1000 / realMmPerPx);

        return renderZoom;
    }

    getTileZoomClosestToScale(coordinateSystem: CoordinateSystem<Coordinate>, scale: number): number {
        // https://wiki.openstreetmap.org/wiki/Zoom_levels
        const scaleLevel0 = 500000000;

        const mul = scaleLevel0 / scale;

        return Math.round(Math.log2(mul));
    }

    getScaleClosestToTileZoom(coordinateSystem: CoordinateSystem<Coordinate>, tileZoom: number): number {
        // https://wiki.openstreetmap.org/wiki/Zoom_levels
        const scaleLevel0 = 500000000;

        return Math.round(scaleLevel0 / 2 ** tileZoom);
    }

    getZoomLevelList(): number[] {
        const levels = [];

        for (let tileZoom = this.minTileZoom; tileZoom <= this.maxTileZoom; tileZoom++) {
            levels.push(tileZoom);
        }

        return levels;
    }

    getExtent(coordinateSystem: CoordinateSystem<Coordinate>): olExtent.Extent {
        const bounds = this.bounds[coordinateSystem.code];

        return [
            bounds.minX,
            bounds.minY,
            bounds.maxX,
            bounds.maxY,
        ];
    }

    getBoundingPolygon<C extends Coordinate>(coordinateSystem: CoordinateSystem<C>): Promise<C[]|null> {
        if (!this.bounds[coordinateSystem.code]) {
            return Promise.resolve(null);
        }

        const bounds = this.bounds[coordinateSystem.code];

        return Promise.resolve(<C[]>[
            coordinateSystem.make(bounds.minX, bounds.minY),
            coordinateSystem.make(bounds.minX, bounds.maxY),
            coordinateSystem.make(bounds.maxX, bounds.maxY),
            coordinateSystem.make(bounds.maxX, bounds.minY),
        ]);
    }

    downloadLegend() {
        alert('Could not find legend URL');
    }

    private renderCheckerboardMapPart(
        coordinateSystem: CoordinateSystem<Coordinate>,
        renderZoom: number,
        tileZoom: number,
        center: number[],
        cache: Cache,
        k: number,
        glStyles: (string|any)[],
        pointFeatures: FeatureLike[][]
    ): Promise<OlMap> {

        return new Promise((resolve, reject) => {
            const format = new MVT({ featureClass: Feature });

            let layersDone = 0;
            const done = () => {
                layersDone++;
                if (layersDone === glStyles.length) {
                    resolve(map);
                }
            };

            const buildLoader = (i) => {
                pointFeatures[i] = [];

                const vectorTileSource = new VectorTile({
                    format: format,
                    url: this.urlSets[i].tileUrl,
                });
                const tileGrid = vectorTileSource.getTileGrid();

                return (extent, resolution, projection) => {
                    const tileProjection = vectorTileSource.getProjection();

                    const safeExtent = getIntersection(extent, tileProjection.getExtent());
                    const gridExtent = transformExtent(safeExtent, projection, tileProjection);

                    let tileCount = 0;
                    tileGrid.forEachTileCoord(gridExtent, tileZoom, function (tileCoord) {
                        if (tileCoord[1] % 2 !== k % 2 || tileCoord[2] % 2 !== (k >> 1) % 2) {
                            return;
                        }

                        tileCount++;
                    });

                    if (tileCount === 0) {
                        setTimeout(() => done());
                        return;
                    }

                    let tilesDone = 0, tilesSucceeded = 0;
                    tileGrid.forEachTileCoord(gridExtent, tileZoom, function (tileCoord) {
                        if (tileCoord[1] % 2 !== k % 2 || tileCoord[2] % 2 !== (k >> 1) % 2) {
                            return;
                        }

                        const tileUrl = vectorTileSource.getTileUrlFunction()(tileCoord, undefined, undefined);

                        cache.fetch(tileUrl, () => {
                            return new Promise((resolve, reject) => {
                                const xhr = new XMLHttpRequest();
                                xhr.open('GET', tileUrl, true);
                                xhr.responseType = 'blob';
                                xhr.onload = function (e) {
                                    const blob = xhr.response;

                                    const fr = new FileReader();
                                    fr.onload = function(e) {
                                        // @ts-ignore
                                        resolve(uint8arrayToString(new Uint8Array(fr.result)));
                                    };
                                    fr.readAsArrayBuffer(blob);
                                };
                                xhr.onerror = (evt) => reject(evt);
                                xhr.send(null);
                            });
                        }).then((resultString) => {
                            const result = stringToUnit8array(resultString);

                            const features = format.readFeatures(result, {
                                extent: tileGrid.getTileCoordExtent(tileCoord),
                                featureProjection: tileProjection
                            });

                            const newFeatures = [];
                            features.forEach(function (feature) {
                                feature.getGeometry().transform(tileProjection, projection);

                                if (feature.getGeometry().getType() === 'Point') {
                                    pointFeatures[i].push(feature);
                                } else {
                                    newFeatures.push(feature);
                                }
                            });

                            sources[i].addFeatures(newFeatures);

                            tilesSucceeded++;
                        }).catch((reason) => {
                            console.log('Het downloaden van één of meerdere tegels is mislukt. Sommige kaartdelen kunnen missen.');
                        }).finally(() => {
                            tilesDone++;

                            if (tilesDone === tileCount) {
                                if (tilesSucceeded > 0) {
                                    setTimeout(() => {
                                        map.once('postrender', () => {
                                            done();
                                        });
                                        map.renderSync();
                                    });
                                } else {
                                    setTimeout(() => done());
                                }
                            }
                        });
                    });
                };
            };

            const map = this.getOlMap(coordinateSystem, renderZoom, center);
            const sources = [];

            for (let i = 0; i < glStyles.length; i++) {
                const source = new VectorSource({
                    loader: buildLoader(i),
                    strategy: bbox
                });

                const vectorLayer = new VectorLayer({
                    declutter: true,
                    source: source,
                });

                stylefunction(vectorLayer, glStyles[i], 'esri');

                map.addLayer(vectorLayer);
                sources[i] = source;
            }

            map.renderSync();
        });

    }

    public renderMap(
        coordinateSystem: CoordinateSystem<Coordinate>,
        renderZoom: number,
        tileZoom: number,
        center: number[],
        tileLoader: OlTileLoader
    ): Promise<string> {

        const cache = tileLoader.cache;

        const pointFeatures = [];

        const promises = [];
        for (const urlSet of this.urlSets) {
            promises.push(cache.fetch(urlSet.styleUrl, () => {
                return fetch(urlSet.styleUrl).then((response) => {
                    return response.json().then((glStyle) => {
                        return JSON.stringify(glStyle);
                    });
                });
            }));
        }

        return Promise.all(promises).then((glStyleJsons: string[]) => {
            const glStyles = [];
            for (const glStyleJson of glStyleJsons) {
                glStyles.push(JSON.parse(glStyleJson));
            }

            return Promise.all([
                this.renderCheckerboardMapPart(coordinateSystem, renderZoom, tileZoom, center, cache, 0, glStyles, pointFeatures),
                this.renderCheckerboardMapPart(coordinateSystem, renderZoom, tileZoom, center, cache, 1, glStyles, pointFeatures),
                this.renderCheckerboardMapPart(coordinateSystem, renderZoom, tileZoom, center, cache, 2, glStyles, pointFeatures),
                this.renderCheckerboardMapPart(coordinateSystem, renderZoom, tileZoom, center, cache, 3, glStyles, pointFeatures),
            ]).then((maps) => {

                const pointMap = this.getOlMap(coordinateSystem, renderZoom, center);
                for (let i = 0; i < glStyleJsons.length; i++) {
                    // Add text labels
                    const pointMapSource = new VectorSource({
                        strategy: bbox
                    });
                    const pointMapVectorLayer = new VectorLayer({
                        declutter: true,
                        source: pointMapSource,
                    });

                    stylefunction(pointMapVectorLayer, glStyles[i], 'esri');

                    pointMap.addLayer(pointMapVectorLayer);
                    pointMapSource.addFeatures(pointFeatures[i]);
                }
                pointMap.renderSync();

                // Get rendered map
                const mapCanvas = document.createElement('canvas');

                // Don't use maps[0].getSize() but real canvas size:
                let canvasWidth = 0, canvasHeight = 0;
                Array.prototype.forEach.call(
                    maps[0].getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                    function (canvas) {
                        if (canvas.width > 0) {
                            canvasWidth = canvas.width;
                            canvasHeight = canvas.height;
                        }
                    }
                );
                mapCanvas.width = canvasWidth;
                mapCanvas.height = canvasHeight;

                const mapContext = mapCanvas.getContext('2d');
                mapContext.globalAlpha = 1;
                mapContext.fillStyle = '#ffffff';
                mapContext.fillRect(0, 0, mapCanvas.width, mapCanvas.height);

                const baseImgd = mapContext.getImageData(0, 0, mapCanvas.width, mapCanvas.height),
                    basePix = baseImgd.data;

                for (const map of maps) {
                    Array.prototype.forEach.call(
                        map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                        function (canvas) {
                            if (canvas.width > 0) {
                                const ctx = canvas.getContext('2d');
                                const newImgd = ctx.getImageData(0, 0, canvas.width, canvas.height),
                                    newPix = newImgd.data;
                                for (let i = 0, n = newPix.length; i <n; i += 4) {
                                    const rbase = basePix[i],
                                        gbase = basePix[i+1],
                                        bbase = basePix[i+2],
                                        abase = basePix[i+3];
                                    const rnew = newPix[i],
                                        gnew = newPix[i+1],
                                        bnew = newPix[i+2],
                                        anew = newPix[i+3];

                                    if (
                                        (rbase === 255 && gbase === 255 && bbase === 255)
                                        || (rbase === 0 && gbase === 0 && bbase === 0 && abase === 0)
                                        || (anew !== 0 && rnew <= rbase && gnew <= gbase && bnew <= bbase)
                                    ) {
                                        basePix[i] = newPix[i];
                                        basePix[i+1] = newPix[i+1];
                                        basePix[i+2] = newPix[i+2];
                                        basePix[i+3] = newPix[i+3];
                                    } else if (
                                        (rbase !== 255 || gbase !== 255 || bbase !== 255)
                                        && (rnew !== 255 || gnew !== 255 || bnew !== 255)
                                        && !(rnew === 0 && gnew === 0 && bnew === 0 && anew === 0)
                                    ) {
                                        const atot = abase + anew;
                                        basePix[i  ] = abase / atot * basePix[i  ] + anew / atot * newPix[i  ];
                                        basePix[i+1] = abase / atot * basePix[i+1] + anew / atot * newPix[i+1];
                                        basePix[i+2] = abase / atot * basePix[i+2] + anew / atot * newPix[i+2];
                                        basePix[i+3] = abase / atot * basePix[i+3] + anew / atot * newPix[i+3];
                                    }
                                }
                            }
                        }
                    );
                }

                // Drop alpha
                for (let i = 0, n = basePix.length; i <n; i += 4) {
                    const a = basePix[i+3] / 255;

                    basePix[i] = basePix[i] * a + 255 * (1-a);
                    basePix[i+1] = basePix[i+1] * a + 255 * (1-a);
                    basePix[i+2] = basePix[i+2] * a + 255 * (1-a);
                    basePix[i+3] = 255;
                }

                mapContext.putImageData(baseImgd, 0, 0);
                mapContext.globalAlpha = 1;

                Array.prototype.forEach.call(
                    pointMap.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
                    function (canvas) {
                        if (canvas.width > 0) {
                            mapContext.drawImage(canvas, 0, 0);
                        }
                    }
                );

                for (const map of maps) {
                    this.disposeOlMap(map);
                }
                this.disposeOlMap(pointMap);

                return mapCanvas.toDataURL();
            });
        });
    }
}
