import Route, {RouteEditMode} from "./Route";
import {Draw, Select, Snap} from "ol/interaction";
import {Collection} from "ol";
import {toTurfPolygon} from "../Util/Math";
import {Circle, Fill, RegularShape, Stroke, Style} from "ol/style";
import RouteIntermediate from "./RouteIntermediate";
import {Options as SelectOptions, SelectEvent} from "ol/interaction/Select";
import {altKeyOnly, click} from "ol/events/condition";
import UserError from "../Util/UserError";
import RouteAddIntermediateAction from "../ActionHistory/RouteAddIntermediateAction";
import RouteDeleteIntermediateAction from "../ActionHistory/RouteDeleteIntermediateAction";
import {getLength} from "ol/sphere";
import {LineString} from "ol/geom";
import {Coordinate as olCoordinate} from "ol/coordinate";
import {reactive} from "vue";
import {Serialization} from "./Serializer";
import Text from 'ol/style/Text.js';
import RouteTechnique, {RouteTechniqueReactiveProps} from "../RouteTechniques/RouteTechnique";
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import CoordinateConverter from "../Util/CoordinateConverter";
import ConversionComposition from "../Conversion/ConversionComposition";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import $ from "jquery";
import RouteSideRoadsUtil from "./RouteSideRoadsUtil";
import {Options, Result} from "ol/interaction/Snap";
import {Pixel} from "ol/pixel";
import OlMap from "ol/Map";
import RouteAddIntermediateOnEdgeAction from "../ActionHistory/RouteAddIntermediateOnEdgeAction";
import {isMobile} from "../Util/functions";

export type IntermediatesMeta = {
    intermediateMetas: IntermediateMeta[],
    intermediateCoordinates: olCoordinate[],
    startCoordinate: olCoordinate|null,
    endCoordinate: olCoordinate|null,
};

export type IntermediateMeta = {
    intermediateNumber: number,
    pathIncomingBearing: number|null,
    directDistance: number|null,
    pathDistance: number|null,
    directBearing: number|null,
    pathInitialBearing: number|null,
    directDistanceFormatted: string|null,
    pathDistanceFormatted: string|null,
    directBearingFormatted: string|null,
    pathInitialBearingFormatted: string|null,
};

export type RouteIntermediatesReactiveProps = {
    intermediates: IntermediateMeta[],
    routeTechniqueRP: RouteTechniqueReactiveProps<any>|null,
    startNumber: number,
};

export default class RouteIntermediates {
    private readonly intermediateDrawInteraction;
    private readonly intermediateSnapInteraction;
    private readonly intermediateRemoveInteraction;

    private intermediates: Record<number, RouteIntermediate> = {};

    private routeTechnique: RouteTechnique<any>|null = null;

    readonly routeSideRoadsUtil: RouteSideRoadsUtil;

    private startNumber: number = 1;

    reactiveProps: RouteIntermediatesReactiveProps;

    constructor(
        public readonly route: Route
    ) {
        this.routeSideRoadsUtil = new RouteSideRoadsUtil(this);

        this.reactiveProps = <RouteIntermediatesReactiveProps>reactive({
            intermediates: [],
            routeTechniqueRP: null,
            startNumber: this.startNumber,
        });

        this.initIntermediateInteractions();

        this.updateReactiveProps();
    }

    private initIntermediateInteractions() {
        const self = this;

        const markerStyleFn = function (opacity: number = 1) {
            return function (feature) {
                const intermediate = <RouteIntermediate>feature.get('intermediate');

                if (intermediate && !intermediate.intermediates.route.isVisible()) {
                    return new Style({});
                }

                const mouseover = intermediate?.intermediates?.routeSideRoadsUtil?.getMouseoverIntermediate() === intermediate;
                const selected = intermediate?.intermediates?.routeSideRoadsUtil?.getFocusedSideRoad()?.intermediate === intermediate;

                return new Style({
                    image: intermediate?.intermediates?.route?.getEditMode() === 'side-road'
                        ? new Circle({
                            fill: new Fill({color: selected ? [0, 204, 51, opacity] : [0, 153, 255, opacity]}),
                            stroke: new Stroke({color: 'white'}),
                            radius: mouseover ? 10 : 8,
                        }) : new RegularShape({
                            fill: new Fill({color: [0, 153, 255, opacity]}),
                            stroke: new Stroke({color: 'white'}),
                            radius: 10,
                            points: 4,
                            angle: Math.PI / 4,
                        }),
                    text: new Text({
                        text: intermediate ? '' + intermediate.getIntermediateNumber() : '',
                        fill: new Fill({
                            color: 'white',
                        }),
                    }),
                });
            };
        };

        this.intermediateDrawInteraction = new Draw({
            source: this.route.routeCollection.intermediatesSource,
            type: 'Point',
            style: markerStyleFn(0.5),
        });

        this.route.routeCollection.intermediatesLayer.setStyle(markerStyleFn());

        this.intermediateDrawInteraction.on('drawend', (e) => {
            setTimeout(() => this.route.routeCollection.intermediatesSource.removeFeature(e.feature));

            const coordinate = e.feature.getGeometry().getCoordinates();

            const result = this.route.locateCoordinate(coordinate);

            if (result === null) {
                return;
            }

            if (result[0] === result[1]) {
                if (typeof this.intermediates[result[0]] !== 'undefined') {
                    return;
                }

                this.route.routeCollection.userInterface.actionHistory.addAction(
                    new RouteAddIntermediateAction(new RouteIntermediate(this, result[0]))
                );
            } else {
                this.route.routeCollection.userInterface.actionHistory.addAction(
                    new RouteAddIntermediateOnEdgeAction(this.route, result[1], coordinate)
                );
            }
        });

        this.intermediateRemoveInteraction = new Select(<SelectOptions>{
            condition: (mapBrowserEvent) => {
                return click(mapBrowserEvent) && altKeyOnly(mapBrowserEvent);
            },
            layers: [this.route.routeCollection.intermediatesLayer],
        });
        this.intermediateRemoveInteraction.addEventListener('select', (selectEvent: SelectEvent) => {
            for (const feature of selectEvent.selected) {
                const intermediate = <RouteIntermediate>feature.get('intermediate');
                if (intermediate !== undefined && intermediate.intermediates === this) {
                    this.route.routeCollection.userInterface.actionHistory.addAction(
                        new RouteDeleteIntermediateAction(intermediate)
                    );
                }
            }

            this.intermediateRemoveInteraction.getFeatures().clear();
        });

        this.intermediateSnapInteraction = new class extends Snap {
            constructor() {
                super(<Options>{
                    source: self.route.routeCollection.intermediatesSource,
                    features: new Collection([self.route.mainFeature]),
                    pixelTolerance: Infinity,
                });
            }

            snapTo(pixel: Pixel, pixelCoordinate: olCoordinate, map: OlMap): Result | null {
                const vertexPixelTolerance = isMobile() ? 30 : 10;

                // Snap to edges
                this.vertex_ = false;
                this.edge_ = true;
                const edgeResult = super.snapTo(pixel, pixelCoordinate, map);

                if (!edgeResult) {
                    return null;
                }

                // Snap the edge-snapped point to vertices
                this.vertex_ = true;
                this.edge_ = false;
                const vertexResult = super.snapTo(edgeResult.vertexPixel, edgeResult.vertex, map);

                if (!vertexResult) {
                    return null;
                }

                // If the cursor or edge-snapped point is close to a vertex, snap to vertex
                // Note: This is aimed at snapping to one of the two vertices of the snapped edge, however
                // there is an edge case in the situation an edge separates between the cursor and a vertex,
                // in which case you would expect the edge to be snapped but instead the vertex is snapped
                // if it is within the pixel tolerance of the edge
                const vertexDistanceSquared = (vertexResult.vertexPixel[0] - edgeResult.vertexPixel[0]) ** 2 + (vertexResult.vertexPixel[1] - edgeResult.vertexPixel[1]) ** 2;
                if (vertexDistanceSquared < vertexPixelTolerance ** 2) {
                    return vertexResult;
                }

                // We are not close to a vertex, so snap to edge
                return edgeResult;
            }
        };
    }

    processNewEditMode(oldMode: RouteEditMode|null, newMode: RouteEditMode|null): void
    {
        const olMap = this.route.routeCollection.getOpenlayersMap();

        if (this.route.getCoordinates().length > 0) {
            if (newMode === 'intermediate' && oldMode !== 'intermediate') {
                olMap.addInteraction(this.intermediateDrawInteraction);
                olMap.addInteraction(this.intermediateSnapInteraction);
                olMap.addInteraction(this.intermediateRemoveInteraction);
            } else if (newMode !== 'intermediate' && oldMode === 'intermediate') {
                olMap.removeInteraction(this.intermediateRemoveInteraction);
                olMap.removeInteraction(this.intermediateSnapInteraction);
                olMap.removeInteraction(this.intermediateDrawInteraction);
            }

            this.routeSideRoadsUtil.processNewEditMode(oldMode, newMode);
        }
    }

    getIntermediatesList(): RouteIntermediate[]
    {
        return Object.values(this.intermediates);
    }

    syncCoordinates(): void {
        for (const intermediate of Object.values(this.intermediates)) {
            intermediate.syncCoordinate();
        }

        this.updateReactiveProps();
    }

    addIntermediate(intermediate: RouteIntermediate): void {
        if (typeof this.intermediates[intermediate.index] !== 'undefined') {
            throw new UserError('Corrupt intermediates array.');
        }

        this.intermediates[intermediate.index] = intermediate;
        intermediate.addToMap();

        this.updateIntermediateNumbers();
        this.updateReactiveProps();
    }

    removeIntermediate(intermediate: RouteIntermediate, update: boolean = true): void {
        if (typeof this.intermediates[intermediate.index] === 'undefined') {
            throw new UserError('Corrupt intermediates array.');
        }

        intermediate.removeFromMap();
        delete this.intermediates[intermediate.index];

        if (update) {
            this.updateIntermediateNumbers();
            this.updateReactiveProps();
        }
    }

    public addToMap()
    {
        for (const intermediate of Object.values(this.intermediates)) {
            intermediate.addToMap();
        }
    }

    public removeFromMap()
    {
        for (const intermediate of Object.values(this.intermediates)) {
            intermediate.removeFromMap();
        }
    }

    getIntermediatesSlice(start: number, end: number): Record<number, RouteIntermediate> {
        let intermediates = {};
        for (let i = start; i < end; i++) {
            if (typeof this.intermediates[i] !== 'undefined') {
                intermediates[i] = this.intermediates[i];
            }
        }
        return intermediates;
    }

    getIntermediatesCount(): number {
        return Object.values(this.intermediates).length;
    }

    getRouteTechnique(): RouteTechnique<any>|null {
        return this.routeTechnique;
    }

    setRouteTechnique(routeTechnique: RouteTechnique<any>|null): void {
        if (routeTechnique && routeTechnique.route !== this.route) {
            throw new Error('Invalid route technique');
        }

        this.routeTechnique = routeTechnique;
        this.updateReactiveProps();
    }

    downloadRouteTechnique(): Promise<void> {
        return this.checkSendPrintStatistics().then(() => {
            this.routeSideRoadsUtil.unfocusSideRoad();

            this.routeTechnique?.download();
        });
    }

    checkSendPrintStatistics(): Promise<void> {
        return this.route.routeCollection.userInterface.checkStatisticsParticipation().then((choice) => {
            if (choice === true) {
                try {
                    const settings = this.serialize();
                    settings.num_intermediates = settings.intermediates.length;
                    delete settings.intermediates;

                    $.post('server/stats.php?request=routech_download', {
                        settings: JSON.stringify(settings),
                    });
                } catch(e) {
                    console.log(e);
                }
            }
        });
    }

    applyMutationSlide(mutationIndex: number, mutationAmount: number): void {
        const newIntermediates = <Record<number, RouteIntermediate>>{};

        for (const index in this.intermediates) {
            const intermediate = this.intermediates[index];

            const newIndex = index >= mutationIndex ? +index + mutationAmount : +index;

            intermediate.setIndex(newIndex);
            newIntermediates[newIndex] = intermediate;
        }

        this.intermediates = newIntermediates;
    }

    updateSideRoadsAfterMoveMutation(mutationIndex: number, coordinate: olCoordinate): void {
        if (typeof this.intermediates[mutationIndex] === 'undefined') {
            return;
        }

        this.intermediates[mutationIndex].updateSideRoadsAfterMoveMutation(coordinate);
    }

    doReverse(): void {
        const newIntermediates = <Record<number, RouteIntermediate>>{};

        const n = this.route.getCoordinates().length;

        for (const index in this.intermediates) {
            const intermediate = this.intermediates[index];

            const newIndex = n - 1 - index;

            intermediate.setIndex(newIndex);
            newIntermediates[newIndex] = intermediate;
        }

        this.intermediates = newIntermediates;

        this.updateIntermediateNumbers();

        this.updateReactiveProps();
    }

    updateReactiveProps()
    {
        this.reactiveProps.intermediates = this.computeIntermediateMeta();
        this.reactiveProps.routeTechniqueRP = this.routeTechnique?.reactiveProps;
        this.reactiveProps.startNumber = this.startNumber;
    }

    updateIntermediateNumbers()
    {
        const intermediateIndexes = Object.keys(this.intermediates).map((x) => +x);
        intermediateIndexes.sort((a, b) => a - b);

        for (let i = 0; i < intermediateIndexes.length; i++) {
            const intermediate = this.intermediates[intermediateIndexes[i]];

            intermediate.setIntermediateNumber(i + this.startNumber);
        }
    }

    public getStartNumber(): number {
        return this.startNumber;
    }

    public setStartNumber(startNumber: number): void {
        this.startNumber = startNumber;
        this.updateIntermediateNumbers();
        this.updateReactiveProps();
        this.route.routeCollection.intermediatesSource.changed();
    }

    computeIntermediatesMeta(): IntermediatesMeta {
        const coordinates = this.route.getCoordinates();

        return {
            intermediateMetas: this.computeIntermediateMeta(),
            intermediateCoordinates: this.computeIntermediateCoordinates(),
            startCoordinate: coordinates.length > 0 ? coordinates[0] : null,
            endCoordinate: coordinates.length > 1 ? coordinates[coordinates.length - 1] : null,
        };
    }

    computeIntermediateMeta(): IntermediateMeta[] {
        const coordinates = this.route.getCoordinates();

        const intermediateIndexes = Object.keys(this.intermediates).map((x) => +x);
        intermediateIndexes.sort((a, b) => a - b);

        const intermediateMetas = <IntermediateMeta[]>[];

        for (let i = 0; i < intermediateIndexes.length; i++) {
            const currentIndex = intermediateIndexes[i];
            let nextIndex;
            if (i === intermediateIndexes.length - 1) {
                if (intermediateIndexes[i] === coordinates.length - 1) {
                    nextIndex = null;
                } else {
                    nextIndex = coordinates.length - 1;
                }
            } else {
                nextIndex = intermediateIndexes[i + 1];
            }

            const meta = <IntermediateMeta>{
                intermediateNumber: this.intermediates[currentIndex].getIntermediateNumber(),
                pathIncomingBearing: null,
                directDistance: null,
                pathDistance: null,
                directBearing: null,
                pathInitialBearing: null,
                directDistanceFormatted: null,
                pathDistanceFormatted: null,
                directBearingFormatted: null,
                pathInitialBearingFormatted: null,
            };
            if (currentIndex !== 0) {
                meta.pathIncomingBearing = Route.computeBearing(coordinates[currentIndex - 1], coordinates[currentIndex]);
            }

            if (nextIndex !== null) {
                const sectionCoords = coordinates.slice(currentIndex, nextIndex + 1);

                const directDistance = getLength(new LineString([sectionCoords[0], sectionCoords[sectionCoords.length - 1]]));
                const pathDistance = getLength(new LineString(sectionCoords));

                const directBearing = Route.computeBearing(sectionCoords[0], sectionCoords[sectionCoords.length - 1]);
                const pathInitialBearing = Route.computeBearing(sectionCoords[0], sectionCoords[1]);

                meta.directDistance = directDistance;
                meta.pathDistance = pathDistance;
                meta.directBearing = directBearing;
                meta.pathInitialBearing = pathInitialBearing;
                meta.directDistanceFormatted = Route.formatLength(directDistance, 0);
                meta.pathDistanceFormatted = Route.formatLength(pathDistance, 0);
                meta.directBearingFormatted = Route.formatBearing(directBearing);
                meta.pathInitialBearingFormatted = Route.formatBearing(pathInitialBearing);
            }

            intermediateMetas.push(meta);
        }

        return intermediateMetas;
    }

    computeIntermediateCoordinates(): olCoordinate[] {
        const coordinates = this.route.getCoordinates();

        const intermediateIndexes = Object.keys(this.intermediates).map((x) => +x);
        intermediateIndexes.sort((a, b) => a - b);

        const intermediateCoordinates = <olCoordinate[]>[];
        for (let i = 0; i < intermediateIndexes.length; i++) {
            intermediateCoordinates.push(coordinates[intermediateIndexes[i]]);
        }

        return intermediateCoordinates;
    }

    serialize(): Serialization {
        const intermediates = [];
        for (const intermediate of Object.values(this.intermediates)) {
            intermediates.push(intermediate.serialize());
        }
        return {
            intermediates: intermediates,
            routeTechnique: this.routeTechnique?.serialize(),
            startNumber: this.startNumber,
        };
    }

    static unserialize(serialized: Serialization, route: Route): RouteIntermediates {
        const routeIntermediates = new RouteIntermediates(route);

        for (const serializedIntermediate of serialized.intermediates) {
            const intermediate = RouteIntermediate.unserialize(serializedIntermediate, routeIntermediates);

            routeIntermediates.intermediates[intermediate.index] = intermediate;
        }

        routeIntermediates.startNumber = serialized.startNumber || 1;
        routeIntermediates.routeTechnique = serialized.routeTechnique ? RouteTechnique.unserialize(serialized.routeTechnique, route) : null;

        routeIntermediates.updateIntermediateNumbers();
        routeIntermediates.updateReactiveProps();

        return routeIntermediates;
    }

    public drawOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        const wgsSystem = CoordinateConverter.getCoordinateSystem('EPSG:4326');
        const paperCoordinateConversion = cutout.getProjection().paperCoordinateConversion();
        const toPaperCoord = new ConversionComposition(
            CoordinateConverter.conversion(wgsSystem, cutout.getProjection().coordinateSystem),
            paperCoordinateConversion
        );

        const paperPolygon = CoordinateConverter.convertPolygonUsingConversion(cutout.mapPolygonProjection, paperCoordinateConversion);
        const turfPaperPolygon = toTurfPolygon(paperPolygon);

        doc.saveGraphicsState();
        try {
            for (const intermediate of Object.values(this.intermediates)) {
                const paperCoordinate = toPaperCoord.convert(intermediate.getCoordinate());

                if (booleanPointInPolygon([paperCoordinate.getX(), paperCoordinate.getY()], turfPaperPolygon)) {
                    doc.setFillColor(0, 153, 255);
                    doc.setDrawColor(255, 255, 255);
                    doc.setLineWidth(0.2);
                    doc.setTextColor(255, 255, 255);
                    doc.setFontSize(6);

                    doc.rect(paperCoordinate.getX() - 1.5, paperCoordinate.getY() - 1.5, 3, 3, 'DF');

                    doc.text(intermediate.getIntermediateNumber() + '', paperCoordinate.getX(), paperCoordinate.getY(), {
                        align: 'center',
                        baseline: 'middle',
                    });
                }
            }

        } finally {
            doc.restoreGraphicsState();
        }
    }
}
