import UserError from "./UserError";
import {Serialization} from "../Main/Serializer";
import Container from "../Main/Container";
import {strSlug} from "./functions";
import UserInterface from "../Main/UserInterface";
export type millimeter = number;

export default abstract class Paper {
    public name: string;
    public title: string;
    public width: millimeter;
    public height: millimeter;

    serialize(): Serialization {
        return {
            name: this.name,
        };
    }

    static unserialize(serialized: Serialization): Paper {
        if(
            Object.values(serialized).length === 1
            && serialized.name !== undefined
            && Container.getPaperList().hasSystemPaper(serialized.name)
        ) {
            return Container.getPaperList().getSystemPaper(serialized.name);
        } else {
            return Container.getPaperList().unserializeCustomPaper(serialized);
        }
    }

    check(): void {
        return;
    }
}

export class A3P extends Paper {
    public readonly name: string = 'A3P';
    public readonly title: string = 'A3 (Staand)';
    public readonly width: millimeter = 297;
    public readonly height: millimeter = 420;
}

export class A3L extends Paper {
    public readonly name: string = 'A3L';
    public readonly title: string = 'A3 (Liggend)';
    public readonly width: millimeter = 420;
    public readonly height: millimeter = 297;
}

export class A4P extends Paper {
    public readonly name: string = 'A4P';
    public readonly title: string = 'A4 (Staand)';
    public readonly width: millimeter = 210;
    public readonly height: millimeter = 297;
}

export class A4L extends Paper {
    public readonly name: string = 'A4L';
    public readonly title: string = 'A4 (Liggend)';
    public readonly width: millimeter = 297;
    public readonly height: millimeter = 210;
}

export class A5P extends Paper {
    public readonly name: string = 'A5P';
    public readonly title: string = 'A5 (Staand)';
    public readonly width: millimeter = 148;
    public readonly height: millimeter = 210;
}

export class A5L extends Paper {
    public readonly name: string = 'A5L';
    public readonly title: string = 'A5 (Liggend)';
    public readonly width: millimeter = 210;
    public readonly height: millimeter = 148;
}

export class CustomPaper extends Paper {
    constructor(
        public width: millimeter,
        public height: millimeter,
        public title: string = 'Custom',
        public name: string = 'custom',
    ) {
        super();
    }

    serialize(): Serialization {
        const serialization = super.serialize();
        serialization.width = this.width;
        serialization.height = this.height;
        serialization.title = this.title;
        return serialization;
    }

    static doUnserialize(serialized: Serialization): CustomPaper {
        return new CustomPaper(
            serialized.width,
            serialized.height,
            serialized.title,
            serialized.name,
        )
    }

    check(): void {
        if(!Container.getPaperList().hasCustomPaper(this.name)) {
            // Paper has gone away... re-import it
            Container.getPaperList().unserializeCustomPaper(this.serialize());
        } else {
            // Paper still present, check it is still the same
            const paper = Container.getPaperList().getCustomPaper(this.name);
            if(paper.width !== this.width || paper.height !== this.height) {
                throw new UserError('Cannot apply action due to modified paper');
            }
        }
    }
}

export class PaperList {

    static readonly LOCALSTORAGE_KEY = 'custom_paper';

    private systemPaperFormats: Record<string, Paper> = {};

    private listeners: Record<string, (() => void)[]> = {};

    constructor() {

    }

    on(key: string, callback: () => void) {
        if(this.listeners[key] === undefined) {
            this.listeners[key] = [];
        }
        this.listeners[key].push(callback);
    }

    trigger(key: string) {
        if(this.listeners[key] === undefined) {
            return;
        }

        for(const listener of this.listeners[key]) {
            listener();
        }
    }

    registerSystemPaper(paper: Paper): void {
        this.systemPaperFormats[paper.name] = paper;
    }

    hasSystemPaper(name: string): boolean {
        return this.systemPaperFormats.hasOwnProperty(name);
    }

    getSystemPaper(name: string): Paper {
        if(!this.systemPaperFormats.hasOwnProperty(name)) {
            throw new Error('Unknown paper "' + name + '"');
        }

        return this.systemPaperFormats[name];
    }

    getPaperList(): Paper[] {
        return [
            ...Object.values(this.systemPaperFormats),
            ...this.getCustomPaperList(),
        ];
    }

    getPaper(name: string): Paper {
        if(this.hasSystemPaper(name)) {
            return this.getSystemPaper(name);
        } else if(this.hasCustomPaper(name)) {
            return this.getCustomPaper(name);
        } else {
            throw new Error('Unknown paper "' + name + '"');
        }
    }

    private fetchCustomPaperList(): Record<string, Serialization> {
        const item = window.localStorage.getItem(PaperList.LOCALSTORAGE_KEY);
        if(item === null) {
            return {};
        }

        return JSON.parse(item);
    }

    getCustomPaperList(): Paper[] {
        const paperList: Paper[] = [];
        for(const serializedPaper of Object.values(this.fetchCustomPaperList())) {
            paperList.push(Paper.unserialize(serializedPaper));
        }
        paperList.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));
        return paperList;
    }

    getCustomPaper(name: string): Paper {
        const paperList = this.fetchCustomPaperList();
        if(paperList[name] === undefined) {
            throw new UserError('Paper does not exist');
        }
        return CustomPaper.doUnserialize(paperList[name]);
    }

    hasCustomPaper(name: string): boolean {
        const paperList = this.fetchCustomPaperList();
        return (paperList[name] !== undefined);
    }

    addCustomPaper(paper: Paper): void {
        const paperList = this.fetchCustomPaperList();
        if(paperList[paper.name] !== undefined || this.hasSystemPaper(paper.name)) {
            throw new UserError('Paper already exists');
        }

        paperList[paper.name] = paper.serialize();
        window.localStorage.setItem(PaperList.LOCALSTORAGE_KEY, JSON.stringify(paperList));

        this.trigger('paper-list-update');
    }

    canDeleteCustomPaper(name: string, userInterface: UserInterface): boolean {
        for(const cutout of userInterface.getCutouts()) {
            if(cutout.getPaper().name === name) {
                return false;
            }
        }

        return true;
    }

    deleteCustomPaper(name: string, userInterface: UserInterface): void {
        const paperList = this.fetchCustomPaperList();
        if(paperList[name] === undefined) {
            throw new UserError('Paper does not exist');
        }
        if(!this.canDeleteCustomPaper(name, userInterface)) {
            throw new UserError('Dit papierformaat kan niet verwijderd worden omdat het nog in gebruik is');
        }

        delete paperList[name];
        window.localStorage.setItem(PaperList.LOCALSTORAGE_KEY, JSON.stringify(paperList));

        this.trigger('paper-list-update');
    }

    unserializeCustomPaper(serialized: Serialization): Paper {
        if(serialized.width === undefined || serialized.height === undefined || serialized.title === undefined) {
            throw new UserError('Corrupt paper configuration');
        }
        if(this.hasCustomPaper(serialized.name)) {
            const paper = this.getCustomPaper(serialized.name);
            if(serialized.width === paper.width && serialized.height === paper.height) {
                return paper;
            }
        }

        let name = serialized.name;
        let title = serialized.title;
        if(this.hasCustomPaper(serialized.name)) {
            // So, a paper format is present with the same name but different dimensions
            // Now, first we check whether a paper format with the same dimensions is present
            // somewhere; if so, we return that. Otherwise, we will generate a new paper format
            // based on the requested dimensions
            for(const paper of this.getPaperList()) {
                if(paper.width === serialized.width && paper.height === serialized.height) {
                    return paper;
                }
            }

            let i;
            if(title.match(/ \d+$/)) {
                i = parseInt(title.substr(title.lastIndexOf(' ') + 1));
                title = title.substr(0, title.lastIndexOf(' ') + 1);
            } else {
                i = 2;
                title += ' ';
            }
            while(this.hasCustomPaper(strSlug(title + i))) {
                i++;
            }

            title = title + i;
            name = strSlug(title);
        }

        const paper = new CustomPaper(
            serialized.width,
            serialized.height,
            title,
            name,
        );
        this.addCustomPaper(paper);
        return paper;
    }

}
