import Container from "../Main/Container";
import $ from "jquery";

export default class Nominatim
{
    private static readonly NOMINATIM_SERVER = 'https://nominatim.openstreetmap.org/';

    public static search(query: string, boundingBox: number[]): Promise<any>
    {
        return Container.getCache().then((cache) => {
            const url = Nominatim.NOMINATIM_SERVER + 'search?' + $.param({
                q: query,
                format: 'json',
                viewbox: boundingBox.join(','),
            });

            return cache.fetch(url, () => {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'text';
                    xhr.onload = function (e) {
                        resolve(xhr.responseText);
                    };
                    xhr.send(null);
                });
            }).then((response: string) => {
                return JSON.parse(response);
            });
        });
    }
}
