import Cache from "./Cache";
import {ImageTile} from "ol";
import TileState from "ol/TileState";
import UserError from "./UserError";

export default class OlTileLoader {
    private beforeFetch: () => void|null = null;
    private useFallback: boolean = false;
    private tileError: boolean = false;

    constructor(public readonly cache: Cache) {
    }

    setBeforeFetch(callback: () => void): void {
        this.beforeFetch = callback;
    }

    setUseFallback(useFallback: boolean = false): void {
        this.useFallback = useFallback;
    }

    hasTileError(): boolean {
        return this.tileError;
    }

    getTileLoadFunction() {
        const self = this;

        // https://openlayers.org/en/latest/apidoc/module-ol_Tile.html
        return function(tile: ImageTile, src: string) {
            self.cache.fetch(src, () => {
                if (self.beforeFetch) {
                    self.beforeFetch();
                }

                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.addEventListener('loadend', function (evt) {
                        const data = this.response;
                        if (data !== undefined && data !== null) {
                            const fr = new FileReader();
                            fr.onload = function(e) {
                                // @ts-ignore
                                resolve(fr.result);
                            };
                            fr.readAsDataURL(data);
                        } else {
                            reject();
                        }
                    });
                    xhr.addEventListener('error', function () {
                        reject();
                    });
                    xhr.open('GET', src);
                    xhr.send();
                });
            }).then((result) => {
                // @ts-ignore
                tile.getImage().src = result;
            }).catch((e) => {
                if (self.useFallback) {
                    console.log('Er is een fout opgetreden bij het laden van een tegel');
                    // @ts-ignore
                    tile.getImage().src = src;
                    return;
                }

                self.tileError = true;
                tile.setState(TileState.ERROR);
                if (!e?.isErrorSignal || e.code !== 'DOWNLOAD_LIMIT_REACHED') {
                    console.log(e);
                    throw new UserError('Er is een fout opgetreden bij het laden van een tegel');
                }
            });
        };
    }
}
