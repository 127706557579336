import Action from "./Action";
import {reactive} from "vue";
import {Serialization} from "../Main/Serializer";

export type ActionHistoryReactiveProps = {
    canUndo: boolean,
    canRedo: boolean,
};

export default class ActionHistory {

    reactiveProps: ActionHistoryReactiveProps;

    readonly actionLimit = 100;

    private actionList: Action[] = [];
    private pointer: number|null = null;

    private isPerforming_: boolean = false;

    constructor() {
        this.reactiveProps = reactive({
            canUndo: false,
            canRedo: false,
        });

        this.computeReactive();
    }

    public getLength(): number {
        return this.actionList.length;
    }

    public addAction(action: Action) {
        if(this.pointer !== null && this.actionList.length > this.pointer + 1) {
            this.actionList.splice(this.pointer + 1);
        } else if(this.pointer === null && this.actionList.length > 0) {
            this.actionList.splice(0);
        }

        if(this.pointer !== null && this.actionList[this.pointer].merge(action)) {
            this.computeReactive();
            return;
        }

        // Apply, then push, such that any actions added in the constructor are pushed before this action
        action.apply(true);

        this.actionList.push(action);

        if(this.actionList.length > this.actionLimit) {
            this.actionList.shift();
        }

        this.pointer = this.actionList.length - 1;
        this.computeReactive();
    }

    public computeReactive(): void {
        this.reactiveProps.canUndo = this.canUndo();
        this.reactiveProps.canRedo = this.canRedo();
    }

    public clear() {
        this.actionList = [];
        this.pointer = null;
        this.computeReactive();
    }

    public canUndo(): boolean {
        return this.pointer !== null;
    }

    public undo() {
        if(!this.canUndo()) {
            return;
        }

        this.isPerforming_ = true;
        try {
            const action = this.actionList[this.pointer];
            action.revert();

            this.pointer--;
            if(this.pointer === -1) {
                this.pointer = null;
            }

            this.computeReactive();
        } finally {
            this.isPerforming_ = false;
        }
    }

    public canRedo(): boolean {
        return this.actionList.length > 0 && (this.pointer === null || this.pointer < this.actionList.length - 1);
    }

    public redo() {
        if(!this.canRedo()) {
            return;
        }

        this.isPerforming_ = true;
        try {
            const newPointer = (this.pointer === null) ? 0 : this.pointer + 1;

            const action = this.actionList[newPointer];
            action.apply(false);

            this.pointer = newPointer;

            this.computeReactive();
        } finally {
            this.isPerforming_ = false;
        }
    }

    public isPerforming(): boolean {
        return this.isPerforming_;
    }

    public serializeForDebug(): Serialization {
        return {
            pointer: this.pointer,
            length: this.actionList.length,
            actions: this.actionList.map((action: Action) => {
                let serialized;
                try {
                    serialized = action.serializeForDebug();
                } catch (error) {
                    serialized = 'error: ' + JSON.stringify(error, Object.getOwnPropertyNames(error));
                }

                return {
                    action: action.constructor.name,
                    data: serialized,
                };
            }),
        };
    }
}
