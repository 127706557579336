
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import UTM, {UTMSystem} from "../Coordinates/UTM";
import Conversion from "./Conversion";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import proj4 from "proj4";

/**
 * Actually, projecting WGS84 to UTM amounts to going through:
 *  WGS84 <-> ITRS <-> ETRS89 <-> UTM
 *
 * https://en.wikipedia.org/wiki/Universal_Transverse_Mercator_coordinate_system#From_latitude,_longitude_(%CF%86,_%CE%BB)_to_UTM_coordinates_(E,_N)
 * https://geographiclib.sourceforge.io/html/transversemercator.html#tmseries
 * https://www.gsi.go.jp/common/000065826.pdf
 * https://www.gsi.go.jp/common/000062452.pdf
 * https://geographiclib.sourceforge.io/html/TransverseMercator_8cpp_source.html#l00045
 * https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=1&ved=2ahUKEwi6-YPWgYvmAhWQalAKHVuSAD0QFjAAegQIBRAC&url=https%3A%2F%2Ficaci.org%2Ffiles%2Fdocuments%2FICC_proceedings%2FICC2007%2Fdocuments%2Fdoc%2FTHEME%25202%2Foral%25201%2F2.1.2%2520A%2520HIGHLY%2520ACCURATE%2520WORLD%2520WIDE%2520ALGORITHM%2520FOR%2520THE%2520TRANSVE.doc&usg=AOvVaw3Rl9gJJSqRaYbjCM1SI_ul
 */
export default class WGS84_UTM implements Conversion<WGS84, UTM> {

    sourceSystem(): CoordinateSystem<WGS84> {
        return new WGS84System();
    }

    targetSystem(): CoordinateSystem<UTM> {
        return new UTMSystem();
    }

    constructor(readonly zone: number|null = null) {
    }

    private determineProj4Def(zone: number, hemi: number): string {
        zone = 32; // TODO: Remove 32
        return '+proj=utm +zone=' + zone + ((hemi === -1) ? ' +south' : '') + ' +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    }

    convert(source: WGS84): UTM {
        // Use requested zone or closest zone
        const zone = (this.zone !== null) ? this.zone : Math.floor((source.lng + 180) / 6) + 1;
        const hemi = (source.lat >= 0) ? 1 : -1;

        const utm = proj4(this.determineProj4Def(zone, hemi)).forward([source.getX(), source.getY()]);
        return new UTM(utm[0], utm[1], zone, hemi);
    }

    inverse(source: UTM): WGS84 {
        const wgs84 = proj4(this.determineProj4Def(source.zone, source.hemi)).inverse([source.getX(), source.getY()]);
        return new WGS84(wgs84[0], wgs84[1]);
    }
}
