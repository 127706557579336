import Coordinate from "../Coordinates/Coordinate";
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Cutout from "../Main/Cutout";
import CoordinateConverter from "../Util/CoordinateConverter";
import Cache from "../Util/Cache";
import {Point, PointSystem} from "../Util/Math";
import {jsPDF} from "jspdf";
import Paper from "../Util/Paper";
import CartesianTransformation from "../Conversion/CartesianTransformation";
import MapImageProvider from "./MapImageProvider";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {reactive} from "vue";

export type ProjectionReactiveProps = {
    mipName: string,
    coordinateSystemCode: string,
    scale: number,
};

export default abstract class Projection<C extends Coordinate, MIP extends MapImageProvider> {

    reactiveProps: ProjectionReactiveProps;

    protected cutout: Cutout<any, C, any> = null;
    coordinateSystem: CoordinateSystem<C>;
    anchor: C;

    protected constructor(
        readonly mapImageProvider: MIP,
        protected scale: number = null,
        extraReactiveProps,
    ) {
        this.coordinateSystem = this.mapImageProvider.getDefaultCoordinateSystem();

        this.reactiveProps = reactive(extraReactiveProps);
        this.reactiveProps.mipName = mapImageProvider.name;
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
        this.reactiveProps.scale = scale;
    }

    abstract clone(): Projection<C, MIP>;

    abstract serialize(): Serialization;

    abstract initialize(): Promise<void>;

    abstract getMipDrawnGrid(): GridSpec|null;

    getBoundingPolygon(): Promise<Coordinate[]|null> {
        return this.mapImageProvider.getBoundingPolygon(this.coordinateSystem);
    }

    detach() {
        if(this.cutout === null) {
            throw new Error('Already detached');
        }

        this.cutout = null;
    }

    attach(cutout: Cutout<any, C, any>) {
        if(this.cutout !== null) {
            throw new Error('Already attached');
        }

        this.cutout = cutout;
    }

    setAnchor(coordinate: Coordinate) {
        this.anchor = CoordinateConverter.convert(coordinate, this.coordinateSystem);
        this.coordinateSystem = this.coordinateSystem.rebase(this.anchor);
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
    }

    setCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>) {
        // @ts-ignore
        this.coordinateSystem = coordinateSystem;
        this.reactiveProps.coordinateSystemCode = this.coordinateSystem.code;
        this.setAnchor(CoordinateConverter.convert(this.anchor, coordinateSystem));
        this.cutout.updateMap();
    }

    getMapImageProvider(): MapImageProvider {
        return this.mapImageProvider;
    }

    getScale(): number {
        return this.scale;
    }

    setScale(newScale: number) {
        this.reactiveProps.scale = this.scale = newScale;
        if(this.cutout) {
            this.cutout.updateMap();
        }
    }

    abstract getDpi();

    paperCoordinateConversion(): CartesianTransformation<C, Point> {
        const realMmPerPaperMm = this.getScale();
        const realMmPerUnit = 1000;
        const paperMmPerUnit = realMmPerUnit / realMmPerPaperMm;

        return CartesianTransformation
            .build(this.coordinateSystem, new PointSystem())

            // Incoming is a projection coordinate; we move the anchor to the origin
            .translate(new Point(-this.anchor.getX(), -this.anchor.getY()))

            // We scale from real distances to paper distances
            .scale(paperMmPerUnit)

            // On paper, the y-axis points down
            .mulMatrix([[1, 0], [0, -1]])

            // Move by margin
            .translate(new Point(
                this.cutout.options.margin_left_printable + this.cutout.options.margin_left_nonprintable,
                this.cutout.getPaper().height - this.cutout.options.margin_bottom_printable - this.cutout.options.margin_bottom_nonprintable
            ))

            .make();
    }

    abstract projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void)|null);
}
