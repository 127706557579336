import Action from "./Action";
import RouteSideRoad from "../Main/RouteSideRoad";
import {Serialization} from "../Main/Serializer";

export default class RouteAddSideRoadAction implements Action {

    constructor(readonly routeSideRoad: RouteSideRoad) {
    }

    public apply() {
        this.routeSideRoad.intermediate.addSideRoad(this.routeSideRoad);
    }

    public revert() {
        this.routeSideRoad.intermediate.removeSideRoad(this.routeSideRoad);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.routeSideRoad.intermediate.intermediates.route.id,
            index: this.routeSideRoad.intermediate.index,
            intermediateNumber: this.routeSideRoad.intermediate.getIntermediateNumber(),
        };
    }
}
