import Coordinate from "./Coordinate";
import CoordinateSystem from "./CoordinateSystem";
import OLConvertibleCoordinate from "./OLConvertibleCoordinate";
import OLConvertibleCoordinateSystem from "./OLConvertibleCoordinateSystem";
import Conversion from "../Conversion/Conversion";
import WGS84_DutchGrid from "../Conversion/WGS84_DutchGrid";
import WGS84_UTM from "../Conversion/WGS84_UTM";
import {Point} from "../Util/Math";
import {padLeadingZeros, trimTrailingZeroDecimalPlaces} from "../Util/functions";
import {fromLonLat, toLonLat} from "ol/proj";
import CoordinateParseResult from "../Util/CoordinateParseResult";

export class WGS84System implements CoordinateSystem<WGS84>, OLConvertibleCoordinateSystem<WGS84> {
    readonly code = 'EPSG:4326';
    readonly name = 'WGS 84';

    make(lng: number, lat: number): WGS84 {
        return new WGS84(lng, lat);
    }

    fromPoint(point: Point): WGS84 {
        return new WGS84(point.getX(), point.getY());
    }

    fromOpenLayersCoordinate(source: number[]): WGS84 {
        const wgs84 = toLonLat(source);
        return this.make(wgs84[0], wgs84[1]);
    }

    conversions(): Conversion<WGS84, Coordinate>[] {
        return [
            new WGS84_DutchGrid(),
            new WGS84_UTM(),
        ];
    }

    rebase(c: WGS84): WGS84System {
        return this;
    }

    parse(value: string): CoordinateParseResult<WGS84>|null {
        let match = value.match(/^([\-]?\d+(?:\.\d+)?)[^\d\-]{1,5}([\-]?\d+(?:\.\d+)?)$/);
        if (match) {
            const coordinate = new WGS84(
                parseFloat(match[2]),
                parseFloat(match[1]),
            );

            if (coordinate.withinBounds()) {
                return new CoordinateParseResult<WGS84>(
                    coordinate,
                    'possible'
                );
            }
        }

        for (const pos of ['before', 'after']) {
            const NSBefore = (pos === 'before') ? '(N|S)' : '';
            const EWBefore = (pos === 'before') ? '(E|W)' : '';
            const NSAfter = (pos === 'after') ? '(N|S)' : '';
            const EWAfter = (pos === 'after') ? '(E|W)' : '';
            const symbol = '[^\\d]{1,4}';
            const maybeSymbol = '[^\\d]{0,4}';
            const offset = (pos === 'before') ? 1 : 0;

            let regex = new RegExp(
                '^' + NSBefore + '\\s*(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + NSAfter
                + '[^\\d]{0,5}'
                + EWBefore + '\\s*(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + EWAfter + '$');
            match = value.match(regex);
            if (match) {
                const multiplierNS = (match[(pos === 'before') ? 1 : 2] === 'N') ? 1 : -1;
                const multiplierEW = (match[(pos === 'before') ? 3 : 4] === 'E') ? 1 : -1;

                const coordinate = new WGS84(
                    multiplierEW * parseFloat(match[offset + 3]),
                    multiplierNS * parseFloat(match[offset + 1]),
                );

                if (coordinate.withinBounds()) {
                    return new CoordinateParseResult<WGS84>(
                        coordinate,
                        'definite'
                    );
                }
            }

            regex = new RegExp(
                '^' + NSBefore + '\\s*(\\d+)' + symbol + '(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + NSAfter
                + '[^\\d]{0,5}'
                + EWBefore + '\\s*(\\d+)' + symbol + '(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + EWAfter + '$');
            match = value.match(regex);
            if (match) {
                const multiplierNS = (match[(pos === 'before') ? 1 : 3] === 'N') ? 1 : -1;
                const multiplierEW = (match[(pos === 'before') ? 4 : 6] === 'E') ? 1 : -1;

                const coordinate = new WGS84(
                    multiplierEW * (parseFloat(match[offset + 4]) + parseFloat(match[offset + 5]) / 60),
                    multiplierNS * (parseFloat(match[offset + 1]) + parseFloat(match[offset + 2]) / 60),
                );

                if (coordinate.withinBounds()) {
                    return new CoordinateParseResult<WGS84>(
                        coordinate,
                        'definite'
                    );
                }
            }

            regex = new RegExp(
                '^' + NSBefore + '\\s*(\\d+)' + symbol + '(\\d+)' + symbol + '(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + NSAfter
                + '[^\\d]{0,5}'
                + EWBefore + '\\s*(\\d+)' + symbol + '(\\d+)' + symbol + '(\\d+(?:\\.\\d+)?)' + maybeSymbol + '\\s*' + EWAfter + '$');
            match = value.match(regex);
            if (match) {
                const multiplierNS = (match[(pos === 'before') ? 1 : 4] === 'N') ? 1 : -1;
                const multiplierEW = (match[(pos === 'before') ? 5 : 8] === 'E') ? 1 : -1;

                const coordinate = new WGS84(
                    multiplierEW * (parseFloat(match[offset + 5]) + parseFloat(match[offset + 6]) / 60 + parseFloat(match[offset + 7]) / 60 / 60),
                    multiplierNS * (parseFloat(match[offset + 1]) + parseFloat(match[offset + 2]) / 60 + parseFloat(match[offset + 3]) / 60 / 60),
                );

                if (coordinate.withinBounds()) {
                    return new CoordinateParseResult<WGS84>(
                        coordinate,
                        'definite'
                    );
                }
            }
        }

        return null;
    }
}

export default class WGS84 implements Coordinate, OLConvertibleCoordinate {
    readonly code = 'EPSG:4326';

    readonly lng: number;
    readonly lat: number;

    static fromStrings(lng: string, lat: string): WGS84|null {
        const longitude = parseFloat(lng);
        const latitude = parseFloat(lat);

        if (isNaN(longitude) || isNaN(latitude)) {
            return null;
        }

        const coordinate = new WGS84(longitude, latitude);

        if (!coordinate.withinBounds()) {
            return null;
        }

        return coordinate;
    }

    constructor(lng: number, lat: number) {
        this.lng = lng;
        this.lat = lat;
    }

    getX(): number {
        return this.lng;
    }

    getY(): number {
        return this.lat;
    }

    make<C extends this>(lng: number, lat: number): C {
        return <C>new WGS84(lng, lat);
    }

    clone<C extends this>(): C {
        return <C>new WGS84(this.lng, this.lat);
    }

    toOpenLayersCoordinate(): number[] {
        return fromLonLat([this.lng, this.lat]);
    }

    withinBounds(): boolean {
        return -180 <= this.lng && this.lng <= 180 && -90 <= this.lat && this.lat <= 90;
    }

    belongsTo(coordinateSystem: CoordinateSystem<Coordinate>): boolean {
        return this.code === coordinateSystem.code;
    }

    formatOrdinateForPdf(dimension: 'x' | 'y'): string {
        return ''; // TODO
    }

    formats(): Record<string, () => string> {
        return {
            raw: (): string => {
                const lat = trimTrailingZeroDecimalPlaces(this.lat, 6);
                const lng = trimTrailingZeroDecimalPlaces(this.lng, 6);
                return lat + ',' + lng;
            },
            deg: (): string => {
                return this.formatDeg(Math.abs(this.lat)) + this.formatNS(this.lat)
                    + ', '
                    + this.formatDeg(Math.abs(this.lng)) + this.formatWE(this.lng);
            },
            degmin: (): string => {
                return this.formatDegMin(Math.abs(this.lat)) + this.formatNS(this.lat)
                    + ', '
                    + this.formatDegMin(Math.abs(this.lng)) + this.formatWE(this.lng);
            },
            degminsec: (): string => {
                return this.formatDegMinSec(Math.abs(this.lat)) + this.formatNS(this.lat)
                    + ', '
                    + this.formatDegMinSec(Math.abs(this.lng)) + this.formatWE(this.lng);
            },
        };
    }

    defaultFormat(): string {
        return 'degmin';
    }

    private formatNS(degrees: number): string {
        return degrees < 0 ? 'S' : 'N';
    }

    private formatWE(degrees: number): string {
        return degrees < 0 ? 'W' : 'E';
    }

    private formatDeg(degrees: number) {
        return degrees.toFixed(6) + '°';
    }

    private formatDegMin(degrees: number) {
        const deg = Math.floor(degrees);
        const min = (degrees - deg) * 60;

        return deg + '°' + (min < 10 ? '0' : '') + min.toFixed(3) + "'";
    }

    private formatDegMinSec(degrees: number) {
        const deg = Math.floor(degrees);
        const minutes = (degrees - deg) * 60;
        const min = Math.floor(minutes);
        const sec = (minutes - min) * 60;

        return deg + '°' + padLeadingZeros(min, 2) + "'" + (sec < 10 ? '0' : '') + sec.toFixed(2) + '"';
    }
}
