import {Feature} from "ol";
import RouteIntermediates from "./RouteIntermediates";
import {Point} from "ol/geom";
import {Coordinate as olCoordinate} from "ol/coordinate";
import {Serialization} from "./Serializer";
import WGS84, {WGS84System} from "../Coordinates/WGS84";
import CoordinateConverter from "../Util/CoordinateConverter";
import RouteSideRoad from "./RouteSideRoad";

export default class RouteIntermediate {

    private feature: Feature|null = null;

    private intermediateNumber: number|null = null;

    private sideRoads: RouteSideRoad[] = [];

    constructor(readonly intermediates: RouteIntermediates, public index: number) {
        this.feature = new Feature({
            geometry: new Point(this.getOlCoordinate()),
            intermediate: this,
        });
    }

    setIndex(index: number) {
        this.index = index;
    }

    getIntermediateNumber(): number|null {
        return this.intermediateNumber;
    }

    setIntermediateNumber(intermediateNumber: number|null): void {
        this.intermediateNumber = intermediateNumber;
    }

    syncCoordinate(): void {
        if (!this.feature) {
            return;
        }

        this.feature.getGeometry().setCoordinates(this.getOlCoordinate());
    }

    public getOlCoordinate(): olCoordinate {
        return this.intermediates.route.getCoordinates()[this.index];
    }

    getCoordinate(): WGS84 {
        const wgs84System = <WGS84System>CoordinateConverter.getCoordinateSystem('EPSG:4326');
        return <WGS84>wgs84System.fromOpenLayersCoordinate(this.getOlCoordinate());
    }

    public getSideRoads(): RouteSideRoad[] {
        return this.sideRoads;
    }

    public addSideRoad(sideRoad: RouteSideRoad): void {
        if (sideRoad.intermediate !== this) {
            throw new Error('addSideRoad: corrupt intermediate');
        }

        this.intermediates.routeSideRoadsUtil.unfocusSideRoad();

        this.sideRoads.push(sideRoad);

        sideRoad.addToMap();

        this.intermediates.routeSideRoadsUtil.focusSideRoad(sideRoad);
    }

    public removeSideRoad(sideRoad: RouteSideRoad): void {
        if (sideRoad.intermediate !== this) {
            throw new Error('removeSideRoad: corrupt intermediate');
        }

        if (this.intermediates.routeSideRoadsUtil.getFocusedSideRoad() === sideRoad) {
            this.intermediates.routeSideRoadsUtil.unfocusSideRoad(false);
        }

        const index = this.sideRoads.indexOf(sideRoad);
        if (index === -1) {
            throw new Error('Corrupt side roads array');
        }

        this.sideRoads.splice(index, 1);

        sideRoad.removeFromMap();
    }

    updateSideRoadsAfterMoveMutation(coordinate: olCoordinate): void {
        for (const sideRoad of this.sideRoads) {
            sideRoad.updateAfterIntermediateMove(coordinate);
        }
    }

    public addToMap()
    {
        this.intermediates.route.routeCollection.intermediatesSource.addFeature(this.feature);

        for (const sideRoad of this.sideRoads) {
            sideRoad.addToMap();
        }
    }

    public removeFromMap()
    {
        this.intermediates.routeSideRoadsUtil.unfocusSideRoad();

        this.intermediates.route.routeCollection.intermediatesSource.removeFeature(this.feature);

        for (const sideRoad of this.sideRoads) {
            sideRoad.removeFromMap();
        }
    }

    serialize(): Serialization {
        return {
            index: this.index,
            sideRoads: this.sideRoads.map((sideRoad: RouteSideRoad) => sideRoad.serialize()),
        };
    }

    static unserialize(serialized: Serialization, routeIntermediates: RouteIntermediates): RouteIntermediate {
        const intermediate = new RouteIntermediate(routeIntermediates, serialized.index);

        for (const serializedSideRoad of serialized.sideRoads || []) {
            intermediate.sideRoads.push(RouteSideRoad.unserialize(serializedSideRoad, intermediate));
        }

        return intermediate;
    }
}
