import Action from "./Action";
import {Coordinate as olCoordinate} from "ol/coordinate";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class LocationMoveAction implements Action {

    private readonly newCoordinate: olCoordinate;

    constructor(private location: Location, private oldCoordinate: olCoordinate) {
        this.newCoordinate = this.location.getCoordinate();
    }

    public apply(initial: boolean) {
        if (initial) {
            return;
        }

        this.location.setCoordinate(this.newCoordinate);
    }

    public revert() {
        this.location.setCoordinate(this.oldCoordinate);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            oldCoordinate: this.oldCoordinate,
            newCoordinate: this.newCoordinate,
        };
    }
}
