import Route from "../Main/Route";
import Action from "./Action";
import {Serialization} from "../Main/Serializer";

export default class RouteSortAction implements Action {

    private readonly oldIndex: number;

    constructor(
        readonly route: Route,
        readonly newIndex: number,
    ) {
        this.oldIndex = this.route.routeCollection.getRoutes().indexOf(route);
    }

    public apply() {
        this.route.routeCollection.moveRouteInList(this.route, this.newIndex);
    }

    public revert() {
        this.route.routeCollection.moveRouteInList(this.route, this.oldIndex);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldIndex: this.oldIndex,
            newIndex: this.newIndex,
        };
    }
}
