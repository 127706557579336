import {padLeadingZeros} from "./functions";
import $ from "jquery";
import {Toast as BsToast} from "bootstrap";

export default class Toasts
{
    private readonly categoricalToasts: Record<string, BsToast> = {};

    addToast(title: string, html: string): BsToast {
        const now = new Date();

        const toastHtml = `
<div class="toast mb-2" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="toast-header">
    <strong class="me-auto">` + title + `</strong>
    <small>` + padLeadingZeros(now.getHours(), 2) + ':' + padLeadingZeros(now.getMinutes(), 2) + `</small>
    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body">
    ` + html + `
  </div>
</div>`;

        const $el = $(toastHtml);
        const el = $el[0];

        $('#toasts').append($el);

        const toast = new BsToast(el, {
            autohide: false,
        });
        toast.show();

        el.addEventListener('hidden.bs.toast', () => {
            toast.dispose();
            $el.remove();
        });

        return toast;
    }

    addCategoricalToast(category: string, title: string, html: string): void
    {
        if (this.categoricalToasts[category]) {
            const oldToast = this.categoricalToasts[category];
            if (oldToast._element) { // NOTE: accessing private element...
                oldToast.hide();
            }
        }

        this.categoricalToasts[category] = this.addToast(title, html);
    }

    addRouteTechniqueScaleToast(routeTechniqueTitle: string, imageWidth: number): void
    {
        this.addCategoricalToast(
            'routeTechniqueScale',
            routeTechniqueTitle,
            'De volledige breedte van de afbeelding komt overeen met een afstand van ' + Math.round(imageWidth) + ' meter. ' +
            'Print de afbeelding met bijv. een breedte van 10 cm voor schaal 1:' +
            Math.round(imageWidth / 0.1) + ', of met een breedte van ' +
            (imageWidth / 10000 * 100).toFixed(2).replace('.', ',') + ' cm voor schaal 1:10000.'
        );
    }
}
