export type Point = {
    x: number,
    y: number,
};

export type Vector = {
    dx: number,
    dy: number,
};

export enum IntersectionPointType {
    TIP = 'TIP',
    FIP = 'FIP',
    PFIP = 'PFIP',
    NFIP = 'NFIP',
}

export type IntersectionPointInfo = {
    continuation: boolean|null,
    type1: IntersectionPointType|null,
    type2: IntersectionPointType|null,
    type: IntersectionPointType|null,
    line1Frac: number|null,
    line2Frac: number|null,
};

export type ArcIntersection = {
    point: Point,
    segment1Frac: number,
    segment2Frac: number,
};

export type PolyLine = {
    segments: Segment[],
};

export type Drawing = {
    polylines: PolyLine[],
};

export abstract class Segment {
    private attrs: Record<string, any> = {};

    public abstract clone(): this;

    public abstract midPoint(): Point;

    public constructor(readonly start: Point, readonly end: Point) {
        if (!isFinite(start.x) || !isFinite(start.y)) {
            throw new Error('start is not finite : ' + start.x + ', ' + start.y);
        }

        if (!isFinite(end.x) || !isFinite(end.y)) {
            throw new Error('end is not finite : ' + end.x + ', ' + end.y);
        }

        if (samePoint(start, end)) {
            throw new Error('Caught degenerate segment');
        }
    }

    public static create(start: Point, end: Point, centralArcAngle: number) {
        if (centralArcAngle === 0) {
            return new LineSegment(start, end);
        } else {
            return new ArcSegment(start, end, centralArcAngle);
        }
    }

    public getAttr(key: string): any {
        return this.attrs[key];
    }

    public setAttr(key: string, value: any): this {
        this.attrs[key] = value;

        return this;
    }

    public attrsFrom(segment: Segment): this {
        for (const key in segment.attrs) {
            this.setAttr(key, segment.attrs[key]);
        }

        return this;
    }
}

export class LineSegment extends Segment {
    clone(): this {
        return new LineSegment(this.start, this.end);
    }

    vector(): Vector {
        return {
            dx: this.end.x - this.start.x,
            dy: this.end.y - this.start.y,
        };
    }

    midPoint(): Point {
        return {
            x: (this.start.x + this.end.x) / 2,
            y: (this.start.y + this.end.y) / 2,
        };
    }
}

export class ArcSegment extends Segment {
    public constructor(start: Point, end: Point, readonly centralArcAngle: number) {
        if (centralArcAngle <= 0 || !isFinite(centralArcAngle)) {
            // We should always walk drawings counter-clockwise, which implies that
            // all arcs should be non-negative. ArcSegments may not be 0 because
            // those should be LineSegments
            throw new Error('Arc segment must have a positive arc, received ' + centralArcAngle);
        }

        super(start, end);
    }

    static betweenLineSegments(segment1: LineSegment, segment2: LineSegment): ArcSegment {
        const v1 = segment1.vector();
        const v2 = segment2.vector();

        let vectorAngle = angleBetweenVectors(v1, v2);
        if (vectorAngle < 0) {
            vectorAngle += 2 * Math.PI;
        }

        if (!isFinite(vectorAngle)) {
            throw new Error('Could not compute angle between (' + v1.dx + ', ' + v1.dy + ') and (' + v2.dx + ', ' + v2.dy + ')');
        }

        return new ArcSegment(segment1.end, segment2.start, vectorAngle);
    }

    clone(): this {
        return new ArcSegment(this.start, this.end, this.centralArcAngle);
    }

    midPoint(): Point {
        const radius = this.radius();
        const center = this.centerPoint();

        const startToEnd: Vector = {
            dx: this.end.x - this.start.x,
            dy: this.end.y - this.start.y,
        };

        const normalDirection = normalize({
            dx: startToEnd.dy,
            dy: -startToEnd.dx,
        });

        const side = this.centralArcAngle <= Math.PI ? 1 : -1;

        return {
            x: center.x + side * radius * normalDirection.dx,
            y: center.y + side * radius * normalDirection.dy,
        };
    }

    centerPoint(): Point {
        const centralAngle = this.centralArcAngle;
        if (centralAngle <= 0 || centralAngle > Math.PI || !isFinite(centralAngle)) {
            throw new Error('Unexpected central arc angle ' + centralAngle + ' in centerPoint()');
        }

        const directVector: Vector = {
            dx: this.end.x - this.start.x,
            dy: this.end.y - this.start.y,
        };
        const directLength = Math.sqrt(directVector.dx ** 2 + directVector.dy ** 2);

        const perpendicularVector = normalize({
            dx: directVector.dy,
            dy: -directVector.dx,
        });
        const perpendicularDistance = directLength / 2 / Math.tan(centralAngle / 2);

        return {
            x: 0.5 * (this.end.x + this.start.x) - perpendicularDistance * perpendicularVector.dx,
            y: 0.5 * (this.end.y + this.start.y) - perpendicularDistance * perpendicularVector.dy,
        };
    }

    radius(): number {
        const centralAngle = this.centralArcAngle;
        if (centralAngle <= 0 || centralAngle > Math.PI) {
            throw new Error('Unexpected central arc angle ' + centralAngle + ' in radius()');
        }

        return distance(this.start, this.end) / 2 / Math.sin(centralAngle / 2);
    }

    discretize(n: number): LineSegment[] {
        const lineSegments = [];

        const center = this.centerPoint();
        const radius = this.radius();
        const centralArcAngle = this.centralArcAngle;

        let startAngle = Math.acos((this.start.x - center.x) / radius);
        if (this.start.y < center.y) {
            startAngle = -startAngle;
        }

        let lastPoint: Point = this.start;
        for (let i = 0; i < n; i++) {
            const nextPoint: Point = {
                x: center.x + radius * Math.cos(startAngle + centralArcAngle / (n + 1) * (i + 1)),
                y: center.y + radius * Math.sin(startAngle + centralArcAngle / (n + 1) * (i + 1)),
            };

            if (!samePoint(lastPoint, nextPoint)) {
                lineSegments.push(new LineSegment(lastPoint, nextPoint));
            }

            lastPoint = nextPoint;
        }

        if (!samePoint(lastPoint, this.end)) {
            lineSegments.push(new LineSegment(lastPoint, this.end));
        }

        return lineSegments;
    }
}

export class Circle {
    readonly centralArcAngle = 2 * Math.PI;

    constructor(readonly c: Point, readonly r: number) {
    }

    centerPoint(): Point {
        return this.c;
    }

    radius(): number {
        return this.r;
    }
}

export function intersectLines(line1: LineSegment, line2: LineSegment, intersectionPointInfo: IntersectionPointInfo = null): Point|null {
    let v1 = line1.vector();
    let v2 = line2.vector();

    if (v2.dy * v1.dx === v2.dx * v1.dy) {
        return null;
    }

    let p1 = line1.start;
    let p2 = line2.start;

    // line1.x + alpha * v1.dx = line2.x + beta * v2.dx
    // line1.y + alpha * v1.dy = line2.y + beta * v2.dy

    // alpha * v1.dx = p2.x + beta * v2.dx - p1.x
    // alpha * v1.dy = p2.y + beta * v2.dy - p1.y

    const dMax = Math.max(Math.abs(v1.dx), Math.abs(v1.dy), Math.abs(v2.dx), Math.abs(v2.dy));

    let swap = false;
    // To prevent conditioning problems, we should prevent dividing by small differences. Hence, here we find the
    // largest difference to divide by later on
    if (dMax !== Math.abs(v1.dx) && dMax !== Math.abs(v1.dy)) {
        [v1, v2] = [v2, v1];
        [p1, p2] = [p2, p1];
        swap = true;
    }

    // Now, p1 has the largest dx or dy

    let beta;
    if (Math.abs(v1.dx) > Math.abs(v1.dy)) {
        // (p2.x + beta * v2.dx - p1.x) * v1.dy / v1.dx = p2.y + beta * v2.dy - p1.y
        beta = ((p2.x - p1.x) * v1.dy / v1.dx - (p2.y - p1.y)) / (v2.dy - v2.dx * v1.dy / v1.dx);
    } else {
        // (p2.y + beta * v2.dy - p1.y) * v1.dx / v1.dy = p2.x + beta * v2.dx - p1.x
        beta = ((p2.y - p1.y) * v1.dx / v1.dy - (p2.x - p1.x)) / (v2.dx - v2.dy * v1.dx / v1.dy);
    }

    if (!isFinite(beta)) {
        console.log(p1, p2, v1, v2, beta);
        throw new Error('Caught infinite intersection solution');
    }

    // Determine alpha using largest of dx and dy to prevent division by 0
    let alpha;
    if (Math.abs(v1.dx) > Math.abs(v1.dy)) {
        alpha = (p2.x + beta * v2.dx - p1.x) / v1.dx
    } else {
        alpha = (p2.y + beta * v2.dy - p1.y) / v1.dy
    }

    if (intersectionPointInfo !== null) {
        const type1 = (0 <= alpha && alpha <= 1) ? IntersectionPointType.TIP : alpha < 0 ? IntersectionPointType.NFIP : IntersectionPointType.PFIP;
        const type2 = (0 <= beta && beta <= 1) ? IntersectionPointType.TIP : beta < 0 ? IntersectionPointType.NFIP : IntersectionPointType.PFIP;

        intersectionPointInfo.continuation = samePoint(line1.end, line2.start);
        intersectionPointInfo.type1 = swap ? type2 : type1;
        intersectionPointInfo.type2 = swap ? type1 : type2;
        intersectionPointInfo.type = type1 === IntersectionPointType.TIP && type2 === IntersectionPointType.TIP ? IntersectionPointType.TIP : IntersectionPointType.FIP;
        intersectionPointInfo.line1Frac = swap ? beta : alpha;
        intersectionPointInfo.line2Frac = swap ? alpha : beta;
    } else if (alpha < 0 || alpha > 1 || beta < 0 || beta > 1) {
        return null;
    }

    return {
        x: p2.x + beta * v2.dx,
        y: p2.y + beta * v2.dy,
    };
}

export function intersectArcs(arc1: ArcSegment, arc2: ArcSegment|Circle): ArcIntersection[] {
    const caa1 = arc1.centralArcAngle;
    const caa2 = arc2.centralArcAngle;

    // https://math.stackexchange.com/a/1033561

    const c1 = arc1.centerPoint();
    const r1 = arc1.radius();

    const c2 = arc2.centerPoint();
    const r2 = arc2.radius();

    const pointToIntersection = (point: Point): ArcIntersection => ({
        point: point,
        segment1Frac: computeClockwiseCentralAngle(c1, arc1.start, point) / caa1,
        segment2Frac: (arc2 instanceof Circle) ? NaN : computeClockwiseCentralAngle(c2, arc2.start, point) / caa2,
    });

    const d = Math.sqrt((c1.x - c2.x) ** 2 + (c1.y - c2.y) ** 2);
    const l = (r1 ** 2 - r2 ** 2 + d ** 2) / (2 * d);
    const h = Math.sqrt(r1 ** 2 - l ** 2);

    if (!(Math.abs(r1 - r2) <= d && d <= r1 + r2)) {
        return [];
    }

    const xa = l / d * (c2.x - c1.x) + c1.x;
    const ya = l / d * (c2.y - c1.y) + c1.y;

    const potentialIntersections: ArcIntersection[] = [];
    if (h === 0) {
        potentialIntersections.push(pointToIntersection({
            x: xa,
            y: ya,
        }));
    } else {
        const xb = h / d * (c2.y - c1.y);
        const yb = h / d * (c2.x - c1.x);

        potentialIntersections.push(pointToIntersection({
            x: xa + xb,
            y: ya - yb,
        }));

        potentialIntersections.push(pointToIntersection({
            x: xa - xb,
            y: ya + yb,
        }));
    }

    return potentialIntersections.filter(intersection =>
        intersection.segment1Frac < 1
        && (arc2 instanceof Circle || intersection.segment2Frac < 1)
    );
}

export function intersectLineAndArc(line: LineSegment, arc: ArcSegment|Circle): ArcIntersection[] {
    const referenceAngle = arc.centralArcAngle;

    const c = arc.centerPoint();
    const r = arc.radius();
    const lv = line.vector();

    const pointToIntersection = (point: Point, lineFrac: number): ArcIntersection => ({
        point: point,
        segment1Frac: lineFrac,
        segment2Frac: (arc instanceof Circle) ? NaN : computeClockwiseCentralAngle(c, arc.start, point) / referenceAngle,
    });

    // (x - c.x) ** 2 + (y - c.y) ** 2 == r ** 2
    // x = line.x + p * lv.dx;
    // y = line.y + p * lv.dy;

    const sx = line.start.x - c.x;
    const sy = line.start.y - c.y;

    // (sx + p * lv.dx) ** 2 + (sy + p * lv.dy) ** 2 == r ** 2

    const A = lv.dx ** 2 + lv.dy ** 2;
    const B = 2 * lv.dx * sx + 2 * lv.dy * sy;
    const C = sx ** 2 + sy ** 2 - r ** 2;

    const D = B ** 2 - 4 * A * C;

    let potentialIntersections: ArcIntersection[] = [];

    if (D === 0) {
        const p = -B / (2 * A);

        if (p < 0 || p > 1) {
            return [];
        }

        potentialIntersections.push(pointToIntersection({
            x: line.start.x + p * lv.dx,
            y: line.start.y + p * lv.dy,
        }, p));
    } else if (D > 0) {
        const sqD = Math.sqrt(D);

        const p1 = (-B + sqD) / (2 * A);
        if (0 <= p1 && p1 <= 1) {
            potentialIntersections.push(pointToIntersection({
                x: line.start.x + p1 * lv.dx,
                y: line.start.y + p1 * lv.dy,
            }, p1));
        }

        const p2 = (-B - sqD) / (2 * A);
        if (0 <= p2 && p2 <= 1) {
            potentialIntersections.push(pointToIntersection({
                x: line.start.x + p2 * lv.dx,
                y: line.start.y + p2 * lv.dy,
            }, p2));
        }
    }

    return potentialIntersections.filter(intersection =>
        arc instanceof Circle || intersection.segment2Frac <= 1
    );
}

function length(vector: Vector): number {
    return Math.sqrt(vector.dx ** 2 + vector.dy ** 2);
}

export function normalize(vector: Vector): Vector {
    const l = length(vector);

    if (l === 0) {
        throw new Error('Cannot normalize zero-length vector');
    }

    return {
        dx: vector.dx / l,
        dy: vector.dy / l,
    };
}

function dot(vector1: Vector, vector2: Vector): number {
    return vector1.dx * vector2.dx + vector1.dy * vector2.dy;
}

function diff(point1: Point, point2: Point): Vector {
    return {
        dx: point1.x - point2.x,
        dy: point1.y - point2.y,
    };
}

export function distance(point1: Point, point2: Point): number {
    return length(diff(point1, point2));
}

export function samePoint(point1: Point, point2: Point): boolean {
    return point1.x === point2.x && point1.y === point2.y;
}

function angleBetweenVectors(v1: Vector, v2: Vector): number {
    if (v2.dy === -v1.dy && v2.dx === -v1.dx) {
        // Prevent returning Math.PI + eps
        return Math.PI;
    }

    return Math.atan2(v2.dy, v2.dx) - Math.atan2(v1.dy, v1.dx);
}

function distancePointLine(point: Point, line: LineSegment): number {
    const lineVector = line.vector();

    const perpendicular: Vector = {
        dx: -lineVector.dy,
        dy: lineVector.dx,
    };

    const pointLine = new LineSegment(point, {
        x: point.x + perpendicular.dx,
        y: point.y + perpendicular.dy,
    });

    const intersectionPointInfo = <IntersectionPointInfo>{};
    const intersection = intersectLines(pointLine, line, intersectionPointInfo);

    let closestPointOnLine: Point;
    if (intersectionPointInfo.type2 === IntersectionPointType.TIP) {
        closestPointOnLine = intersection;
    } else if (intersectionPointInfo.type2 === IntersectionPointType.PFIP) {
        closestPointOnLine = line.end;
    } else if (intersectionPointInfo.type2 === IntersectionPointType.NFIP) {
        closestPointOnLine = line.start;
    } else {
        throw new Error();
    }

    return distance(point, closestPointOnLine);
}

export function distancePointLines(point: Point, lines: LineSegment[]): number {
    if (lines.length === 0) {
        throw new Error();
    }

    let minDistance = null;

    for (const line of lines) {
        const distance = distancePointLine(point, line);

        minDistance = minDistance === null ? distance : Math.min(minDistance, distance);
    }

    return minDistance;
}

function computeClockwiseCentralAngle(center: Point, start: Point, end: Point): number {
    const centerToStart: Vector = {
        dx: start.x - center.x,
        dy: start.y - center.y,
    };

    const centerToPoint: Vector = {
        dx: end.x - center.x,
        dy: end.y - center.y,
    };

    const ctsPerp = normalize({
        dx: -centerToStart.dy,
        dy: centerToStart.dx,
    });

    let angle = Math.acos(dot(centerToStart, centerToPoint) / (length(centerToStart) * length(centerToPoint)));

    if (dot(ctsPerp, centerToPoint) < 0) {
        angle = 2 * Math.PI - angle;
    }

    if (angle < 0) {
        throw new Error('Unexpected negative central arc angle');
    }

    return angle;
}

export function drawingMinMax(drawing: Drawing): {minX: number; minY: number; maxX: number; maxY: number} {
    let minX = null, maxX = null, minY = null, maxY = null;

    for (const polyline of drawing.polylines) {
        for (const segment of polyline.segments) {
            minX = minX === null ? Math.min(segment.start.x, segment.end.x) : Math.min(minX, segment.start.x, segment.end.x);
            minY = minY === null ? Math.min(segment.start.y, segment.end.y) : Math.min(minY, segment.start.y, segment.end.y);

            maxX = maxX === null ? Math.max(segment.start.x, segment.end.x) : Math.max(maxX, segment.start.x, segment.end.x);
            maxY = maxY === null ? Math.max(segment.start.y, segment.end.y) : Math.max(maxY, segment.start.y, segment.end.y);
        }
    }

    return {minX, minY, maxX, maxY};
}
