import Action from "./Action";
import Route from "../Main/Route";
import {Coordinate as olCoordinate} from "ol/coordinate";
import RouteIntermediate from "../Main/RouteIntermediate";
import {Serialization} from "../Main/Serializer";

export default class RouteCoordinateMutationAction implements Action {

    private mutationIndex: number;
    private oldSlice: olCoordinate[];
    private newSlice: olCoordinate[];
    private oldIntermediates: Record<number, RouteIntermediate>|null = null;

    constructor(readonly route: Route, oldCoordinates: olCoordinate[]) {
        this.determineSlices(oldCoordinates, this.route.getCoordinates());
    }

    public apply(initial: boolean) {
        if (this.oldIntermediates) {
            for (const index in this.oldIntermediates) {
                this.route.getIntermediates().removeIntermediate(this.oldIntermediates[index], false);
            }
        }

        const coordinates = this.route.getCoordinates().slice();
        if (!initial) {
            coordinates.splice(this.mutationIndex, this.oldSlice.length, ...this.newSlice);
        }
        this.route.applyCoordinatesChange(coordinates, this.mutationIndex, this.newSlice.length - this.oldSlice.length);

        if (this.oldIntermediates) {
            this.route.getIntermediates().updateIntermediateNumbers();
            this.route.getIntermediates().updateReactiveProps();
        }
    }

    public revert() {
        const coordinates = this.route.getCoordinates().slice();
        coordinates.splice(this.mutationIndex, this.newSlice.length, ...this.oldSlice);
        this.route.applyCoordinatesChange(coordinates, this.mutationIndex, this.oldSlice.length - this.newSlice.length);

        if (this.oldIntermediates) {
            for (const index in this.oldIntermediates) {
                this.route.getIntermediates().addIntermediate(this.oldIntermediates[index]);
            }
        }
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    private determineSlices(a: olCoordinate[], b: olCoordinate[]) {
        const [i, j] = RouteCoordinateMutationAction.determineDiff(a, b);

        this.mutationIndex = i;
        this.oldSlice = a.slice(i, a.length - j);
        this.newSlice = b.slice(i, b.length - j);

        if (this.route.reactiveProps.hasInitializedIntermediates && this.newSlice.length < this.oldSlice.length) {
            this.oldIntermediates = this.route.getIntermediates().getIntermediatesSlice(
                this.mutationIndex + this.newSlice.length,
                this.mutationIndex + this.oldSlice.length
            );
        }
    }

    public static determineDiff(a: olCoordinate[], b: olCoordinate[]): [number, number] {
        const lMin = Math.min(a.length, b.length);

        let i;
        for (i = 0; i < lMin; i++) {
            if (
                a[i][0] !== b[i][0]
                || a[i][1] !== b[i][1]
            ) {
                break;
            }
        }

        let j;
        for (j = 0; j < lMin; j++) {
            if (
                a[a.length - 1 - j][0] !== b[b.length - 1 - j][0]
                || a[a.length - 1 - j][1] !== b[b.length - 1 - j][1]
            ) {
                break;
            }
        }

        return [i, j];
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            mutationIndex: this.mutationIndex,
            oldSlice: this.oldSlice,
            newSlice: this.newSlice,
            oldIntermediatesCount: Object.values(this.oldIntermediates || {}).length,
        };
    }
}
