import {Coordinate as olCoordinate} from "ol/coordinate";
import {
    bearingToRadians,
    olComputeHeading,
    olPathStartBearing
} from "../../Util/Math";

type RoadId = number;
type Bearing = number;
type Angle = number;

type Road = {
    id: RoadId,
    reference: any,
    coordinates: olCoordinate[],
    minBearing: Bearing,
    startBearing: Bearing,
    maxBearing: Bearing,
    preferredDirection: Direction,
    alternativeDirection: Direction,
};

export type RoadResult = {
    reference: any,
    coordinates: olCoordinate[],
};

type Direction = {
    bearing: Bearing,
    interestedRoads: Road[],
    // preferredRoad: Road|null,
    // alternativeRoad: Road|null,
};

export default class SideRoadSnapping {
    private readonly roads: Road[] = [];

    private readonly directionsByBearing: Record<Bearing, Direction> = {};

    private readonly directionAngle: Angle;

    private offset: number|null = null;

    constructor(readonly center: olCoordinate, readonly numDirections: number = 8) {
        if (360 % numDirections !== 0) {
            throw new Error('numDirections should divide 360');
        }

        this.directionAngle = 360 / this.numDirections;

        for (let i = 0; i < this.numDirections; i++) {
            const bearing = i * this.directionAngle;

            this.directionsByBearing[bearing] = {
                bearing: bearing,
                interestedRoads: [],
                // preferredRoad: null,
                // alternativeRoad: null,
            };
        }
    }

    public getOffset(): number {
        if (this.offset === null) {
            throw new Error();
        }

        return this.offset;
    }

    public addFromRoad(reference: any, coordinates: olCoordinate[]): void {
        if (this.offset !== null) {
            throw new Error();
        }

        this.addRoad_(reference, coordinates, true);
    }

    public addRoad(reference: any, coordinates: olCoordinate[]): void {
        if (this.offset === null) {
            throw new Error();
        }

        this.addRoad_(reference, coordinates, false);
    }

    private addRoad_(reference: any, coordinates: olCoordinate[], recordOffset: boolean): void {
        if (coordinates[0][0] !== this.center[0] || coordinates[0][1] !== this.center[1]) {
            throw new Error('First point must be center');
        }

        coordinates = coordinates.slice(1);

        let [startBearing, minBearing, maxBearing] = this.determineRouteBearings(coordinates);

        if (recordOffset) {
            this.offset = this.snapBearing(startBearing) - startBearing;
        }

        const preferredBearing = this.snapBearing(normalizeBearing(startBearing + this.offset));

        const alternativeBearing = normalizeBearing(preferredBearing + this.directionAngle * (bearingBetween(preferredBearing, startBearing - this.directionAngle, startBearing) ? 1 : -1));

        const road: Road = {
            id: this.roads.length,
            reference: reference,
            coordinates: coordinates,
            minBearing: minBearing,
            startBearing: startBearing,
            maxBearing: maxBearing,
            preferredDirection: this.directionsByBearing[preferredBearing],
            alternativeDirection: this.directionsByBearing[alternativeBearing],
        };

        this.roads.push(road);

        if (this.roads[road.id] !== road) {
            throw new Error('Corrupt roads array');
        }

        addInterestedRoadToDirection(road.preferredDirection, road);
        addInterestedRoadToDirection(road.alternativeDirection, road);
    }

    private snapBearing(bearing: number): number {
        return normalizeBearing(Math.round(bearing / this.directionAngle) * this.directionAngle);
    }

    /**
     * Return augmented roads based on discrete assignment of roads to directions
     */
    public snap(): RoadResult[]|null {
        if (this.offset === null) {
            throw new Error('Must first add from road');
        }

        const assignment = this.assignRoadsToDirections();

        if (assignment === null) {
            return null;
        }

        const roadResults = [];
        for (const bearingKey in assignment) {
            const roadId = assignment[bearingKey];
            const road = this.roads[roadId];
            const directionBearing = parseInt(bearingKey);

            roadResults.push(this.computeRoadResult(directionBearing, road));
        }

        return roadResults;
    }

    /**
     * Return augmented roads based on discrete assignment of roads to directions
     */
    private computeRoadResult(directionBearing: Bearing, road: Road): RoadResult {
        const roadResult: RoadResult = {
            reference: road.reference,
            coordinates: [],
        };

        const sourceSpanToMin = bearingDiffAngle(road.minBearing, road.startBearing);
        const sourceSpanToMax = bearingDiffAngle(road.startBearing, road.maxBearing);

        const targetMaxSpanAngle = this.directionAngle * 0.8;

        const targetSpanToMin = Math.min(sourceSpanToMin, targetMaxSpanAngle / 2);
        const targetSpanToMax = Math.min(sourceSpanToMax, targetMaxSpanAngle / 2);
        const targetMidBearing = directionBearing;

        const scaleToMin = targetSpanToMin > 0 ? targetSpanToMin / sourceSpanToMin : 1;
        const scaleToMax = targetSpanToMax > 0 ? targetSpanToMax / sourceSpanToMax : 1;

        for (const coordinate of road.coordinates) {
            const {startBearing: sourcePhiBea, distance: r} = olComputeHeading(this.center, coordinate);

            let targetPhiBea: number;
            if (bearingBetween(sourcePhiBea, road.minBearing, road.startBearing)) {
                targetPhiBea = normalizeBearing(
                    modAngle(sourcePhiBea - road.startBearing) * scaleToMin + targetMidBearing
                );
            } else {
                targetPhiBea = normalizeBearing(
                    modAngle(sourcePhiBea - road.startBearing) * scaleToMax + targetMidBearing
                );
            }
            const targetPhiRad = bearingToRadians(targetPhiBea);

            roadResult.coordinates.push([
                this.center[0] + r * Math.cos(targetPhiRad),
                this.center[1] + r * Math.sin(targetPhiRad),
            ]);
        }

        return roadResult;
    }

    /**
     * Branch-and-bound algorithm for determining best distribution of roads over directions, minimizing
     * the squared snapping error
     */
    private assignRoadsToDirections(): Record<Bearing, RoadId>|null {
        const roadRejections: Record<RoadId, number> = {};
        for (const road of this.roads) {
            roadRejections[road.id] = 0;
        }
        let maxRejections = 0;

        const directionAssignments: Record<Bearing, Road|null> = {};
        for (const bearing in this.directionsByBearing) {
            directionAssignments[bearing] = null;
        }
        let directionAssignmentsError: number = 0;

        let bestAssignment: Record<Bearing, Road|null>|null = null;
        let bestAssignmentError = null;

        const assign = (direction: Direction, road: Road) => {
            directionAssignments[direction.bearing] = road;

            maxRejections = 0;
            for (const interestedRoad of direction.interestedRoads) {
                if (interestedRoad !== road) {
                    roadRejections[interestedRoad.id]++;
                }

                maxRejections = Math.max(maxRejections, roadRejections[interestedRoad.id]);
            }

            directionAssignmentsError += smallestAngleBetweenBearings(direction.bearing, road.preferredDirection.bearing) ** 2;
        };
        const unassign = (direction: Direction) => {
            const road = directionAssignments[direction.bearing];
            directionAssignments[direction.bearing] = null;

            maxRejections = 0;
            for (const interestedRoad of direction.interestedRoads) {
                if (interestedRoad !== road) {
                    roadRejections[interestedRoad.id]--;
                }

                maxRejections = Math.max(maxRejections, roadRejections[interestedRoad.id]);
            }

            directionAssignmentsError -= smallestAngleBetweenBearings(direction.bearing, road.preferredDirection.bearing) ** 2;
        };

        let roadIndex = 0;
        let forward = true;
        while (roadIndex >= 0) {
            if (roadIndex === this.roads.length) {
                if (bestAssignmentError === null || directionAssignmentsError < bestAssignmentError) {
                    bestAssignment = Object.assign({}, directionAssignments);
                    bestAssignmentError = directionAssignmentsError;

                    // If this assignment satisfies each road's preference, this must be the overall optimum
                    let overallPreferred = true;
                    for (const bearing in bestAssignment) {
                        if (bestAssignment[bearing] === null) {
                            continue;
                        }

                        const direction = this.directionsByBearing[bearing];
                        const road = bestAssignment[bearing];
                        if (direction !== road.preferredDirection) {
                            overallPreferred = false;
                            break;
                        }
                    }
                    if (overallPreferred) {
                        break;
                    }
                }

                forward = false;
                roadIndex--;
                continue;
            }

            const road = this.roads[roadIndex];

            if (forward) {
                if (bestAssignmentError !== null && directionAssignmentsError > bestAssignmentError) {
                    // We already have a better solution, so backtrack to try other branch
                    forward = false;
                    roadIndex--;
                } else if (maxRejections >= 2) {
                    // Some road will not be able to be assigned, so backtrack to try other branch
                    forward = false;
                    roadIndex--;
                } else if (
                    directionAssignments[road.preferredDirection.bearing] !== null
                    && directionAssignments[road.alternativeDirection.bearing] !== null
                ) {
                    // Cannot assign road to a valid direction, so backtrack to try other branch
                    forward = false;
                    roadIndex--;
                } else if (directionAssignments[road.preferredDirection.bearing] === null) {
                    // Choose preferred direction and continue forward
                    assign(road.preferredDirection, road);
                    roadIndex++;
                } else if (directionAssignments[road.alternativeDirection.bearing] === null) {
                    // Choose alternative direction and continue forward
                    assign(road.alternativeDirection, road);
                    roadIndex++;
                } else {
                    throw new Error('Unexpected forward mode');
                }
            } else {
                if (
                    directionAssignments[road.preferredDirection.bearing] === road
                    && directionAssignments[road.alternativeDirection.bearing] === null
                ) {
                    // Choose other branch
                    unassign(road.preferredDirection);
                    assign(road.alternativeDirection, road);
                    forward = true;
                    roadIndex++;
                } else if (directionAssignments[road.preferredDirection.bearing] === road) {
                    // Handled all possible branches, backtrack to try other branch
                    unassign(road.preferredDirection);
                    roadIndex--;
                } else if (directionAssignments[road.alternativeDirection.bearing] === road) {
                    // Handled all possible branches, backtrack to try other branch
                    unassign(road.alternativeDirection);
                    roadIndex--;
                } else {
                    throw new Error('Unexpected backward mode');
                }
            }
        }

        if (bestAssignment === null) {
            return null;
        }

        const assignment: Record<Bearing, RoadId> = {};
        for (const bearing in bestAssignment) {
            if (bestAssignment[bearing] === null) {
                continue;
            }

            const road = bestAssignment[bearing];

            assignment[bearing] = road.id;
        }

        return assignment;
    }

    private determineRouteBearings(coordinates: olCoordinate[]): [Bearing, Bearing, Bearing] {
        if (coordinates.length === 0) {
            throw new Error('Cannot determine bearings without coordinates');
        }

        const initialBearing = this.computeBearing(coordinates[0]);
        let previousBearing = initialBearing;

        let minBearing = initialBearing,
            maxBearing = initialBearing;

        let revolutions = 0;

        for (let i = 1; i < coordinates.length; i++) {
            const coordinate = coordinates[i];

            let bearing = revolutions * 360 + this.computeBearing(coordinate);

            if (Math.abs(bearing - previousBearing) > 180) {
                if (bearing < previousBearing) {
                    bearing += 360;
                    revolutions++;
                } else {
                    bearing -= 360;
                    revolutions--;
                }
            }

            minBearing = Math.min(minBearing, bearing);
            maxBearing = Math.max(maxBearing, bearing);

            previousBearing = bearing;
        }

        const startBearing = olPathStartBearing([this.center, ...coordinates], 10);

        return [
            normalizeBearing(startBearing),
            normalizeBearing(minBearing),
            normalizeBearing(maxBearing),
        ];
    }

    private computeBearing(coordinate: olCoordinate): Bearing {
        return olComputeHeading(this.center, coordinate).startBearing;
    }
}

function addInterestedRoadToDirection(direction: Direction, road: Road): void {
    direction.interestedRoads.push(road);

    // if (
    //     !direction.preferredRoad
    //     || smallestAngleBetweenBearings(road.midBearing, direction.bearing)
    //     < smallestAngleBetweenBearings(direction.preferredRoad.midBearing, direction.bearing)
    // ) {
    //     direction.alternativeRoad = direction.preferredRoad;
    //     direction.preferredRoad = road;
    //     return;
    // }
    //
    // if (
    //     !direction.alternativeRoad
    //     || smallestAngleBetweenBearings(road.midBearing, direction.bearing)
    //     < smallestAngleBetweenBearings(direction.alternativeRoad.midBearing, direction.bearing)
    // ) {
    //     direction.alternativeRoad = road;
    //     return;
    // }
}

function normalizeBearing(bearing: Bearing): Bearing {
    while (bearing >= 360) {
        bearing -= 360;
    }

    while (bearing < 0) {
        bearing += 360;
    }

    return bearing;
}

function modAngle(angle: Angle): Angle {
    while (angle >= 180) {
        angle -= 360;
    }

    while (angle < -180) {
        angle += 360;
    }

    return angle;
}

function smallestAngleBetweenBearings(bearing1: Bearing, bearing2: Bearing): Angle {
    const angle = Math.abs(normalizeBearing(bearing1 - bearing2));

    return angle > 180 ? 360 - angle : angle;
}

function bearingDiffAngle(lowBearing: Bearing, highBearing: Bearing): Angle {
    if (lowBearing > highBearing) {
        lowBearing -= 360;
    }

    return highBearing - lowBearing;
}

// TODO/NOTE: exported interal function; should be moved/refactored
export function bearingBetween(bearing: Bearing, min: Bearing, max: Bearing): boolean {
    bearing = normalizeBearing(bearing);
    min = normalizeBearing(min);
    max = normalizeBearing(max);

    if (max < min) {
        if (bearing < max) {
            return true;
        }

        max += 360;
    }

    return min <= bearing && bearing < max;
}
