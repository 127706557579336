import Action from "./Action";
import RouteIntermediate from "../Main/RouteIntermediate";
import {Serialization} from "../Main/Serializer";

export default class RouteDeleteIntermediateAction implements Action {

    constructor(readonly routeIntermediate: RouteIntermediate) {
    }

    public apply() {
        this.routeIntermediate.intermediates.removeIntermediate(this.routeIntermediate);
    }

    public revert() {
        this.routeIntermediate.intermediates.addIntermediate(this.routeIntermediate);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.routeIntermediate.intermediates.route.id,
            index: this.routeIntermediate.index,
            intermediateNumber: this.routeIntermediate.getIntermediateNumber(),
        };
    }
}
