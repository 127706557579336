
export default class ErrorSignal extends Error {
    readonly isErrorSignal = true;
    readonly code;

    constructor(code, ...args) {
        super(...args);

        this.code = code;
    }
}
