import $ from "jquery";
import UserInterface from "../Main/UserInterface";
import {App} from "@vue/runtime-core";

export default class ErrorLogger
{
    private static userInterface: UserInterface = null;

    static setUserInterface(userInterface: UserInterface): void
    {
        ErrorLogger.userInterface = userInterface;
    }

    static logError(error) {
        try {
            const message = <Record<string, any>>{};
            if (typeof error?.fileName !== 'undefined') message.fileName = error.fileName;
            if (typeof error?.lineNumber !== 'undefined') message.lineNumber = error.lineNumber;
            if (typeof error?.columnNumber !== 'undefined') message.columnNumber = error.columnNumber;
            if (typeof error?.message !== 'undefined') message.message = error.message;
            if (typeof error?.stack !== 'undefined') message.stack = error.stack;

            message.navigator = <Record<string, any>>{};
            for (const key of ['appCodeName', 'appName', 'appVersion', 'cookieEnabled', 'language', 'onLine', 'platform', 'userAgent']) {
                message.navigator[key] = navigator[key];
            }

            message.raw = error;

            let details = {};

            Promise.resolve().then(() => {
                if (ErrorLogger.userInterface === null) {
                    message.bootError = true;
                    return;
                }

                return ErrorLogger.userInterface.requestErrorReport(JSON.stringify(message)).then((detailsValues) => {
                    details = detailsValues;
                });
            }).then(() => {
                $.post('server/stats.php?request=js_error', {
                    message: JSON.stringify(message),
                    details: {
                        description: details.description,
                        history: JSON.stringify(details.history),
                        workspace: JSON.stringify(details.workspace),
                        contact: details.contact,
                    },
                });
            });
        } catch(e) {
            console.log(e);
        }
    }

    static bindToWindow(): void
    {
        window.addEventListener('error', function(event) {
            if (event.error === null && event.message === 'Script error.') {
                // https://sentry.io/answers/script-error/
                // We get a reproducible Script error when downloading an xlsx of locations,
                // and navigating back to the application that seems to be unrelated to
                // our code. This only happens when opening the application freshly, not
                // when opening the browser freshly on an existing plattekaart tab.
                // When this error happens, ignore it, as we do not have any more details
                // than the fact 'an error' has occurred.
                // Slightly related: https://gitlab.com/marcovo/plattekaart/-/issues/16
                return;
            }

            if(event.error && event.error.isUserError) {
                alert(event.error.message);
                event.preventDefault();
            } else {
                ErrorLogger.logError(event.error);
            }
        });

        window.addEventListener('unhandledrejection', function (event) {
            if(event?.reason?.isUserError) {
                alert(event?.reason?.message);
                event.preventDefault();
            } else {
                ErrorLogger.logError(event?.reason);
            }
        })
    }

    static bindToVue(app: App): void
    {
        app.config.errorHandler = function(error, vm, info) {
            if(error.isUserError) {
                alert(error.message);
            } else {
                ErrorLogger.logError(error);
                throw error;
            }
        }
    }
}
