import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import UserError from "../Util/UserError";
import CoordinateConverter from "../Util/CoordinateConverter";
import {WGS84System} from "../Coordinates/WGS84";
import {DutchGridSystem} from "../Coordinates/DutchGrid";
import {createSvgEl} from "./Util/Svg";

type Coordinate = [number, number];
type CoordinateLine = Coordinate[];
export type ProjectedCoordinates = {
    route: CoordinateLine,
    sideRoads: {index: number, coordinates: CoordinateLine}[],
};

export type Dimensioning = {
    minX: number,
    minY: number,
    maxX: number,
    maxY: number,
    spanX: number,
    spanY: number,
    dimension: number,
    padding: number,
    width: number,
    height: number,
    widthPx: number,
    heightPx: number,
};

export default abstract class GeodeticLineTechnique<ConfigType> extends ImageGeneratingTechnique<ConfigType>
{
    protected readonly TECHNIQUE_TEXT_TITLE : string;

    protected computeMinMax(projectedCoordinates: ProjectedCoordinates): {minX: number, minY: number, maxX: number, maxY: number} {
        let minX = null, maxX = null, minY = null, maxY = null;
        for (const coordinateLine of [projectedCoordinates.route, ...projectedCoordinates.sideRoads.map(s => s.coordinates)]) {
            for (const coordinate of coordinateLine) {
                if (minX === null || coordinate[0] < minX) {
                    minX = coordinate[0];
                }

                if (maxX === null || coordinate[0] > maxX) {
                    maxX = coordinate[0];
                }

                if (minY === null || coordinate[1] < minY) {
                    minY = coordinate[1];
                }

                if (maxY === null || coordinate[1] > maxY) {
                    maxY = coordinate[1];
                }
            }
        }

        return {minX, minY, maxX, maxY};
    }

    protected computeDimensioning(projectedCoordinates: ProjectedCoordinates): Dimensioning {
        if (projectedCoordinates.route.length < 2) {
            throw new UserError('Er kan geen ' + this.TECHNIQUE_TEXT_TITLE.toLowerCase() + ' gemaakt worden van een route met minder dan 2 punten.');
        }

        const {minX, maxX, minY, maxY} = this.computeMinMax(projectedCoordinates);

        const spanX = maxX - minX;
        const spanY = maxY - minY;

        const dimension = Math.max(spanX, spanY);
        const padding = Math.round(dimension / 50);
        const width = spanX + 2 * padding;
        const height = spanY + 2 * padding;

        let widthPx, heightPx;
        if (width > height) {
            widthPx = 1000;
            heightPx = widthPx / width * height;
        } else {
            heightPx = 1000;
            widthPx = heightPx / height * width;
        }

        return {
            minX, minY,
            maxX, maxY,
            spanX, spanY,
            dimension,
            padding,
            width, height,
            widthPx, heightPx,
        };
    }

    protected getProjectedCoordinates(includeSideRoads: boolean): ProjectedCoordinates {
        const coordinates = [];

        const wgs84System = new WGS84System();
        const dutchGridSystem = new DutchGridSystem();

        let sumX = 0, sumY = 0;

        // Convert all coordinates to cartesian
        for (const olCoordinate of this.route.getCoordinates()) {
            const coordinate = CoordinateConverter.convert(
                wgs84System.fromOpenLayersCoordinate(olCoordinate),
                dutchGridSystem
            );

            coordinates.push([coordinate.getX(), coordinate.getY()]);
            sumX += coordinate.getX();
            sumY += coordinate.getY();
        }

        // Rotate coordinates around center
        const centerX = sumX / coordinates.length;
        const centerY = sumY / coordinates.length;

        const rotatedCoordinates = [];
        const c = Math.cos(this.config.rotation / 180 * Math.PI);
        const s = Math.sin(this.config.rotation / 180 * Math.PI);

        for (const coordinate of coordinates) {
            rotatedCoordinates.push([
                centerX + c * (coordinate[0] - centerX) + s * (coordinate[1] - centerY),
                centerY + -s * (coordinate[0] - centerX) + c * (coordinate[1] - centerY),
            ]);
        }

        const projectedCoordinates = <ProjectedCoordinates>{
            route: rotatedCoordinates,
            sideRoads: []
        };

        if (includeSideRoads) {
            for (const intermediate of this.route.getIntermediates().getIntermediatesList()) {
                for (const sideRoad of intermediate.getSideRoads()) {
                    const rotatedSideRoadCoordinates = <CoordinateLine>[];

                    for (const olCoordinate of sideRoad.getCoordinates()) {
                        const coordinate = CoordinateConverter.convert(
                            wgs84System.fromOpenLayersCoordinate(olCoordinate),
                            dutchGridSystem
                        );

                        rotatedSideRoadCoordinates.push([
                            centerX + c * (coordinate.getX() - centerX) + s * (coordinate.getY() - centerY),
                            centerY + -s * (coordinate.getX() - centerX) + c * (coordinate.getY() - centerY),
                        ]);
                    }

                    projectedCoordinates.sideRoads.push({
                        index: intermediate.index,
                        coordinates: rotatedSideRoadCoordinates,
                    });
                }
            }

            if (projectedCoordinates.sideRoads.length === 0) {
                this.route.routeCollection.userInterface.toasts.addCategoricalToast(
                    'routeTechnique' + this.constructor.TECHNIQUE_NAME + 'MissingSideRoads',
                    'Missende zijwegen',
                    'Voeg beslispunten en zijwegen toe, om deze te kunnen intekenen.'
                );
            }
        }

        return projectedCoordinates;
    }

    protected drawRoute(svg: SVGSVGElement, dim: Dimensioning, projectedCoordinates: ProjectedCoordinates, width: number = 0.005): void {
        for (const sideRoad of projectedCoordinates.sideRoads) {
            svg.appendChild(createSvgEl('polyline', {
                points: sideRoad.coordinates.map(c => (c[0] - dim.minX + dim.padding) + ',' + (dim.maxY - c[1] + dim.padding)).join(' '),
                fill: 'none',
                stroke: '#000000',
                strokeWidth: width * dim.dimension,
            }));
        }

        svg.appendChild(createSvgEl('polyline', {
            points: projectedCoordinates.route.map(c => (c[0] - dim.minX + dim.padding) + ',' + (dim.maxY - c[1] + dim.padding)).join(' '),
            fill: 'none',
            stroke: '#000000',
            strokeWidth: width * dim.dimension,
        }));
    }

    protected drawScale(svg: SVGSVGElement, dim: Dimensioning): void {
        let scaleWidth = 10 ** Math.floor(Math.log10(dim.spanX / 8));
        if (scaleWidth < dim.spanX / 40) {
            scaleWidth *= 5;
        } else if (scaleWidth < dim.spanX / 16) {
            scaleWidth *= 2;
        }

        const scaleRefX = dim.padding;
        const scaleRefY = dim.height - dim.padding;
        svg.appendChild(createSvgEl('polyline', {
            points: [
                (scaleRefX) + ',' + (scaleRefY - 0.02 * dim.dimension),
                (scaleRefX) + ',' + (scaleRefY),
                (scaleRefX + scaleWidth) + ',' + (scaleRefY),
                (scaleRefX + scaleWidth) + ',' + (scaleRefY - 0.02 * dim.dimension),
            ].join(' '),
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.002 * dim.dimension,
        }));

        const scaleText = createSvgEl('text', {
            x: scaleRefX + 0.5 * scaleWidth,
            y: scaleRefY - 0.01 * dim.dimension,
            textAnchor: 'middle',
            fill: '#000000',
        });
        scaleText.textContent = scaleWidth + 'm';
        scaleText.style.font = (dim.dimension * 0.02) + 'px sans-serif';
        svg.appendChild(scaleText);
    }

    protected drawNorthArrow(svg: SVGSVGElement, dim: Dimensioning): void {
        const length = 0.05 * dim.dimension;
        const northArrowRefX = dim.width - dim.padding - 0.01 * dim.dimension;
        const northArrowRefY = dim.height - dim.padding - length / 2;

        const c = Math.cos(this.config.rotation / 180 * Math.PI);
        const s = Math.sin(this.config.rotation / 180 * Math.PI);

        const from = [
            northArrowRefX - length / 2 * s,
            northArrowRefY + length / 2 * c,
        ];

        const to = [
            northArrowRefX + length / 2 * s,
            northArrowRefY - length / 2 * c,
        ];

        this.drawDoubleArrow(svg, from, to, {
            stroke: '#aaaaaa',
            strokeWidth: 0.002 * dim.dimension,
            fill: 'none',
        }, {
            arrowWidth: 0.007 * dim.dimension,
            tipWidth: 0.007 * dim.dimension,
            tipLength: 0.03 * dim.dimension,
        });

        const scaleText = createSvgEl('text', {
            x: northArrowRefX - length * 0.75,
            y: northArrowRefY,
            textAnchor: 'middle',
            fill: '#aaaaaa',
        });
        scaleText.textContent = 'N';
        scaleText.style.font = 'bold ' + (dim.dimension * 0.02) + 'px sans-serif';
        svg.appendChild(scaleText);
    }
}
