import {olNormalize} from "../../Util/Math";
import {createSvgEl} from "./Svg";

type Anchor = 'tip' | 'body-center';

interface Arrow {
    drawSvg(svg: SVGSVGElement, from: [number, number], to: [number, number]): void;
}

export class TipArrow implements Arrow {
    private anchor: Anchor = 'tip';

    constructor(readonly bodyLength: number, readonly tailLength: number, readonly sideWidth: number) {
    }

    public setAnchor(anchor: Anchor): this {
        this.anchor = anchor;
        return this;
    }

    public drawSvg(svg: SVGSVGElement, from: [number, number], to: [number, number]) {
        const polygon = this.computePolygon(from, to);

        svg.appendChild(createSvgEl('polygon', {
            points: polygon.map(c => c[0] + ',' + c[1]).join(' '),
            fill: '#000000',
            stroke: 'none',
        }));
    }

    private computePolygon(from: [number, number], to: [number, number]): [number, number][] {
        const vector = olNormalize([
            to[0] - from[0],
            to[1] - from[1],
        ]);

        const perpendicular = [
            -vector[1],
            vector[0],
        ];

        let tip;
        if (this.anchor === 'body-center') {
            tip = [
                to[0] + this.bodyLength / 2 * vector[0],
                to[1] + this.bodyLength / 2 * vector[1],
            ];
        } else {
            tip = to;
        }

        const polygon: [number, number][] = [];
        polygon.push(tip);

        polygon.push([
            tip[0] - vector[0] * (this.bodyLength + this.tailLength) + perpendicular[0] * this.sideWidth,
            tip[1] - vector[1] * (this.bodyLength + this.tailLength) + perpendicular[1] * this.sideWidth,
        ]);

        polygon.push([
            tip[0] - vector[0] * this.bodyLength,
            tip[1] - vector[1] * this.bodyLength,
        ]);

        polygon.push([
            tip[0] - vector[0] * (this.bodyLength + this.tailLength) - perpendicular[0] * this.sideWidth,
            tip[1] - vector[1] * (this.bodyLength + this.tailLength) - perpendicular[1] * this.sideWidth,
        ]);

        return polygon;
    }
}
