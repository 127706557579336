import Action from "./Action";
import Route, {LineStyleType} from "../Main/Route";
import {Serialization} from "../Main/Serializer";

export default class RouteChangeLineStyleTypeAction implements Action {

    private readonly oldLineStyleType: LineStyleType;

    constructor(private route: Route, private newLineStyleType: LineStyleType) {
        this.oldLineStyleType = this.route.getLineStyleType();
    }

    public apply() {
        this.route.setLineStyleType(this.newLineStyleType);
    }

    public revert() {
        this.route.setLineStyleType(this.oldLineStyleType);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldLineStyleType: this.oldLineStyleType,
            newLineStyleType: this.newLineStyleType,
        };
    }
}
