import Action from "./Action";
import Route from "../Main/Route";
import {Coordinate as olCoordinate} from "ol/coordinate";
import RouteCoordinateMutationAction from "./RouteCoordinateMutationAction";
import RouteIntermediate from "../Main/RouteIntermediate";
import {Serialization} from "../Main/Serializer";

export default class RouteAddIntermediateOnEdgeAction extends RouteCoordinateMutationAction {

    private routeIntermediate: RouteIntermediate|null = null;

    constructor(readonly route: Route, readonly index: number, readonly coordinate: olCoordinate) {

        const coordinates = route.getCoordinates();
        coordinates.splice(index, 0, coordinate);

        route.getGeometry().setCoordinates(coordinates);

        super(route, route.getOldCoordinates());
    }

    public apply(initial: boolean) {
        super.apply(initial);

        if (this.routeIntermediate === null) {
            this.routeIntermediate = new RouteIntermediate(this.route.getIntermediates(), this.index);
        }

        this.route.getIntermediates().addIntermediate(this.routeIntermediate);

        this.route.triggerChanged(false);
    }

    public revert() {
        this.route.getIntermediates().removeIntermediate(this.routeIntermediate);

        super.revert();

        this.route.triggerChanged(false);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        const serialization = super.serializeForDebug();

        serialization.index = this.routeIntermediate.index;
        serialization.intermediateNumber = this.routeIntermediate.getIntermediateNumber();
        serialization.coordinate = this.coordinate;

        return serialization;
    }
}
