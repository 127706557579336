import Route from "../Main/Route";
import Location from "../Main/Location";
import {createApp as createVueApp, isReactive, toRaw} from 'vue';
import CutoutSettingsModal from '../components/CutoutSettingsModal.vue';
import CutoutTemplates from '../components/CutoutTemplates.vue';
import Bookmarks from '../components/Bookmarks.vue';
import SearchBar from '../components/SearchBar.vue';
import CoordinatePanel from '../components/CoordinatePanel.vue';
import SketchRoutePanel from '../components/SketchRoutePanel.vue';
import GeneralSettingsModal from '../components/GeneralSettingsModal.vue';
import RouteSettingsModal from '../components/RouteSettingsModal.vue';
import LocationSettingsModal from '../components/LocationSettingsModal.vue';
import {Modal} from "bootstrap";

import $ from "jquery";
import {App} from "@vue/runtime-core";
import ErrorLogger from "./ErrorLogger";
import RouteIntermediatesPanel from "../components/RouteIntermediatesPanel.vue";

export function trimTrailingZeroDecimalPlaces(number: number, fractionDigits: number): string {
    let text = number.toFixed(fractionDigits);

    if(fractionDigits === 0) {
        return text;
    }

    for(let i=text.length-1; i>=0; i--) {
        if(text.substr(i, 1) === '0') {
            text = text.substr(0, i);
        } else {
            if(text.substr(i, 1) === '.' || text.substr(i, 1) === ',') {
                text = text.substr(0, i);
            }
            break;
        }
    }

    return text;
}

export function padLeadingZeros(number: number|string, length: number) {
    number = number + '';
    while(number.length < length) {
        number = '0' + number;
    }
    return number;
}

export function strSlug(text: string): string {
    return text.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
}

export function formatCm(number: number, decimals: number = 1): string {
    return formatMeters(number/100, decimals, -2);
}

export function formatMeters(number: number, decimals: number = 1, displayOrderExponent: number = null): string {

    const numberOrderExponent = Math.floor(Math.log10(number));
    if(displayOrderExponent === null) {
        displayOrderExponent = 3 * Math.floor(numberOrderExponent / 3);
    }

    const displayOrder = 10 ** displayOrderExponent;
    const roundingOrder = displayOrder / (10**decimals);
    const roundedNumber = Math.round(number / roundingOrder) * roundingOrder;

    const numberText = trimTrailingZeroDecimalPlaces(roundedNumber / displayOrder, decimals);

    let unit: string;
    if(displayOrderExponent == 0) {
        unit = 'm';
    } else if(displayOrderExponent == 3) {
        unit = 'km';
    } else if(displayOrderExponent == -3) {
        unit = 'mm';
    } else if(displayOrderExponent == -2) {
        unit = 'cm';
    } else if(displayOrderExponent == -1) {
        unit = 'dm';
    } else if(displayOrderExponent == 1) {
        unit = 'dam';
    } else if(displayOrderExponent == 2) {
        unit = 'hm';
    } else {
        throw new Error('Could not find unit for order ' + displayOrderExponent);
    }

    return numberText + ' ' + unit;
}

export function findChildNode(node: Node, callback: (node) => boolean): Node|null {
    for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i];
        if(callback(childNode)) {
            return childNode;
        }
    }
    return null;
}

export function uint8arrayToString(input: Uint8Array): string {
    const output = [];

    for (let i = 0; i < input.length; i++) {
        output.push(String.fromCharCode(input[i]));
    }

    return output.join('');
}

export function stringToUnit8array(input: string): Uint8Array {
    return Uint8Array.from(input, (char) => char.charCodeAt(0));
}

export function formatDateTime(date: Date|number): string {
    if(!(date instanceof Date)) {
        date = new Date(date);
    }

    const minutes = date.getMinutes();
    const minuteString = ((minutes < 10) ? '0' : '') + minutes.toString();
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear() + ' ' + date.getHours() + ':' + minuteString;
}

export function copyInput(selector) {
    const $input = $(selector);
    $input[0].select();
    document.execCommand('copy');
}

export function isIOS() {
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function isMobile() {
    // From http://detectmobilebrowsers.com/ . Currently only used for a minor functionality detail. When
    // more critical decisions need to be made, the check below may not be sufficiently reliable...
    // @ts-ignore
    const a = navigator.userAgent||navigator.vendor||window.opera;
    return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4));
}

export function eachPromise<T>(items: T[], callback: (item: T) => Promise<void>): Promise<void> {
    if(items.length === 0) {
        return Promise.resolve();
    }

    const processItem = (i: number): Promise<void> => {
        return callback(items[i]).then(() => {
            if(i + 1 < items.length) {
                return processItem(i + 1);
            } else {
                return Promise.resolve();
            }
        });
    };

    return processItem(0);
}

export function throttledParallelEachPromise<T>(threads: number, itemGenerator: () => Generator<Promise<void>>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const items = itemGenerator();

        let allStarted = false;
        let numStarted = 0;
        let numDone = 0;
        let killed = false;

        const processItem = (): Promise<void> => {
            if(killed) {
                return Promise.resolve();
            }

            if(allStarted) {
                if(numDone === numStarted) {
                    resolve();
                }
                return Promise.resolve();
            }

            const itemResult = items.next();
            if(itemResult.done) {
                allStarted = true;
                // Make sure we always check the overall done status
                return processItem();
            }

            numStarted++;

            const item = <Promise<void>>itemResult.value;
            return item.then(() => {
                numDone++;
                return processItem();
            });
        };

        for(let i = 0; i < threads && !allStarted; i++) {
            processItem().catch(() => {
                killed = true;
                reject();
            });
        }
    })
}

export function presentDownload(filename: string, contents: string, mimetype = 'text/plain') {
    presentDownloadRaw(filename, 'data:' + mimetype + ';charset=utf-8,' + encodeURIComponent(contents));
}

export function presentDownloadRaw(filename: string, contents: string) {
    const element = document.createElement('a');
    element.setAttribute('href', contents);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function selectHtmlElementText(node: HTMLElement) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);

    if (node.childNodes.length === 1 && node.childNodes[0] instanceof Text) {
        const textNode = node.childNodes[0];
        const text = textNode.textContent.trim();
        const index = textNode.textContent.indexOf(text);
        if (index > -1) {
            range.setStart(textNode, index);
            range.setEnd(textNode, index + text.length);
        }
    }

    selection.removeAllRanges();
    selection.addRange(range);
}

export function generateKml(objects: (Route|Location)[]) {
    // https://developers.google.com/kml/documentation/kml_tut
    // https://developers.google.com/kml/documentation/kmlreference
    const doc = document.implementation.createDocument('', '', null);

    const kml = doc.createElement('kml');
    doc.appendChild(kml);

    const documentEl = doc.createElement('Document');
    kml.appendChild(documentEl);

    const documentName = doc.createElement('name');
    documentName.textContent = 'plattekaart.nl';
    documentEl.appendChild(documentName);

    for (const object of objects) {
        documentEl.appendChild(object.generateKmlNode(doc));
    }

    return (new XMLSerializer()).serializeToString(doc.documentElement);
}

export function generateGpx(objects: (Route|Location)[]) {
    // http://www.topografix.com/gpx/1/1/

    const doc = document.implementation.createDocument('', '', null);

    const gpx = doc.createElement('gpx');
    gpx.setAttribute('version', '1.1');
    gpx.setAttribute('creator', 'plattekaart.nl');
    doc.appendChild(gpx);

    for (const object of objects) {
        gpx.appendChild(object.generateGpxNode(doc));
    }

    return (new XMLSerializer()).serializeToString(doc.documentElement);
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function enumValues<O extends object, K extends keyof O = keyof O>(obj: O): O[keyof O][] {
    return enumKeys(obj).map(k => obj[k]);
}

export function createVue(el, props): App {
    const app = createVueApp(props);

    ErrorLogger.bindToVue(app);

    app.component('CutoutSettingsModal', CutoutSettingsModal);
    app.component('CutoutTemplates', CutoutTemplates);
    app.component('Bookmarks', Bookmarks);
    app.component('SearchBar', SearchBar);
    app.component('CoordinatePanel', CoordinatePanel);
    app.component('SketchRoutePanel', SketchRoutePanel);
    app.component('GeneralSettingsModal', GeneralSettingsModal);
    app.component('RouteSettingsModal', RouteSettingsModal);
    app.component('LocationSettingsModal', LocationSettingsModal);
    app.component('RouteIntermediatesPanel', RouteIntermediatesPanel);

    app.mount(el);

    return app;
}

export function bsModal(selector)
{
    const element = typeof selector === 'string' ? document.querySelector(selector) : selector;

    return Modal.getOrCreateInstance(element);
}

export function unreactive(object)
{
    return isReactive(object) ? toRaw(object) : object;
}

export function noModifierKeys(event: KeyboardEvent|MouseEvent|TouchEvent): boolean {
    // From OpenLayers, ol/events/condition.js
    return (
        !event.altKey &&
        !(event.metaKey || event.ctrlKey) &&
        !event.shiftKey
    );
}

export function shiftKeyOnly(event: KeyboardEvent|MouseEvent|TouchEvent): boolean {
    // From OpenLayers, ol/events/condition.js
    return (
        !event.altKey &&
        !(event.metaKey || event.ctrlKey) &&
        event.shiftKey
    );
}

export function max(a: number|null, b: number|null): number|null {
    if (a === null) {
        return b;
    }

    if (b === null) {
        return a;
    }

    return Math.max(a, b);
}
