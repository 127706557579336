import {Circle as CircleStyle, Fill, Stroke, Style, RegularShape, Text} from 'ol/style';
import {Draw, Modify} from 'ol/interaction';
import {GeometryCollection, LineString, Point, Polygon} from 'ol/geom';
import {Coordinate as olCoordinate} from "ol/coordinate";
import {getArea, getLength} from 'ol/sphere';
import {Feature, MapBrowserEvent} from "ol";
import RouteCollection from "./RouteCollection";
import {asArray} from "ol/color";
import * as olExtent from 'ol/extent';
import {fromLonLat, toLonLat} from "ol/proj";
import RouteCoordinateMutationAction from "../ActionHistory/RouteCoordinateMutationAction";
import RouteDropSketchAction from "../ActionHistory/RouteDropSketchAction";
import {Serialization} from "./Serializer";
import RouteReverseAction from "../ActionHistory/RouteReverseAction";
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import ConversionComposition from "../Conversion/ConversionComposition";
import CoordinateConverter from "../Util/CoordinateConverter";
import {
    diff,
    lineSegmentIntersectsPolygonEdges,
    min,
    olComputeHeading,
    Point as MPoint,
    toTurfPolygon
} from "../Util/Math";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {noModifierKeys, platformModifierKey, platformModifierKeyOnly, primaryAction} from "ol/events/condition";
import $ from "jquery";
import {reactive} from "vue";
import RouteIntermediates, {RouteIntermediatesReactiveProps} from "./RouteIntermediates";
import Location from "./Location";

export type LineStyleType = 'solid'|'dashed'|'dotted';

export type RouteReactiveProps = {
    type: 'route',
    id: number,
    name: string,
    color: string,
    visibleOnMap: boolean,
    lineStyleType: LineStyleType,
    quickEditingName: boolean,
    hasFocus: boolean,
    editMode: RouteEditMode|null,
    formattedLength: string,
    formattedArea: string,
    formattedBearing: string,
    hasInitializedIntermediates: boolean,
    intermediatesRP: RouteIntermediatesReactiveProps,
};

export type RouteEditMode = 'draw'|'intermediate'|'side-road';

export default class Route {

    static idIncrement: number = 0;

    reactiveProps: RouteReactiveProps;

    private intermediates: RouteIntermediates|null = null;

    readonly id: number;

    private oldCoordinates: olCoordinate[] = [];

    private name = null;
    private color = null;

    private lineStringStyles;
    private labelStyle;
    private modifyStyle;
    private tick1Style;
    private tick5Style;
    private tick10Style;

    private modifyInteraction;
    private drawInteraction;
    private mainFeature: Feature<any>;

    private visibleOnMap = true;
    private hasMouseover = false;

    private onChangeCallbacks: (() => void)[] = [];

    private lineStyleType:LineStyleType = 'solid';

    private modifyDragging = false;

    constructor(readonly routeCollection: RouteCollection, readonly isSketch = false) {
        this.id = Route.idIncrement++;

        this.reactiveProps = <RouteReactiveProps>reactive({
            type: 'route',
            id: this.id,
            name: this.name,
            color: this.color,
            visibleOnMap: this.visibleOnMap,
            lineStyleType: this.lineStyleType,
            quickEditingName: false,
            hasFocus: false,
            formattedLength: '',
            formattedArea: '',
            formattedBearing: '',
            hasInitializedIntermediates: this.intermediates !== null,
            intermediatesRP: this.intermediates?.reactiveProps,
        });

        this.initStyles();
        this.initInteraction();

        this.updateReactiveProps();
    }

    private initStyles() {
        const color = asArray(this.color || '#000000').slice(0);
        color[3] = this.hasMouseover ? 0.7 : 0.5;

        this.lineStringStyles = [];

        const lineDash = this.lineStyleType === 'dashed' ? [10, 10] : this.lineStyleType === 'dotted' ? [2, 5] : null;

        if (!this.isSketch) {
            this.lineStringStyles.push(new Style({
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, ' + (this.hasMouseover ? 0.6 : 0.4) + ')',
                    width: this.hasMouseover ? 5 : 4,
                    lineDash: lineDash,
                }),
            }));
        }

        this.lineStringStyles.push(new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
            }),
            stroke: new Stroke({
                color: this.isSketch ? 'rgba(0, 0, 0, 0.5)' : color,
                width: this.hasMouseover ? 3 : 2,
                lineDash: lineDash,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }));

        this.labelStyle = new Style({
            text: new Text({
                font: '14px Calibri,sans-serif',
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 1)',
                }),
                backgroundFill: new Fill({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                padding: [3, 3, 3, 3],
                textBaseline: 'bottom',
                offsetY: -15,
            }),
            image: new RegularShape({
                radius: 7,
                points: 3,
                angle: Math.PI,
                displacement: [0, 9],
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
            }),
        });

        this.modifyStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.4)',
                }),
            }),
            text: new Text({
                font: '12px Calibri,sans-serif',
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 1)',
                }),
                backgroundFill: new Fill({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                padding: [2, 2, 2, 2],
                textAlign: 'left',
                offsetX: 15,
            }),
        });

        this.tick1Style = new Style({
            image: new RegularShape({
                radius: this.isSketch ? 2 : 3,
                points: 2,
                rotation: 0,
                rotateWithView: true,
                displacement: [0, 0],
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.6)',
                }),
            }),
        });

        this.tick5Style = new Style({
            image: new RegularShape({
                radius: this.isSketch ? 4 : 5,
                points: 2,
                rotation: 0,
                rotateWithView: true,
                displacement: [0, 0],
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.6)',
                }),
            }),
        });

        this.tick10Style = new Style({
            image: new RegularShape({
                radius: this.isSketch ? 6 : 7,
                points: 2,
                rotation: 0,
                rotateWithView: true,
                displacement: [0, 0],
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.6)',
                }),
            }),
            text: new Text({
                font: '12px Calibri,sans-serif',
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 1)',
                }),
                backgroundFill: new Fill({
                    color: 'rgba(255, 255, 255, 0.4)',
                }),
                rotation: 0,
                rotateWithView: true,
                padding: [2, 2, 2, 2],
                textBaseline: 'bottom',
                offsetY: -6,
            })
        });
    }

    private initInteraction() {
        const routeCollection = this.routeCollection;

        const olMap = routeCollection.getOpenlayersMap();

        const styleFunction = (feature) => {
            if (!this.visibleOnMap) {
                return [new Style({})];
            }

            const styles = this.lineStringStyles.slice();

            if (this.reactiveProps.hasFocus) {
                const geometry = feature.getGeometry();
                const type = geometry.getType();

                if (type === 'LineString' && geometry) {
                    // const totalLength = getLength(geometry);
                    const inverseZoom = 28 - olMap.getView().getZoom(); // 28 is max zoom
                    let every1 = 2**(inverseZoom - 6);
                    let every = 10 ** Math.floor(Math.log10(every1));
                    if (every1 / every > 5) {
                        every *= 5;
                    } else if (every1 / every > 2) {
                        every *= 2;
                    }

                    let length = 0;
                    let tickCount = 0;

                    const mapExtent = olMap.getView().calculateExtent(olMap.getSize());
                    geometry.forEachSegment((a, b) => {
                        const segment = new LineString([a, b]);
                        const segmentLength = getLength(segment);

                        if (segment.intersectsExtent(mapExtent) && (tickCount + 1) * every < length + segmentLength) {
                            const tickPoints1 = [];
                            const tickPoints5 = [];

                            const angle = - Math.atan((b[1] - a[1]) / (b[0] - a[0]));

                            tickCount = Math.floor(length / every);

                            const tickStyles = {};

                            while ((tickCount + 1) * every < length + segmentLength) {
                                const tickPos = tickCount + 1;

                                let tickType;
                                if (tickPos % 10 === 0) {
                                    tickType = 10;
                                } else if (tickPos % 5 === 0) {
                                    tickType = 5;
                                } else {
                                    tickType = 1;
                                }

                                if (this.lineStyleType === 'solid' || tickType === 10) {
                                    if (tickStyles[tickType] === undefined) {
                                        if (tickType === 10) {
                                            tickStyles[tickType] = this.tick10Style.clone();
                                            tickStyles[tickType].getText().setRotation(angle);
                                        } else if (tickType === 5) {
                                            tickStyles[tickType] = this.tick5Style.clone();
                                        } else {
                                            tickStyles[tickType] = this.tick1Style.clone();
                                        }
                                        tickStyles[tickType].getImage().setRotation(angle);
                                    }

                                    const segmentPoint = new Point(segment.getCoordinateAt(
                                        (tickPos * every - length) / segmentLength
                                    ));

                                    if (segmentPoint.intersectsExtent(mapExtent)) {
                                        if (tickType === 10) {
                                            const tickStyle = tickStyles[10].clone();
                                            tickStyle.getText().setText(Route.formatLength(tickPos * every));
                                            tickStyle.setGeometry(segmentPoint);
                                            styles.push(tickStyle);
                                        } else if (tickType === 5) {
                                            tickPoints5.push(segmentPoint);
                                        } else {
                                            tickPoints1.push(segmentPoint);
                                        }
                                    }
                                }

                                tickCount++;
                            }

                            if (tickPoints5.length > 0) {
                                const tickStyle = tickStyles[5];
                                tickStyle.setGeometry(new GeometryCollection(tickPoints5));
                                styles.push(tickStyle);
                            }

                            if (tickPoints1.length > 0) {
                                const tickStyle = tickStyles[1];
                                tickStyle.setGeometry(new GeometryCollection(tickPoints1));
                                styles.push(tickStyle);
                            }
                        }

                        length += segmentLength;
                    });
                }

                if (type === 'LineString') {
                    this.labelStyle.setGeometry(new Point(geometry.getLastCoordinate()));
                    this.labelStyle.getText().setText(Route.formatLength(getLength(geometry)));
                    styles.push(this.labelStyle);
                }
            }

            return styles;
        }

        const self = this;

        this.drawInteraction = new class extends Draw {
            constructor() {
                super({
                    source: routeCollection.drawSource,
                    type: 'LineString',
                    style: function (feature) {
                        return styleFunction(feature);
                    },
                    stopClick: true,
                    condition: (e: MapBrowserEvent<any>) => {
                        // Default: noModifierKeys()
                        return (noModifierKeys(e) || platformModifierKeyOnly(e)) && primaryAction(e);
                    }
                });
            }

            handleUpEvent(event) {
                if (self.modifyDragging) {
                    // Fix for bug when modifying the route fast over a short distance+time
                    return true;
                }

                const pass = super.handleUpEvent(event);

                if (pass) {
                    return true;
                }

                this.finishDrawing();

                const geometry = <Point|LineString>routeCollection.drawSource.getFeatures()[0].getGeometry();

                let newCoordinates = <olCoordinate[]>geometry.getCoordinates();

                if (newCoordinates.length === 1 && !platformModifierKey(event)) {
                    // Determine whether we have clicked a location; if so, add coordinate of the location
                    const locationFeature = olMap.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
                        if (feature && feature.get('location')) {
                            return feature;
                        }
                    }, {
                        layerFilter: (layer) => {
                            return layer == routeCollection.userInterface.getLocationCollection().mapLayer;
                        }
                    });

                    if (locationFeature) {
                        const location: Location = locationFeature.get('location');
                        newCoordinates = [location.getCoordinate()];
                    }
                }

                const coordinates = lineString.getCoordinates();
                const changed = Route.appendCoordinates(coordinates, newCoordinates);
                lineString.setCoordinates(coordinates);

                routeCollection.drawSource.clear();

                self.triggerChanged(changed);

                return false;
            }
        };

        const lineString = new LineString([]);
        this.mainFeature = new Feature({
            geometry: lineString,
            route: this,
        });
        this.mainFeature.setStyle(styleFunction);
        this.modifyInteraction = new Modify({source: routeCollection.modifySource, style: this.modifyStyle});

        this.modifyInteraction.on('modifystart', () => {
            this.modifyDragging = true;
        });

        this.modifyInteraction.on('modifyend', () => {
            this.triggerChanged(true);
            this.modifyDragging = false;
        });

        //draw.on('drawstart', (evt) => {
        //    evt.feature.setStyle(styleFunction);
        //});
    }

    public static appendCoordinates(coordinates: olCoordinate[], newCoordinates: olCoordinate[]): boolean
    {
        let changed = false;

        for (const newCoordinate of newCoordinates) {
            const lastCoordinate = coordinates[coordinates.length - 1];

            if (!lastCoordinate || newCoordinate[0] !== lastCoordinate[0] || newCoordinate[1] !== lastCoordinate[1]) {
                coordinates.push(newCoordinate);
                changed = true;
            }
        }

        return changed;
    }

    public addToMap()
    {
        this.routeCollection.mainSource.addFeature(this.mainFeature);

        this.intermediates?.addToMap();
    }

    public removeFromMap()
    {
        this.mouseout(false);
        this.routeCollection.mainSource.removeFeature(this.mainFeature);

        this.intermediates?.removeFromMap();
    }

    public onChange(callback: () => void)
    {
        this.onChangeCallbacks.push(callback);
    }

    public triggerChanged(addAction)
    {
        for (const callback of this.onChangeCallbacks) {
            callback();
        }

        this.updateReactiveProps();

        if (addAction) {
            this.routeCollection.userInterface.actionHistory.addAction(new RouteCoordinateMutationAction(this, this.oldCoordinates));
        }
        this.oldCoordinates = this.getCoordinates().slice();

        if (this.intermediates) {
            this.intermediates.syncCoordinates();
        }
    }

    public getOldCoordinates(): olCoordinate[]
    {
        return this.oldCoordinates.slice();
    }

    public getCoordinates(): olCoordinate[]
    {
        return this.mainFeature.getGeometry().getCoordinates();
    }

    public locateCoordinate(searchCoordinate: olCoordinate): [number, number]|null
    {
        const tolerance = 1e-10;

        // Find exact vertex/coordinate
        const routeCoordinates = this.getCoordinates();
        const dist = c => (c[0] - searchCoordinate[0])**2 + (c[1] - searchCoordinate[1])**2;
        const minVertexCoordinate = min(routeCoordinates, dist);
        if (minVertexCoordinate && dist(minVertexCoordinate) <= tolerance) {
            const index = routeCoordinates.indexOf(minVertexCoordinate);
            return [index, index];
        }

        // Find along edge
        for (let i = 0; i < routeCoordinates.length - 1; i++) {
            const a = routeCoordinates[i];
            const b = routeCoordinates[i + 1];

            if ((a[0] <= searchCoordinate[0]) !== (searchCoordinate[0] <= b[0])) {
                // x coordinate is not within [x_a, x_b] / [x_b, x_a]
                continue;
            }

            if ((a[1] <= searchCoordinate[1]) !== (searchCoordinate[1] <= b[1])) {
                // y coordinate is not within [y_a, y_b] / [y_b, y_a]
                continue;
            }

            // If dy < dx, compute expected y. Otherwise, compute expected x. This prevents
            // problems when dx or dy tends to 0 in the denominator.
            const base = Math.abs(b[1] - a[1]) < Math.abs(b[0] - a[0]) ? 0 : 1;

            const expectedOther = a[1 - base] + (searchCoordinate[base] - a[base]) * (b[1 - base] - a[1 - base]) / (b[base] - a[base]);

            if (Math.abs(expectedOther - searchCoordinate[1 - base]) < Math.sqrt(tolerance)) {
                return [i, i + 1];
            }
        }

        return null;
    }

    public getGeometry()
    {
        return this.mainFeature.getGeometry();
    }

    public getName()
    {
        return this.name;
    }

    public getColor()
    {
        return this.color;
    }

    public setCoordinates(coordinates: olCoordinate[], addAction: boolean = false)
    {
        this.mainFeature.getGeometry().setCoordinates(coordinates);
        this.triggerChanged(addAction);
    }

    public applyCoordinatesChange(coordinates: olCoordinate[], mutationIndex: number, mutationAmount: number)
    {
        if (this.intermediates) {
            this.intermediates.applyMutationSlide(mutationIndex, mutationAmount);
        }

        this.setCoordinates(coordinates);

        if (this.intermediates && mutationAmount === 0) {
            this.intermediates.updateSideRoadsAfterMoveMutation(mutationIndex, coordinates[mutationIndex]);
        }
    }

    public setColor(color: null|string)
    {
        this.reactiveProps.color = this.color = color;
        this.initStyles();
        this.mainFeature.changed();

        this.intermediates?.routeSideRoadsUtil?.initStyles();
        this.routeCollection.sideRoadsMainSource.changed();
    }

    public setName(name: string)
    {
        this.reactiveProps.name = this.name = name;
    }

    public getLineStyleType(): LineStyleType
    {
        return this.lineStyleType;
    }

    public setLineStyleType(lineStyleType: LineStyleType)
    {
        this.reactiveProps.lineStyleType = this.lineStyleType = lineStyleType;
        this.initStyles();
        this.mainFeature.changed();
    }

    updateReactiveProps()
    {
        this.reactiveProps.formattedLength = this.getFormattedLength();
        this.reactiveProps.formattedArea = this.getFormattedArea();
        this.reactiveProps.formattedBearing = this.getFormattedBearing();
    }

    setIntermediates(intermediates: RouteIntermediates|null)
    {
        if (intermediates !== null && intermediates.route !== this) {
            throw new Error();
        }

        if (intermediates === null) {
            if (this.hasFocus()) {
                this.routeCollection.unfocus();
            }

            if (this.routeCollection.getRouTechSelectedRoute()?.id === this.id) {
                this.routeCollection.setRouTechSelectedRoute(null);
            }
        }

        this.intermediates = intermediates;
        this.reactiveProps.hasInitializedIntermediates = intermediates !== null;
        this.reactiveProps.intermediatesRP = this.intermediates?.reactiveProps;

        if (this.reactiveProps.hasInitializedIntermediates && this.reactiveProps.hasFocus) {
            this.routeCollection.setRouTechSelectedRoute(this.reactiveProps.id);
        } else {
            this.routeCollection.checkCloseRouteIntermediatesPanel();
        }
    }

    getIntermediates(): RouteIntermediates|null
    {
        return this.intermediates;
    }

    public reverse()
    {
        this.routeCollection.userInterface.actionHistory.addAction(new RouteReverseAction(this));
    }

    public doReverse()
    {
        this.setCoordinates(this.getCoordinates().slice().reverse(), false);

        if (this.intermediates) {
            this.intermediates.doReverse();
        }

        this.triggerChanged(false);
    }

    clone(): Route {
        const route = new Route(this.routeCollection);
        route.setCoordinates(this.getCoordinates());

        if (this.intermediates) {
            route.setIntermediates(RouteIntermediates.unserialize(this.intermediates.serialize(), route));
        }

        return route;
    }

    serialize(): Serialization {
        const coordinates = [];
        for (const coordinate of this.getCoordinates()) {
            coordinates.push(toLonLat(coordinate));
        }
        return {
            name: this.name,
            color: this.color,
            lineStyleType: this.lineStyleType,
            coordinates: coordinates,
            visibleOnMap: this.visibleOnMap,
            intermediates: this.intermediates?.serialize(),
        };
    }

    static unserialize(serialized: Serialization, routeCollection: RouteCollection): Route {
        const route = new Route(routeCollection);

        route.reactiveProps.name = route.name = serialized.name;
        route.setColor(serialized.color);
        route.setLineStyleType(serialized.lineStyleType);
        route.reactiveProps.visibleOnMap = route.visibleOnMap = serialized.visibleOnMap;

        const coordinates: olCoordinate[] = [];
        for (const coordinate of serialized.coordinates) {
            coordinates.push(fromLonLat(coordinate));
        }
        route.setCoordinates(coordinates);

        if (serialized.intermediates) {
            route.setIntermediates(RouteIntermediates.unserialize(serialized.intermediates, route));
        }

        return route;
    }

    public toggleVisibility(visible: boolean = null) {
        if (visible === null) {
            visible = !this.visibleOnMap;
        }

        this.reactiveProps.visibleOnMap = this.visibleOnMap = visible;

        if (!this.visibleOnMap && this.hasFocus()) {
            this.routeCollection.unfocus();
        }

        this.mainFeature.changed();
        if (this.intermediates) {
            this.routeCollection.intermediatesSource.changed();
            this.routeCollection.sideRoadsMainSource.changed();
        }
    }

    public isVisible(): boolean {
        return this.visibleOnMap;
    }

    public mouseover(notifyLayer: boolean = true) {
        this.hasMouseover = true;
        $('#route_' + this.id).addClass('hover');
        this.initStyles();
        if (notifyLayer) {
            this.mainFeature.changed();
        }
    }

    public mouseout(notifyLayer: boolean = true) {
        this.hasMouseover = false;
        $('#route_' + this.id).removeClass('hover');
        this.initStyles();
        if (notifyLayer) {
            this.mainFeature.changed();
        }
    }

    public focus()
    {
        if (this.routeCollection.getFocusedRoute() !== this) {
            this.routeCollection.focusRoute(this);
        }
    }

    public hasFocus()
    {
        return this.reactiveProps.hasFocus;
    }

    public setFocus(focus)
    {
        if (!this.reactiveProps.hasFocus && focus) {
            if (this.routeCollection.mainSource.hasFeature(this.mainFeature)) {
                this.routeCollection.mainSource.removeFeature(this.mainFeature);
            }
            this.routeCollection.modifySource.addFeature(this.mainFeature);

            this.setEditMode('draw');
            this.reactiveProps.hasFocus = true;
            this.toggleVisibility(true);
        } else if (this.reactiveProps.hasFocus && !focus) {
            if (this.routeCollection.modifySource.hasFeature(this.mainFeature)) {
                this.routeCollection.modifySource.removeFeature(this.mainFeature);
            }
            this.routeCollection.mainSource.addFeature(this.mainFeature);

            this.setEditMode(null);
            this.reactiveProps.hasFocus = false;
            this.modifyDragging = false; // Failsafe

            if (this.isSketch) {
                const oldCoordinates = this.oldCoordinates.slice();
                this.setCoordinates([], false); // The action is handled by RouteDropSketchAction
                if (!this.routeCollection.userInterface.actionHistory.isPerforming()) {
                    this.routeCollection.userInterface.actionHistory.addAction(new RouteDropSketchAction(this, oldCoordinates));
                }
            }
        }
    }

    getEditMode(): RouteEditMode|null {
        return this.reactiveProps.editMode;
    }

    setEditMode(mode: RouteEditMode|null): void
    {
        const olMap = this.routeCollection.getOpenlayersMap();

        const oldMode = this.reactiveProps.editMode;
        this.reactiveProps.editMode = mode;

        if (mode === 'draw' && oldMode !== 'draw') {
            olMap.addInteraction(this.modifyInteraction);
            olMap.addInteraction(this.drawInteraction);
        } else if (mode !== 'draw' && oldMode === 'draw') {
            olMap.removeInteraction(this.modifyInteraction);
            olMap.removeInteraction(this.drawInteraction);
        }

        if (this.intermediates) {
            this.intermediates.processNewEditMode(oldMode, mode);
        }
    }

    handleKeyDown(evt: KeyboardEvent)
    {
        if (evt.defaultPrevented || !this.reactiveProps.hasFocus) {
            return;
        }

        if (evt.which === 27 || evt.code === 'Escape') {
            if (this.isSketch && this.getCoordinates().length > 0) {
                this.setCoordinates([], true);
            } else if (this.intermediates && this.intermediates.routeSideRoadsUtil.getFocusedSideRoad()) {
                this.intermediates.routeSideRoadsUtil.unfocusSideRoad();
            } else {
                this.routeCollection.unfocus();

                if (this.isSketch) {
                    this.routeCollection.userInterface.actionHistory.addAction(new RouteDropSketchAction(this, this.oldCoordinates));
                }
            }

            evt.preventDefault();
        }
    }

    handleKeyDownForIntermediates(evt: KeyboardEvent)
    {
        if (evt.defaultPrevented || !this.intermediates || this.routeCollection.userInterface.isLocked()) {
            return;
        }

        if (evt.target?.closest('input, select, textarea')) {
            return;
        }

        if (evt.code === 'KeyQ' || evt.code === 'KeyW' || evt.code === 'KeyE') {
            let newEditMode = null;
            if (evt.code === 'KeyQ') {
                newEditMode = 'draw';
            } else if (evt.code === 'KeyW') {
                newEditMode = 'intermediate';
            } else if (evt.code === 'KeyE') {
                newEditMode = 'side-road';
            }

            if (this.hasFocus() && this.getEditMode() === newEditMode) {
                this.routeCollection.unfocus();
            } else {
                this.routeCollection.focusRoute(this);
                this.setEditMode(newEditMode);

                if (this.routeCollection.userInterface.getOpenObjectTypeTab() !== 'route-intermediates') {
                    this.routeCollection.userInterface.showObjectListPanel('route-intermediates');
                }
            }

            evt.preventDefault();
        }
    }

    getFormattedLength()
    {
        return Route.formatLength(getLength(this.mainFeature.getGeometry()));
    }

    getFormattedArea()
    {
        const geometry = <LineString>this.mainFeature.getGeometry();
        const coordinates = geometry.getCoordinates();
        if (coordinates.length > 2) {
            const extent = geometry.getExtent();
            const size = Math.max(olExtent.getWidth(extent), olExtent.getHeight(extent));
            const length = getLength(new LineString([coordinates[0], coordinates[coordinates.length - 1]]));

            if (length * 50 < size) {
                return Route.formatArea(getArea(new Polygon([coordinates])));
            }
        }

        return null;
    }

    getFormattedBearing()
    {
        const geometry = <LineString>this.mainFeature.getGeometry();
        const coordinates = geometry.getCoordinates();
        if (coordinates.length !== 2) {
            return null;
        }

        return Route.formatBearing(Route.computeBearing(coordinates[0], coordinates[1]));
    }

    static computeBearing(from: olCoordinate, to: olCoordinate): number {
        return (Math.round(olComputeHeading(from, to).startBearing) + 360) % 360;
    }

    static formatLength(length: number, maximumFractionDigits = 2)
    {
        if (length >= 1000) {
            return (length / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: maximumFractionDigits}) + ' km';
        } else {
            return length.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: maximumFractionDigits}) + ' m';
        }
    }

    static formatArea(area: number)
    {
        if (area > 10000) {
            return (area / 1000000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' km\xB2';
        } else {
            return area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2}) + ' m\xB2';
        }
    }

    static formatBearing(bearing: number)
    {
        return bearing + ' °';
    }

    public generateKmlNode(doc: XMLDocument): HTMLElement
    {
        // https://developers.google.com/kml/documentation/kml_tut
        // https://developers.google.com/kml/documentation/kmlreference

        const placemark = doc.createElement('Placemark');

        const placemarkName = doc.createElement('name');
        placemarkName.textContent = this.name;
        placemark.appendChild(placemarkName);

        const lineString = doc.createElement('LineString');
        placemark.appendChild(lineString);

        // This 'tessellate' seems to be 'good' to include. Did not investigate why
        const tessellate = doc.createElement('tessellate');
        tessellate.textContent = '1';
        lineString.appendChild(tessellate);

        const coordinates = doc.createElement('coordinates');
        coordinates.textContent = this.getCoordinates().map((coord: olCoordinate) => {
            const lonLat = toLonLat(coord);
            return lonLat[0] + ',' + lonLat[1] + ',0';
        }).join(' ');
        lineString.appendChild(coordinates);

        return placemark;
    }

    public generateGpxNode(doc: XMLDocument): HTMLElement
    {
        // http://www.topografix.com/gpx/1/1/

        const rte = doc.createElement('rte');

        const name = doc.createElement('name');
        name.textContent = this.name;
        rte.appendChild(name);

        for (const coordinate of this.getCoordinates()) {
            const lonLat = toLonLat(coordinate);

            const rtept = doc.createElement('rtept');
            rtept.setAttribute('lat', lonLat[1].toString());
            rtept.setAttribute('lon', lonLat[0].toString());
            rte.appendChild(rtept);
        }

        return rte;
    }

    public drawOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        Route.drawLineOnPdf(
            doc,
            cutout,
            this.getCoordinates(),
            this.color,
            this.lineStyleType,
            true,
        );
    }

    public static drawLineOnPdf(
        doc: jsPDF,
        cutout: Cutout<any, any, any>,
        olCoordinates: olCoordinate[],
        hexColor: string,
        lineStyleType: LineStyleType|null,
        stroke: boolean,
    ) {
        const wgsSystem = CoordinateConverter.getCoordinateSystem('EPSG:4326');
        const paperCoordinateConversion = cutout.getProjection().paperCoordinateConversion();
        const toPaperCoord = new ConversionComposition(
            CoordinateConverter.conversion(wgsSystem, cutout.getProjection().coordinateSystem),
            paperCoordinateConversion
        );

        const paperPolygon = CoordinateConverter.convertPolygonUsingConversion(cutout.mapPolygonProjection, paperCoordinateConversion);
        const turfPaperPolygon = toTurfPolygon(paperPolygon);

        const coordinates: MPoint[] = [];
        const inPolygon: boolean[] = [];
        for (const coordinate of olCoordinates) {
            const paperCoordinate = toPaperCoord.convert(wgsSystem.make(...toLonLat(coordinate)));
            coordinates.push(paperCoordinate);
            inPolygon.push(booleanPointInPolygon([paperCoordinate.getX(), paperCoordinate.getY()], turfPaperPolygon));
        }

        doc.saveGraphicsState();
        try {
            const color = asArray(hexColor || '#000000').slice(0);
            const lineDash = lineStyleType === 'dashed' ? [5, 3] : lineStyleType === 'dotted' ? [.5, .5] : null;
            doc.setLineDashPattern(lineDash, 0);

            const accumulator = [];
            for (let i=0; i<coordinates.length; i++) {
                let include: boolean;
                if (i === coordinates.length-1) {
                    include = false;
                } else {
                    include = inPolygon[i] || inPolygon[i+1] || lineSegmentIntersectsPolygonEdges({from: coordinates[i], to: coordinates[i+1]}, paperPolygon);
                }

                if (!include) {
                    if (accumulator.length > 0) {
                        const diffs = diff(accumulator);

                        if (stroke) {
                            doc.setDrawColor(0, 0, 0);
                            doc.setLineWidth(.6);
                            doc.lines(diffs, accumulator[0].getX(), accumulator[0].getY());
                        }

                        doc.setDrawColor(color[0], color[1], color[2]);
                        doc.setLineWidth(.4);
                        doc.lines(diffs, accumulator[0].getX(), accumulator[0].getY());

                        accumulator.splice(0);
                    }
                    continue;
                }

                if (accumulator.length === 0) {
                    accumulator.push(coordinates[i]);
                }

                accumulator.push(coordinates[i+1]);
            }
        } finally {
            doc.restoreGraphicsState();
        }
    }

}
