import RouteIntermediates from "./RouteIntermediates";
import {asArray} from "ol/color";
import {Circle, Fill, Stroke, Style} from "ol/style";
import {LineString, Point} from "ol/geom";
import {Draw, Modify, Select} from "ol/interaction";
import {MapBrowserEvent} from "ol";
import {
    altKeyOnly,
    noModifierKeys,
    platformModifierKeyOnly,
    primaryAction, shiftKeyOnly
} from "ol/events/condition";
import {Coordinate as olCoordinate} from "ol/coordinate";
import RouteSideRoad from "./RouteSideRoad";
import {ModifyEvent} from "ol/interaction/Modify";
import RouteIntermediate from "./RouteIntermediate";
import {Options as SelectOptions, SelectEvent} from "ol/interaction/Select";
import RouteAddSideRoadAction from "../ActionHistory/RouteAddSideRoadAction";
import RouteDeleteSideRoadAction from "../ActionHistory/RouteDeleteSideRoadAction";
import Route, {RouteEditMode} from "./Route";

export default class RouteSideRoadsUtil {

    private lineStringStyles;
    private modifyStyle;
    private endPointStyle;

    private sideRoadIntermediateSelectInteraction;
    private sideRoadSelectInteraction;

    private sideRoadEndpointModifyInteraction: Modify;

    private sideRoadModifyInteraction;
    private sideRoadDrawInteraction;

    private modifyDragging = false;
    private unfocusOnFirst = false;

    private mouseoverIntermediate: RouteIntermediate|null = null;
    private mouseoverSideRoad: RouteSideRoad|null = null;

    private focusedSideRoad: RouteSideRoad|null = null;

    constructor(readonly intermediates: RouteIntermediates) {
        this.initStyles();
        this.initDrawInteractions();
        this.initSelectInteractions();
    }

    public initStyles() {
        this.lineStringStyles = this.computeLineStringStyles();
        this.modifyStyle = new Style({
            image: new Circle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.4)',
                }),
            }),
        });
        this.endPointStyle = this.computeEndPointStyle();

        for (const intermediate of this.intermediates.getIntermediatesList()) {
            for (const routeSideRoad of intermediate.getSideRoads()) {
                routeSideRoad.initStyles();
            }
        }
    }

    public computeLineStringStyles(routeSideRoad: RouteSideRoad|null = null): Style[]
    {
        const color = asArray(this.intermediates.route.getColor() || '#000000').slice(0);
        color[3] = routeSideRoad?.hasFocus() ? 0.7 : 0.5;

        const styles = [];

        styles.push(new Style({
            stroke: new Stroke({
                color: color,
                width: routeSideRoad?.hasFocus() ? 3 : 2,
            }),
            image: new Circle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }));

        return styles;
    }

    public computeEndPointStyle(routeSideRoad: RouteSideRoad|null = null): Style
    {
        return new Style({
            image: new Circle({
                fill: new Fill({color: routeSideRoad?.hasFocus() ? [0, 204, 51] : [0, 153, 255]}),
                stroke: new Stroke({color: 'white'}),
                radius: routeSideRoad?.hasMouseover() ? 8 : 6,
            }),
        });
    }

    public getStyleFunction()
    {
        return (feature): Style[] => {
            if (!this.intermediates.route.isVisible()) {
                return [new Style({})];
            }

            const routeSideRoad = <RouteSideRoad>feature.get('routeSideRoad');

            const styles = (routeSideRoad?.getLineStringStyles() || this.lineStringStyles).slice();

            if (this.intermediates.route.getEditMode() === 'side-road' && routeSideRoad) {
                styles.push(routeSideRoad.getEndPointStyle(this.endPointStyle));
            }

            return styles;
        };
    }

    private initDrawInteractions() {
        const routeCollection = this.intermediates.route.routeCollection;

        const styleFunction = this.getStyleFunction();

        const self = this;

        this.sideRoadDrawInteraction = new class extends Draw {
            constructor() {
                super({
                    source: routeCollection.sideRoadsDrawSource,
                    type: 'LineString',
                    style: function (feature) {
                        return styleFunction(feature);
                    },
                    stopClick: true,
                    condition: (e: MapBrowserEvent<any>) => {
                        // Default: noModifierKeys()
                        return (noModifierKeys(e) || platformModifierKeyOnly(e)) && primaryAction(e);
                    }
                });
            }

            handleUpEvent(event: MapBrowserEvent<any>) {
                if (self.modifyDragging) {
                    // Fix for bug when modifying the route fast over a short distance+time
                    return true;
                }

                const pass = super.handleUpEvent(event);

                if (pass) {
                    return true;
                }

                this.finishDrawing();

                const feature = routeCollection.sideRoadsDrawSource.getFeatures()[0];
                const geometry = <Point|LineString>feature.getGeometry();

                const lineString = self.focusedSideRoad.getMainFeature().getGeometry();
                const coordinates = lineString.getCoordinates();
                const changed = Route.appendCoordinates(coordinates, <olCoordinate[]>geometry.getCoordinates());
                lineString.setCoordinates(coordinates);

                routeCollection.sideRoadsDrawSource.clear();

                self.focusedSideRoad.triggerChanged(changed, true);

                if (coordinates.length === 2 && self.unfocusOnFirst) {
                    if (platformModifierKeyOnly(event)) {
                        self.intermediates.route.routeCollection.userInterface.actionHistory.addAction(
                            new RouteAddSideRoadAction(new RouteSideRoad(self.focusedSideRoad.intermediate))
                        );

                        self.unfocusOnFirst = true;
                    } else {
                        self.unfocusSideRoad();
                        self.unfocusOnFirst = false;
                    }
                }

                return false;
            }
        };

        this.sideRoadModifyInteraction = new Modify({source: routeCollection.sideRoadsModifySource, style: this.modifyStyle});

        this.sideRoadModifyInteraction.on('modifystart', () => {
            this.modifyDragging = true;
        });

        this.sideRoadModifyInteraction.on('modifyend', (e: ModifyEvent) => {
            const routeSideRoad: RouteSideRoad = e.features.getArray()[0].get('routeSideRoad');
            routeSideRoad.triggerChangedByModify();
            this.modifyDragging = false;
        });
    }

    public getMouseoverIntermediate(): RouteIntermediate {
        return this.mouseoverIntermediate;
    }

    private setMouseoverIntermediate(intermediate: RouteIntermediate): void {
        if (intermediate === this.mouseoverIntermediate) {
            return;
        }

        this.mouseoverIntermediate = intermediate;
        this.intermediates.route.routeCollection.intermediatesLayer.changed();

        document.getElementById('map-canvas').classList.toggle('cursor-pointer-intermediate', intermediate !== null);
    }

    private initSelectInteractions() {
        const olMap = this.intermediates.route.routeCollection.userInterface.getMap().getOpenlayersMap();

        olMap.on('pointermove', (e) => {
            if (this.intermediates.route.getEditMode() !== 'side-road') {
                this.setMouseoverIntermediate(null);
                return;
            }

            const intermediateFeature = olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (feature && feature.get('intermediate')) {
                    return feature;
                }
            }, {
                layerFilter: (layer) => {
                    return layer == this.intermediates.route.routeCollection.intermediatesLayer;
                }
            });

            if (intermediateFeature) {
                const intermediate = <RouteIntermediate>intermediateFeature.get('intermediate');
                this.setMouseoverIntermediate(intermediate);
                return;
            }

            this.setMouseoverIntermediate(null);
        });

        this.sideRoadIntermediateSelectInteraction = new Select(<SelectOptions>{
            layers: [this.intermediates.route.routeCollection.intermediatesLayer],
        });
        this.sideRoadIntermediateSelectInteraction.addEventListener('select', (selectEvent: SelectEvent) => {
            for (const feature of selectEvent.selected) {
                const intermediate = <RouteIntermediate>feature.get('intermediate');
                if (intermediate !== undefined && intermediate.intermediates === this.intermediates) {
                    this.intermediates.route.routeCollection.userInterface.actionHistory.addAction(
                        new RouteAddSideRoadAction(new RouteSideRoad(intermediate))
                    );

                    this.unfocusOnFirst = !shiftKeyOnly(selectEvent.mapBrowserEvent);
                }
            }

            this.sideRoadIntermediateSelectInteraction.getFeatures().clear();
        });

        const setMouseover = (sideRoad: RouteSideRoad) => {
            if (sideRoad === this.mouseoverSideRoad) {
                return;
            }

            this.mouseoverSideRoad?.setMouseOver(false);
            sideRoad?.setMouseOver(true);

            this.mouseoverSideRoad = sideRoad;

            this.intermediates.route.routeCollection.sideRoadsMainLayer.changed();

            document.getElementById('map-canvas').classList.toggle('cursor-pointer-sideroads', !!sideRoad);
        };

        olMap.on('pointermove', (e) => {
            if (this.intermediates.route.getEditMode() !== 'side-road') {
                setMouseover(null);
                return;
            }

            const sideRoadFeature = olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (feature && feature.get('routeSideRoad')) {
                    return feature;
                }
            }, {
                layerFilter: (layer) => {
                    return layer == this.intermediates.route.routeCollection.sideRoadsMainLayer;
                }
            });

            if (sideRoadFeature) {
                const routeSideRoad = <RouteSideRoad>sideRoadFeature.get('routeSideRoad');
                setMouseover(routeSideRoad);
                return;
            }

            setMouseover(null);
        });

        this.sideRoadSelectInteraction = new Select(<SelectOptions>{
            layers: [this.intermediates.route.routeCollection.sideRoadsMainLayer],
        });
        this.sideRoadSelectInteraction.addEventListener('select', (selectEvent: SelectEvent) => {
            for (const feature of selectEvent.selected) {
                const routeSideRoad = <RouteSideRoad>feature.get('routeSideRoad');
                if (routeSideRoad !== undefined && routeSideRoad.intermediate.intermediates === this.intermediates) {
                    if (altKeyOnly(selectEvent.mapBrowserEvent)) {
                        this.intermediates.route.routeCollection.userInterface.actionHistory.addAction(
                            new RouteDeleteSideRoadAction(routeSideRoad)
                        );
                        break;
                    }

                    routeSideRoad.intermediate.intermediates.routeSideRoadsUtil.focusSideRoad(routeSideRoad);
                }
            }

            this.sideRoadSelectInteraction.getFeatures().clear();
        });

        // Allow moving a side road endpoint when not focussed
        this.sideRoadEndpointModifyInteraction = new Modify({
            source: this.intermediates.route.routeCollection.sideRoadsMainSource,
            style: new Style(),
            condition: (e: MapBrowserEvent<any>): boolean => {
                if (!primaryAction(e)) {
                    return false;
                }

                // Here, we make sure we can only grab vertices close to the endpoint (so, 99% of times, only the endpoint).
                // On modifyend, in triggerChangedEndpoint(), we make sure indeed nothing but the endpoint can be moved
                const olMap = this.intermediates.route.routeCollection.userInterface.getMap().getOpenlayersMap();
                const features = olMap.getFeaturesAtPixel(e.pixel, {
                    layerFilter: (l) => l == this.intermediates.route.routeCollection.sideRoadsMainLayer,
                });

                const pixelTolerance = 10;

                for (const feature of features) {
                    const routeSideRoad: RouteSideRoad = feature.get('routeSideRoad');
                    if (!routeSideRoad) {
                        continue;
                    }

                    const coordinates = routeSideRoad.getCoordinates();
                    const lastPixel = olMap.getPixelFromCoordinate(coordinates[coordinates.length - 1]);

                    if ((lastPixel[0] - e.pixel[0]) ** 2 + (lastPixel[1] - e.pixel[1]) ** 2 > pixelTolerance ** 2) {
                       return false;
                    }
                }

                return true;
            },
            deleteCondition: () => false,
            insertVertexCondition: () => false,
        });

        this.sideRoadEndpointModifyInteraction.on('modifyend', (e: ModifyEvent) => {
            for (const feature of e.features.getArray()) {
                const routeSideRoad: RouteSideRoad = feature.get('routeSideRoad');
                routeSideRoad.triggerChangedEndpoint();
            }
        });
    }

    processNewEditMode(oldMode: RouteEditMode|null, newMode: RouteEditMode|null): void
    {
        const olMap = this.intermediates.route.routeCollection.getOpenlayersMap();

        if (newMode === 'side-road' && oldMode !== 'side-road') {
            olMap.addInteraction(this.sideRoadEndpointModifyInteraction);
            olMap.addInteraction(this.sideRoadIntermediateSelectInteraction);
            olMap.addInteraction(this.sideRoadSelectInteraction);
        } else if (newMode !== 'side-road' && oldMode === 'side-road') {
            olMap.removeInteraction(this.sideRoadSelectInteraction);
            olMap.removeInteraction(this.sideRoadIntermediateSelectInteraction);
            olMap.removeInteraction(this.sideRoadEndpointModifyInteraction);
        }

        this.intermediates.route.routeCollection.intermediatesLayer.changed();
        this.intermediates.route.routeCollection.sideRoadsMainLayer.changed();

        if (newMode !== 'side-road') {
            this.mouseoverIntermediate = null;
            this.unfocusSideRoad();
        }
    }

    public getFocusedSideRoad(): RouteSideRoad|null
    {
        return this.focusedSideRoad;
    }

    public focusSideRoad(routeSideRoad: RouteSideRoad): void
    {
        this.intermediates.route.focus();

        this.unfocusSideRoad();
        this.unfocusOnFirst = false;

        this.intermediates.route.setEditMode('side-road');

        const routeCollection = this.intermediates.route.routeCollection;

        if (routeCollection.sideRoadsMainSource.hasFeature(routeSideRoad.getMainFeature())) {
            routeCollection.sideRoadsMainSource.removeFeature(routeSideRoad.getMainFeature());
        }
        routeCollection.sideRoadsModifySource.addFeature(routeSideRoad.getMainFeature());

        this.focusedSideRoad = routeSideRoad;
        routeSideRoad.setFocus(true);
        routeSideRoad.initStyles();

        const olMap = routeCollection.getOpenlayersMap();
        olMap.addInteraction(this.sideRoadModifyInteraction);
        olMap.addInteraction(this.sideRoadDrawInteraction);
        olMap.removeInteraction(this.sideRoadEndpointModifyInteraction);

        this.intermediates.route.routeCollection.intermediatesLayer.changed();
    }

    public unfocusSideRoad(checkRemove: boolean = true): void
    {
        if (!this.focusedSideRoad) {
            return;
        }

        const routeCollection = this.intermediates.route.routeCollection;

        if (routeCollection.sideRoadsModifySource.hasFeature(this.focusedSideRoad.getMainFeature())) {
            routeCollection.sideRoadsModifySource.removeFeature(this.focusedSideRoad.getMainFeature());
        }
        routeCollection.sideRoadsMainSource.addFeature(this.focusedSideRoad.getMainFeature());

        const focusedSideRoad = this.focusedSideRoad;
        this.focusedSideRoad.setFocus(false);
        this.focusedSideRoad.initStyles();
        this.focusedSideRoad = null;

        const olMap = routeCollection.getOpenlayersMap();
        olMap.removeInteraction(this.sideRoadModifyInteraction);
        olMap.removeInteraction(this.sideRoadDrawInteraction);
        olMap.addInteraction(this.sideRoadEndpointModifyInteraction);

        this.modifyDragging = false; // Failsafe

        if (checkRemove && focusedSideRoad.getCoordinates().length <= 1) {
            this.intermediates.route.routeCollection.userInterface.actionHistory.addAction(
                new RouteDeleteSideRoadAction(focusedSideRoad)
            );
        }

        this.intermediates.route.routeCollection.intermediatesLayer.changed();
    }
}
