<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      v-bind:id="'location_settings_modal_' + locationRP.id"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Punt instellingen {{ locationRP.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" v-bind:id="'lsm_' + locationRP.id + '_tabbar-tablist'">
            <li class="nav-item">
              <a
                  class="nav-link active"
                  v-bind:id="'lsm_' + locationRP.id + '_tabbar_general-tab'"
                  role="tab"
                  data-bs-toggle="tab"
                  v-bind:aria-controls="'lsm_' + locationRP.id + '_tabbar_general'"
                  v-bind:data-bs-target="'#lsm_' + locationRP.id + '_tabbar_general'"
                  aria-selected="true"
              >Algemeen</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
                class="tab-pane show active"
                v-bind:id="'lsm_' + locationRP.id + '_tabbar_general'"
                role="tabpanel"
                v-bind:aria-labelledby="'lsm_' + locationRP.id + 'tabbar_general-tab'"
            >
              <div class="form-group">
                <label v-bind:for="'lsm_' + locationRP.id + '_name'" class="form-label">Punt naam</label>
                <input type="text" class="form-control" v-bind:id="'lsm_' + locationRP.id + '_name'" placeholder="Typ een naam..." v-bind:value="locationRP.name">
              </div>

              <div class="form-group">
                <label class="form-label">Marker</label>
                <div class="d-flex w-auto btn-group" data-bs-toggle="buttons">
                  <label
                      v-for="markerType of markerTypes"
                      v-bind:class="'btn btn-light d-inline-flex align-items-center' + (locationRP.markerType == markerType ? ' active' : '')"
                      v-on:click="changeMarkerType(markerType)"
                  >
                    <input
                        type="radio"
                        class="btn-check"
                        v-bind:name="'lsm_' + locationRP.id + '_marker'"
                        v-bind:id="'lsm_' + locationRP.id + '_marker_' + markerType"
                        v-bind:checked="locationRP.markerType == markerType"
                    >
                    <img v-bind:src="getMarkerImage(markerType)" v-bind:alt="markerType" class="mx-auto" />
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label v-bind:id="'lsm_' + locationRP.id + '_color_label'" class="form-label">Kleur</label>

                <div class="input-group">
                  <div class="form-control d-flex flex-column justify-content-center cursor-pointer" v-bind:id="'lsm_' + locationRP.id + '_color_value'">
                    <div class="color-value" v-bind:style="'background-color: ' + locationRP.color"></div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      v-bind:id="'lsm_' + locationRP.id + '_color_toggle'"
                  >
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <button
                        v-for="color of colors"
                        class="dropdown-item color-option"
                        type="button"
                        v-bind:style="'background-color: ' + color"
                        v-on:click="changeColor(color)"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" v-bind:id="'lsm_' + locationRP.id + '_draw_marker'" type="checkbox" value="1" v-bind:checked="locationRP.drawMarker">
                  <label class="form-check-label" v-bind:for="'lsm_' + locationRP.id + '_draw_marker'">Marker tekenen</label>
                </div>
              </div>

              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" v-bind:id="'lsm_' + locationRP.id + '_draw_name'" type="checkbox" value="1" v-bind:checked="locationRP.drawName">
                  <label class="form-check-label" v-bind:for="'lsm_' + locationRP.id + '_draw_name'">Naam tekenen</label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Container from "../Main/Container";
import $ from "jquery";
import Location from "../Main/Location";
import LocationChangeNameAction from "../ActionHistory/LocationChangeNameAction";
import LocationChangeColorAction from "../ActionHistory/LocationChangeColorAction";
import LocationChangeMarkerTypeAction from "../ActionHistory/LocationChangeMarkerTypeAction";
import {getMarkerImage, MarkerType} from "../Main/Map";
import {bsModal, enumValues} from "../Util/functions";
import UpdateLocationOptionAction from "../ActionHistory/UpdateLocationOptionAction";
import {Dropdown} from "bootstrap";

export default {
  props: {
    listenId: String,
    location: Location,
  },
  data () {
    $(() => {
      const location: Location = this.location;

      $('#' + this.listenId).on('click', () => {
        bsModal('#location_settings_modal_' + location.id).show();
      });

      $('#lsm_' + location.id + '_name').on('change keyup input blur', function() {
        const newName = $(this).val();
        if(newName !== location.getName()) {
          location.locationCollection.userInterface.actionHistory.addAction(new LocationChangeNameAction(location, newName));
        }
      });

      const $toggle = $('#lsm_' + location.id + '_color_toggle');
      let colorOpen = false;
      $toggle.parent()[0].addEventListener('show.bs.dropdown', () => colorOpen = true);
      $toggle.parent()[0].addEventListener('hidden.bs.dropdown', () => colorOpen = false);
      $('#lsm_' + location.id + '_color_label, #lsm_' + location.id + '_color_value').on('click', () => {
        if (!colorOpen) {
          setTimeout(() => Dropdown.getOrCreateInstance($toggle[0]).show());
        }
      });

      for(const [optionKey, optionGetter] of [['draw_marker', 'getDrawMarker'], ['draw_name', 'getDrawName']]) {
        $('#lsm_' + location.id + '_' + optionKey).on('change', function() {
          const newVal = $(this).prop('checked');
          if(newVal !== location[optionGetter]()) {
            location.locationCollection.userInterface.actionHistory.addAction(new UpdateLocationOptionAction(
                location,
                optionKey,
                newVal
            ));
          }
        });
      }
    });

    return {
      container: Container,
      locationRP: this.location.reactiveProps,
      markerTypes: enumValues(MarkerType),
      getMarkerImage: getMarkerImage,
      colors: this.location.locationCollection.userInterface.colors,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    changeColor(newColor) {
      if(newColor !== this.location.color) {
        this.location.locationCollection.userInterface.actionHistory.addAction(new LocationChangeColorAction(this.location, newColor));
      }
    },
    changeMarkerType(newMarkerType) {
      if(newMarkerType !== this.location.getMarkerType()) {
        this.location.locationCollection.userInterface.actionHistory.addAction(
            new LocationChangeMarkerTypeAction(this.location, newMarkerType)
        );
      }
    },
  }
};
</script>

<style scoped>
.color-option {
  height: 1rem;
}

.color-value {
  height: .25rem;
}
</style>
