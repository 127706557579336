<template>
  <div class="control-pane control-pane-coordinate-panel d-none" id="coordinatePanel">
    <button type="button" class="btn-close" aria-label="Close" v-on:click="closeCoordinatePanel" title="Sluiten"></button>

    <button type="button" class="btn-close open-sketch-route" aria-label="Close" v-on:click="openSketchRoute" title="Afstand meten">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class=" bi bi-rulers" viewBox="0 0 16 16">
        <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"/>
      </svg>
    </button>

    <button type="button" class="btn-close make-location-from-coordinate" aria-label="Close" v-on:click="makeLocationFromCoordinate" title="Maak punt" v-if="!userInterface.isLocked()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    </button>

    <div class="dropup dropup-center">
      <button type="button" class="btn-close open-coordinate-links" data-bs-toggle="dropdown" aria-expanded="false" title="Links">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="2 2 14 14">
          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
        </svg>
      </button>
      <ul class="dropdown-menu">
        <li>
          <div class="dropdown-item coordinate-link-item" v-for="coordinateLink in coordinateLinks">
            <a :href="coordinateLink.url" target="_blank">{{ coordinateLink.label }}</a>

            <button
                type="button"
                class="btn btn-sm btn-outline-secondary"
                v-on:click="copyCoordinateLink(coordinateLink)"
                title="Kopiëren"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="align-baseline bi bi-clipboard-plus" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
            </button>
          </div>
        </li>
      </ul>
    </div>

    <div class="control-pane-content" id="coordinateControlPanelContent">
      <div v-for="coordinateInSystem in coordinateInSystems" :key="coordinateInSystem.id">
        <div class="form-group">

          <!-- d-inline-block voorkomt overlap van bovenste buttons -->
          <div class="form-check mb-2 d-inline-block" v-if="openForLocation">
            <input
                class="form-check-input"
                v-bind:id="'coord_panel_pref_cs_' + coordinateInSystem.id"
                type="radio"
                name="coord_panel_pref_cs"
                value="1"
                v-bind:checked="coordinateInSystem.isPreferred"
                title="Tonen in punten overzicht"
                v-on:change="setPreferredCoordinateSystem(coordinateInSystem.coordinateSystem)"
            />
            <label class="form-check-label" v-bind:for="'coord_panel_pref_cs_' + coordinateInSystem.id">{{ coordinateInSystem.name }}</label>
          </div>
          <label v-else v-bind:for="'coord_panel_input_' + coordinateInSystem.id" class="form-label">{{ coordinateInSystem.name }}</label>

          <div class="input-group dropup">
            <input
                type="text"
                class="form-control"
                v-bind:id="'coord_panel_input_' + coordinateInSystem.id"
                readonly
                v-bind:value="coordinateInSystem.showFormatted"
                aria-label="Coordinate"
            >

            <button
                type="button"
                class="btn btn-outline-secondary"
                v-on:click="copyInput('#coord_panel_input_' + coordinateInSystem.id)"
                title="Kopiëren"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="align-baseline bi bi-clipboard-plus" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
              </svg>
            </button>
            <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                v-on:click="updateFormatDropdownWidth"
                title="Formaten"
            ></button>
            <div class="dropdown-menu dropdown-menu-end">
              <div class="mx-2">
                <label class="form-label">{{ coordinateInSystem.name }}</label>

                <div v-for="(coordinate, formatName) in coordinateInSystem.formatted" :key="formatName">
                  <div class="input-group mb-1">
                    <input
                        type="text"
                        class="form-control"
                        v-bind:id="'coord_panel_input_' + coordinateInSystem.id + '_' + formatName"
                        readonly
                        v-bind:value="coordinate"
                        aria-label="Coordinate"
                    >

                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        v-on:click="copyInput('#coord_panel_input_' + coordinateInSystem.id + '_' + formatName)"
                        title="Kopiëren"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="align-baseline bi bi-clipboard-plus" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                      </svg>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        v-bind:disabled="formatName === coordinateInSystem.showFormatName"
                        v-on:click="setPreferredFormatName(coordinateInSystem.coordinate, formatName)"
                        title="Gebruik formaat"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="align-baseline bi bi-box-arrow-in-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"/>
                        <path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import UserInterface from "../Main/UserInterface";
import OLConvertibleCoordinate from "../Coordinates/OLConvertibleCoordinate";
import OLConvertibleCoordinateSystem from "../Coordinates/OLConvertibleCoordinateSystem";
import CoordinateConverter from "../Util/CoordinateConverter";
import Coordinate from "../Coordinates/Coordinate";
import {copyInput} from "../Util/functions";
import {Collection, Feature} from "ol";
import {Point} from "ol/geom";
import {Icon, Style} from "ol/style";
import mapMarker from '../img/marker.svg';
import CoordinateSystem from "../Coordinates/CoordinateSystem";
import Location from "../Main/Location";
import Container from "../Main/Container";
import {MarkerType} from "../Main/Map";
import {Translate} from "ol/interaction";
import WGS84 from "../Coordinates/WGS84";

export default {
  props: {
    userInterface: UserInterface,
    olConvertibleCoordinateSystem: <OLConvertibleCoordinateSystem<OLConvertibleCoordinate>><unknown>Object,
  },
  data () {
    this.openForLocation = null;
    this.coordinate = null;
    this.coordinateName = null;
    this.currentFeature = null;
    this.markerTranslateInteraction = null;

    const map = this.userInterface.getMap();
    const openlayersMap = map.getOpenlayersMap();

    this.markerFeature = new Feature({
      geometry: new Point(openlayersMap.getView().getCenter()), // Initialize with dummy point
    });

    this.initializeMarkerInteraction();

    openlayersMap.on('singleclick', (e) => {
      if (this.userInterface.getRouteCollection().hasFocusedRoute()) {
        // Very rarely on mobile (android/iphone) a event falls through to here while a route is active.
        // Make sure we do not mark a coordinate in these cases
        return;
      }

      if(this.currentFeature === null) {
        this.openCoordinatePanel(this.olConvertibleCoordinateSystem.fromOpenLayersCoordinate(
            openlayersMap.getCoordinateFromPixel(e.pixel)
        ))
      } else {
        this.closeCoordinatePanel();
      }
    });
    return {
      coordinatePanelRecomputeCounter: 0,
    };
  },
  watch: {

  },
  computed: {
    coordinateInSystems: function() {
      this.coordinatePanelRecomputeCounter;

      if(this.currentFeature === null || this.coordinate === null) {
        return null;
      }

      const preferredCoordinateSystem = Container.getPreferredCoordinateSystem();
      const preferredFormats = Container.getPreferredCoordinateFormats();

      const coordinateSystemsRecord: Record<string, boolean> = {};
      coordinateSystemsRecord['EPSG:4326'] = true; // Always show WGS84
      coordinateSystemsRecord[preferredCoordinateSystem.code] = true;
      for(const cutout of this.userInterface.getCutouts()) {
        coordinateSystemsRecord[cutout.workspaceCoordinateSystem.code] = true;
        coordinateSystemsRecord[cutout.getProjection().coordinateSystem.code] = true;
        coordinateSystemsRecord[cutout.getGrid().coordinateSystem.code] = true;
      }
      
      const coordinateSystemCodes = Object.keys(coordinateSystemsRecord);
      coordinateSystemCodes.sort();

      const coordinateInSystems = [];
      for(const code of coordinateSystemCodes) {
        const coordinateSystem = CoordinateConverter.getCoordinateSystem(code);
        const converted = CoordinateConverter.convert(this.coordinate, coordinateSystem);
        const formats = converted.formats();
        const formatNames = Object.keys(formats);

        if(formatNames.length > 0) {
          let showFormatName;
          if(preferredFormats.hasOwnProperty(code) && formats.hasOwnProperty(preferredFormats[code])) {
            showFormatName = preferredFormats[code];
          } else {
            showFormatName = converted.defaultFormat();
          }

          const formatted: Record<string, string> = {};
          for(const formatName of formatNames) {
            formatted[formatName] = formats[formatName]();
          }

          coordinateInSystems.push({
            id: coordinateSystem.code.replace(/[^a-z0-9]/g, '_'),
            coordinate: converted,
            coordinateSystem: coordinateSystem,
            isPreferred: coordinateSystem.code === preferredCoordinateSystem.code,
            name: coordinateSystem.name,
            formatted: formatted,
            showFormatName: showFormatName,
            showFormatted: formatted[showFormatName],
          });
        }
      }

      return coordinateInSystems;
    },
    wgs84Coordinate: function (): WGS84 {
      this.coordinatePanelRecomputeCounter;

      if(this.currentFeature === null || this.coordinate === null) {
        return null;
      }

      const coordinateSystem = CoordinateConverter.getCoordinateSystem('EPSG:4326');
      return <WGS84>CoordinateConverter.convert(this.coordinate, coordinateSystem);
    },
    coordinateLinks: function() {
      const formatUnderscoreDms = (degrees: number, n: 'W'|'S', p: 'E'|'N') => {
        const postfix = degrees < 0 ? n : p;
        degrees = Math.abs(degrees);

        const deg = Math.floor(degrees);
        const minutes = (degrees - deg) * 60;
        const min = Math.floor(minutes);
        const sec = (minutes - min) * 60;

        return deg + '_' + min + '_' + Math.round(sec) + '_' + postfix;
      };

      const longitude = this.wgs84Coordinate?.getX();
      const latitude = this.wgs84Coordinate?.getY();
      const longitudeWE = longitude < 0 ? ((-longitude) + 'W') : (longitude + 'E');
      const latitudeNS = latitude < 0 ? ((-latitude) + 'S') : (latitude + 'N');

      return [
        {
          label: 'Plattekaart',
          url: 'https://plattekaart.nl?c=' + latitude + ',' + longitude,
        },
        {
          label: 'Google Maps',
          url: 'https://www.google.nl/maps/place/' + latitudeNS + '+' + longitudeWE,
        },
        {
          label: 'Google Street View',
          url: 'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + latitude + ',' + longitude,
        },
        {
          label: 'Geohack',
          url: 'https://geohack.toolforge.org/geohack.php?language=nl&params=' + formatUnderscoreDms(latitude, 'S', 'N') + '_' + formatUnderscoreDms(longitude, 'W', 'E'),
        },
      ];
    },
  },
  methods: {
    copyInput,
    initializeMarkerInteraction() {
      const openlayersMap = this.userInterface.getMap().getOpenlayersMap();

      this.markerTranslateInteraction = new Translate({
        features: new Collection([this.markerFeature]),
      });

      this.markerTranslateInteraction.on('translatestart', (e) => {
        document.getElementById('map-canvas').classList.add('coordinate-marker-dragging');
      });

      this.markerTranslateInteraction.on('translateend', (e) => {
        document.getElementById('map-canvas').classList.remove('coordinate-marker-dragging');
        this.updateLocationCoordinate();
      });

      openlayersMap.addInteraction(this.markerTranslateInteraction);

      openlayersMap.on('pointermove', (e) => {
        if (e.dragging || this.currentFeature !== this.markerFeature) {
          return;
        }

        if (this.userInterface.getRouteCollection().getRouTechSelectedRoute()?.getEditMode() !== 'intermediate') {
          for (const feature of openlayersMap.getFeaturesAtPixel(e.pixel)) {
            if (feature === this.markerFeature) {
              document.getElementById('map-canvas').classList.add('coordinate-marker-hover');
              return;
            }
          }
        }

        document.getElementById('map-canvas').classList.remove('coordinate-marker-hover');
      });
    },
    updateFormatDropdownWidth(event) {
      const $btn = $(event.target);
      $btn.siblings('.dropdown-menu').width($btn.closest('#coordinateControlPanelContent').width());
    },
    setPreferredCoordinateSystem(coordinateSystem: CoordinateSystem<Coordinate>) {
      Container.setPreferredCoordinateSystem(coordinateSystem);

      this.coordinatePanelRecomputeCounter++;

      this.userInterface.recomputeObjectList();
    },
    setPreferredFormatName(coordinate: Coordinate, formatName: string) {
      Container.setPreferredCoordinateFormatName(coordinate, formatName);

      this.coordinatePanelRecomputeCounter++;

      this.userInterface.recomputeObjectList();
    },
    updateLocationCoordinate() {
      if (this.openForLocation) {
        this.coordinate = this.olConvertibleCoordinateSystem.fromOpenLayersCoordinate(this.openForLocation.getCoordinate());
      } else {
        this.coordinate = this.olConvertibleCoordinateSystem.fromOpenLayersCoordinate(this.markerFeature.getGeometry().getCoordinates());
      }
      this.coordinatePanelRecomputeCounter++;
    },
    toggleCoordinatePanelForLocation(location: Location) {
      const closeOnly = (this.openForLocation && this.openForLocation.id === location.id);

      this.closeCoordinatePanel();

      if (closeOnly) {
        return;
      }

      this.coordinate = this.olConvertibleCoordinateSystem.fromOpenLayersCoordinate(location.getCoordinate());
      this.coordinateName = null;

      location.on('change', this.updateLocationCoordinate);

      this.currentFeature = location.getFeature();
      this.openForLocation = location;
      this.coordinatePanelRecomputeCounter++;

      $('#coordinatePanel').toggleClass('d-none', false);
    },
    openCoordinatePanel(coordinate: Coordinate, coordinateName: string|null = null) {
      this.closeCoordinatePanel();

      const map = this.userInterface.getMap();

      this.coordinate = CoordinateConverter.convert(coordinate, this.olConvertibleCoordinateSystem);
      this.coordinateName = coordinateName;

      this.markerFeature.getGeometry().setCoordinates(this.coordinate.toOpenLayersCoordinate());
      this.currentFeature = this.markerFeature;

      this.openForLocation = null;
      this.coordinatePanelRecomputeCounter++;

      const downloaded = map.markerDownloaded();
      if (!downloaded) {
        this.markerFeature.setStyle(new Style({
          image: new Icon({
            anchor: [12, 41],
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            scale : 0.75,
            opacity: 1,
            src: mapMarker
          }),
        }));
      }

      map.getCoordinatePanelMarker().then((icon) => {
        this.markerFeature.setStyle(new Style({
          image: icon,
        }));

        this.markerFeature.changed();
      });

      map.getOpenlayersVectorSource().addFeature(this.currentFeature);
      map.getOpenlayersVectorSource().changed();
      $('#coordinatePanel').toggleClass('d-none', false);
    },
    closeCoordinatePanel() {
      // The 'close' button is primarily provided for iOS devices, as these don't correctly close the
      // coordinate panel on a second click
      if (this.currentFeature === null) {
        return;
      }

      if (this.openForLocation === null) {
        this.userInterface.getMap().getOpenlayersVectorSource().removeFeature(this.currentFeature);
        document.getElementById('map-canvas').classList.remove('coordinate-marker-hover');
      } else {
        this.openForLocation.off('change', this.updateLocationCoordinate);
      }
      this.currentFeature = null;
      this.coordinate = null;
      this.coordinateName = null;
      this.openForLocation = null;
      this.coordinatePanelRecomputeCounter++;

      $('#coordinatePanel').toggleClass('d-none', true);
    },
    openSketchRoute() {
      if (this.currentFeature === null) {
        return;
      }

      const coordinate = this.currentFeature.getGeometry().getCoordinates();
      this.closeCoordinatePanel();
      this.userInterface.getRouteCollection().startSketchRoute(coordinate);
    },
    makeLocationFromCoordinate() {
      if (this.currentFeature === null) {
        return;
      }

      const coordinate = this.currentFeature.getGeometry().getCoordinates();
      const name = this.coordinateName;
      this.closeCoordinatePanel();
      this.userInterface.getLocationCollection().addLocation(coordinate, name);
    },
    copyCoordinateLink(coordinateLink) {
      navigator.clipboard.writeText(coordinateLink.url);
    }
  }
};

</script>

<style scoped>

.control-pane-coordinate-panel .btn-close {
  float: right;
  margin-top: -12px;
  margin-right: -6px;
}

.control-pane-coordinate-panel .btn-close svg {
  vertical-align: baseline;
}

.control-pane-coordinate-panel .btn-close.open-sketch-route, .control-pane-coordinate-panel .btn-close.open-coordinate-links {
  background: white;
  margin-right: 3px;
}

.control-pane-coordinate-panel .btn-close.make-location-from-coordinate {
  background: white;
  margin-top: -13px;
  margin-right: 4px;
}

.coordinate-link-item {
  padding: 0;
  display: flex;
  font-size: 80%;
}

.coordinate-link-item a, .coordinate-link-item a:hover {
  text-decoration: none;
  color: var(--bs-body-color);
  flex: 1;
  padding-left: 0.5rem;
  align-content: center;
}

.coordinate-link-item a:hover {
  background-color: rgba(0, 20, 100, 0.1);
}

.coordinate-link-item button {
  border-width: 0;
}
</style>
