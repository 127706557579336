import Coordinate from "../Coordinates/Coordinate";
import Cutout from "../Main/Cutout";
import Cache from "../Util/Cache";
import {jsPDF} from "jspdf";
import Paper from "../Util/Paper";
import Container from "../Main/Container";
import Projection, {ProjectionReactiveProps} from "./Projection";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {throttledParallelEachPromise} from "../Util/functions";
import OlMip from "./OlMip";
import CoordinateConverter from "../Util/CoordinateConverter";
import OlTileLoader from "../Util/OlTileLoader";
import OlVectorMip from "./OlVectorMip";

type _OlVectorProjectionReactiveProps = {
    type: 'OlVectorProjection',
    zoom: number|null,
    dpi: number|null,
}
export type OlVectorProjectionReactiveProps = ProjectionReactiveProps & _OlVectorProjectionReactiveProps;

export default class OlVectorProjection<C extends Coordinate> extends Projection<C, OlVectorMip> {

    reactiveProps: OlVectorProjectionReactiveProps;

    private zoom: number|null = null;
    private dpi: number|null = null;

    constructor(olMipName: string, scale: number = null) {
        super(Container.olVectorMip(olMipName), scale, <_OlVectorProjectionReactiveProps>{
            type: 'OlVectorProjection',
            zoom: null,
            dpi: null,
        });
    }

    initialize(): Promise<void> {
        return Promise.resolve().then(() => {
            if(this.scale === null && this.zoom === null) {
                this.reactiveProps.scale = this.scale = 25000;
            }

            if(this.dpi === null) {
                this.reactiveProps.dpi = this.dpi = 200; // 200 provides better font sizes than 300
            }

            if(this.zoom === null) {
                this.reactiveProps.zoom = this.zoom = this.mapImageProvider.getTileZoomClosestToScale(this.coordinateSystem, this.scale);
            }

            if(this.scale === null) {
                this.reactiveProps.scale = this.scale = this.mapImageProvider.getScaleClosestToTileZoom(this.coordinateSystem, this.zoom);
            }
        });
    }

    clone(): OlVectorProjection<C> {
        const projection = new OlVectorProjection<C>(
            this.mapImageProvider.name,
            this.getScale(),
        );
        projection.coordinateSystem = this.coordinateSystem;
        return projection;
    }

    serialize(): Serialization {
        return {
            type: 'openlayers_vector',
            mip: this.mapImageProvider.name,
            csCode: this.coordinateSystem.code,
            scale: this.scale,
            zoom: this.zoom,
            dpi: this.dpi,
        };
    }

    static unserialize(serialized: Serialization): OlVectorProjection<Coordinate> {
        const projection = new OlVectorProjection(serialized.mip, serialized.scale);
        projection.coordinateSystem = CoordinateConverter.getCoordinateSystem(serialized.csCode);
        if(serialized.zoom) {
            projection.setZoom(serialized.zoom);
        }
        if(serialized.dpi) {
            projection.setDpi(serialized.dpi);
        }
        return projection;
    }

    attach(cutout: Cutout<any, C, any>) {
        super.attach(cutout);

        // Preload capabilities upon attaching
        this.initialize();
    }

    getMipDrawnGrid(): GridSpec | null {
        return null;
    }

    getZoom(): number {
        return this.zoom;
    }

    setZoom(newZoom: number) {
        this.reactiveProps.zoom = this.zoom = newZoom;
    }

    getDpi(): number {
        return this.dpi;
    }

    setDpi(newDpi: number) {
        this.reactiveProps.dpi = this.dpi = newDpi;
    }

    getRenderZoom(): number {
        return this.mapImageProvider.computeRenderZoom(this.coordinateSystem, this.getScale(), this.getDpi());
    }

    projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void)|null): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // real mm: mm in physical world
            // paper mm: mm on paper map
            // render: one OpenLayers map render
            // px: pixels in OpenLayers map render
            // unit: unit of measurement of projection coordinate system (e.g., meters)
            const scale = this.getScale();
            const realMmPerPaperMm = scale;

            const pxDimensionPerTile = 256;

            const realMmPerUnit = 1000;
            const pxPerRender = OlMip.mapPixelWidth;
            const paperMmPerUnit = realMmPerUnit / realMmPerPaperMm;

            const pxPerUnit = this.mapImageProvider.getBasePixelsPerUnit(this.coordinateSystem) * (2 ** this.getRenderZoom());

            const unitsPerRender = pxPerRender / pxPerUnit;
            const paperMmPerRender = unitsPerRender * paperMmPerUnit;

            const toPaperCoord = this.paperCoordinateConversion();

            const p = this.cutout.mapPolygonProjection;
            const minX = Math.floor(Math.min(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX())/unitsPerRender)*unitsPerRender;
            const maxX = Math.ceil(Math.max(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX())/unitsPerRender)*unitsPerRender;
            const minY = Math.floor(Math.min(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY())/unitsPerRender)*unitsPerRender;
            const maxY = Math.ceil(Math.max(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY())/unitsPerRender)*unitsPerRender;

            let done = 0;
            const totalRenders = Math.floor((maxX - minX) / unitsPerRender) * Math.floor((maxY - minY) / unitsPerRender);
            const self = this;

            const totalTilesApprox = totalRenders * (OlMip.mapPixelWidth / pxDimensionPerTile) ** 2;
            if(totalTilesApprox > 200) {
                if(!confirm('De te downloaden kaart is van een groot formaat. Download niet onnodig grote kaarten, dit kan een hoge belasting geven op de bronservers. Wil je doorgaan?')) {
                    reject();
                    return;
                }
            }

            const tileLoader = new OlTileLoader(cache);

            return throttledParallelEachPromise(1, function*() {

                for(let x=minX; x<maxX; x+= unitsPerRender) {
                    for(let y=minY; y<maxY; y+= unitsPerRender) {
                        const imagePromise: Promise<HTMLImageElement> = self.downloadPrintImage(
                            cache,
                            tileLoader,
                            [
                                self.coordinateSystem.make(x, y+unitsPerRender),
                                self.coordinateSystem.make(x+unitsPerRender, y+unitsPerRender),
                                self.coordinateSystem.make(x+unitsPerRender, y),
                                self.coordinateSystem.make(x, y),
                            ]
                        );

                        const paperCoord = toPaperCoord.convert(self.coordinateSystem.make(x, y+unitsPerRender));

                        yield imagePromise.then((img) => {
                            doc.addImage(img, 'PNG', paperCoord.getX(), paperCoord.getY(), paperMmPerRender, paperMmPerRender);

                            done++;
                            if(progressCallback) {
                                progressCallback({
                                    done: done,
                                    total: totalRenders,
                                })
                            }
                        });
                    }
                }

            }).then(resolve, reject);

        });
    }

    private downloadPrintImage(cache: Cache, tileLoader: OlTileLoader, coords: C[]): Promise<HTMLImageElement> {
        const center = [
            (coords[1].getX() + coords[3].getX()) / 2,
            (coords[1].getY() + coords[3].getY()) / 2,
        ];

        const cacheKey = [
            'DATA_URL',
            this.mapImageProvider.name,
            this.coordinateSystem.name,
            this.scale,
            this.dpi,
            center[0],
            center[1],
        ].join('_');

        return cache.fetch(cacheKey, () => {
            return this.mapImageProvider.renderMap(this.coordinateSystem, this.getRenderZoom(), this.getZoom(), center, tileLoader);
        }).then((result) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.src = result;
                img.onload = function () {
                    resolve(img);
                };
            });
        });
    }
}
