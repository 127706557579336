import UserInterface from "./UserInterface";
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import Location, {LocationReactiveProps} from "./Location";
import {Translate} from "ol/interaction";
import LocationAddAction from "../ActionHistory/LocationAddAction";
import {Coordinate as olCoordinate} from "ol/coordinate";
import CoordinateConverter from "../Util/CoordinateConverter";
import {SheetData} from "write-excel-file";
import predictName from "../Util/NamePredictor";
import {reactive} from "vue";
import {MapBrowserEvent} from "ol";
import Route from "./Route";

export default class LocationCollection {

    private reactiveLocationsProps: LocationReactiveProps[];

    readonly mapLayer: VectorLayer<any>;
    readonly mapSource: VectorSource<any>;
    readonly translateInteraction: Translate;

    private locations: Location[] = [];

    constructor(readonly userInterface: UserInterface) {
        this.reactiveLocationsProps = reactive([]);

        const olMap = userInterface.getMap().getOpenlayersMap();

        this.mapSource = new VectorSource();

        this.mapLayer = new VectorLayer({
            source: this.mapSource,
        });

        this.translateInteraction = new Translate({
            layers: [this.mapLayer],
            condition: (mapBrowserEvent: MapBrowserEvent<any>): boolean => {
                // You may not drag in locked mode
                if (this.userInterface.isLocked()) {
                    return false;
                }

                if (this.userInterface.getRouteCollection().getFocusedRoute()?.getEditMode() === 'intermediate') {
                    // Work-around for glitch where marker is snapped to intermediate points when moving a marker
                    // that was originally located near an intermediate point
                    return false;
                }

                return true;
            },
        });

        olMap.addLayer(this.mapLayer);
        olMap.addInteraction(this.translateInteraction);

        this.translateInteraction.on('translatestart', (e) => {
            document.body.style.cursor = 'grabbing';
        });

        this.translateInteraction.on('translateend', (e) => {
            document.body.style.cursor = 'default';

            const feature = e.features.getArray()[0];

            if (!feature) {
                return;
            }

            const location: Location = feature.get('location');

            if (!location) {
                return;
            }

            if (location.isMouseOver()) {
                document.body.style.cursor = 'grab';
            }

            location.updateAfterMove();
        });

        olMap.on('singleclick', (e) => {
            if (e.defaultPrevented) {
                return;
            }

            const locationFeature = olMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (feature && feature.get('location')) {
                    return feature;
                }
            }, {
                layerFilter: (layer) => {
                    return layer == this.mapLayer;
                }
            });

            if (locationFeature) {
                e.preventDefault();
                e.stopPropagation();

                const location = locationFeature.get('location');
                this.userInterface.toggleCoordinatePanelForLocation(location);
            }
        });
    }

    public getOpenlayersMap()
    {
        return this.userInterface.getMap().getOpenlayersMap();
    }

    public getLocations()
    {
        return this.locations;
    }

    public find(id: number)
    {
        return this.locations.find(location => location.id === id);
    }

    public getReactiveLocationsProps(): LocationReactiveProps[]
    {
        return this.reactiveLocationsProps;
    }

    public updateReactiveLocationsProps(): void
    {
        this.reactiveLocationsProps.splice(0, this.reactiveLocationsProps.length, ...this.locations.map(location => location.reactiveProps));
    }

    public addLocation(coordinate: olCoordinate|null = null, name: string|null = null)
    {
        const location = new Location(this, coordinate);
        location.setName(
            name
            || predictName(this.locations.map((location) => location.getName()))
            || 'Punt ' + (location.id + 1)
        );

        this.userInterface.actionHistory.addAction(new LocationAddAction(
            location,
            this.locations.length
        ));

        return location;
    }

    public attachLocation(location: Location, position: number)
    {
        location.mouseout(false);
        location.addToMap();
        this.locations.splice(position, 0, location);
        this.updateReactiveLocationsProps();
        this.userInterface.showObjectListPanel('location');
    }

    public detachLocation(location: Location)
    {
        const index = this.locations.indexOf(location);
        if(index === -1) {
            throw new Error('Invalid location');
        }

        location.removeFromMap();
        this.locations.splice(index, 1);
        this.updateReactiveLocationsProps();
        this.userInterface.showObjectListPanel('location');
    }

    public moveLocationInList(location: Location, newIndex: number): void {
        const index = this.locations.indexOf(location);
        if (index === -1) {
            throw new Error('Invalid location');
        }

        this.locations.splice(index, 1);
        this.locations.splice(newIndex, 0, location);

        this.updateReactiveLocationsProps();
        this.userInterface.showObjectListPanel('location');
    }

    public duplicateLocation(sourceLocation: Location)
    {
        const coordinate = sourceLocation.getCoordinate();
        const location = this.addLocation();
        location.setCoordinate(coordinate);
        location.setColorAndMarkerType(sourceLocation.getColor(), sourceLocation.getMarkerType());

        location.setDrawMarker(sourceLocation.getDrawMarker());
        location.setDrawName(sourceLocation.getDrawName());
    }

    public drawOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        for (const location of this.locations) {
            if (location.isVisible()) {
                location.drawMarkerOnPdf(doc, cutout);
            }
        }

        for (const location of this.locations) {
            if (location.isVisible()) {
                location.drawNameOnPdf(doc, cutout);
            }
        }
    }

    public downloadAllXlsx()
    {
        const coordinateSystemCodes = [
            'EPSG:28992',
            'EPSG:25832',
            'EPSG:4326',
        ];

        const formatColumns = [];

        const dummyCoord = CoordinateConverter.getCoordinateSystem('EPSG:4326').make(0, 0);

        for (const code of coordinateSystemCodes) {
            const coordinateSystem = CoordinateConverter.getCoordinateSystem(code);
            const systemCoord = CoordinateConverter.convert(dummyCoord, coordinateSystem);
            const formats = systemCoord.formats();

            for (const format in formats) {
                formatColumns.push({
                    name: coordinateSystem.name + ' (' + format + ')',
                    formatter: (location: Location) => {
                        try {
                            return location.getFormattedCoordinate(coordinateSystem, format);
                        } catch (e) {
                            return '-';
                        }
                    }
                });
            }
        }

        const data: SheetData = [
            [
                {
                    value: 'Punt',
                    fontWeight: 'bold',
                    backgroundColor: '#eeeeee',
                    align: 'center'
                },
            ]
        ];
        const columnConfig = [{width: 20}];
        for (const column of formatColumns) {
            data[0].push({
                value: column.name,
                fontWeight: 'bold',
                backgroundColor: '#eeeeee',
                align: 'center',
            });
            columnConfig.push({width: 20});
        }

        for (const location of this.locations) {
            const row = [
                {value: location.getName()},
            ];
            for (const column of formatColumns) {
                row.push({value: column.formatter(location)});
            }
            data.push(row);
        }

        import('write-excel-file').then(({default: writeXlsxFile}) => {
            return writeXlsxFile(data, {
                columns: columnConfig,
                fileName: 'punten.xlsx',
            });
        })
    }
}
