import {Style} from 'ol/style';
import {LineString, Point} from 'ol/geom';
import {Coordinate as olCoordinate} from "ol/coordinate";
import {Feature} from "ol";
import {fromLonLat, toLonLat} from "ol/proj";
import {Serialization} from "./Serializer";
import {jsPDF} from "jspdf";
import Cutout from "./Cutout";
import RouteIntermediate from "./RouteIntermediate";
import Route from "./Route";
import RouteSideRoadCoordinateMutationAction from "../ActionHistory/RouteSideRoadCoordinateMutationAction";
import {getLength} from "ol/sphere";

export default class RouteSideRoad {
    private oldCoordinates: olCoordinate[] = [];

    private readonly mainFeature: Feature<any>;

    private lineStringStyles;
    private endPointStyle;

    private _hasMouseover = false;
    private _hasFocus: boolean = false;

    constructor(readonly intermediate: RouteIntermediate) {
        this.mainFeature = new Feature({
            geometry: new LineString([]),
            routeSideRoad: this,
        });
        this.mainFeature.setStyle(this.getRouteSideRoadsUtil().getStyleFunction());

        this.setCoordinates([intermediate.getOlCoordinate()], false);
    }

    public initStyles(): void
    {
        this.lineStringStyles = this._hasFocus ? this.getRouteSideRoadsUtil().computeLineStringStyles(this) : null;
        this.endPointStyle = this._hasFocus || this._hasMouseover ? this.getRouteSideRoadsUtil().computeEndPointStyle(this) : null;
    }

    public getLineStringStyles(): Style[]
    {
        return this.lineStringStyles;
    }

    public getEndPointStyle(defaultStyle: Style): Style
    {
        const endPointStyle = this.endPointStyle || defaultStyle;

        endPointStyle.setGeometry(new Point(this.mainFeature.getGeometry().getLastCoordinate()));

        return endPointStyle;
    }

    private getRouteSideRoadsUtil()
    {
        return this.intermediate.intermediates.routeSideRoadsUtil;
    }

    public getMainFeature()
    {
        return this.mainFeature;
    }

    private getRouteCollection()
    {
        return this.intermediate.intermediates.route.routeCollection;
    }

    public addToMap()
    {
        this.getRouteCollection().sideRoadsMainSource.addFeature(this.mainFeature);
    }

    public removeFromMap()
    {
        this.setMouseOver(false);
        this.getRouteCollection().sideRoadsMainSource.removeFeature(this.mainFeature);
    }

    public hasMouseover()
    {
        return this._hasMouseover;
    }

    public setMouseOver(mouseOver: boolean)
    {
        this._hasMouseover = mouseOver;
    }

    public hasFocus()
    {
        return this._hasFocus;
    }

    public setFocus(focus)
    {
        this._hasFocus = focus;
    }

    public triggerChangedByModify()
    {
        // Make sure the first point is never modified
        const lineString: LineString = this.mainFeature.getGeometry();
        const oldFirst = this.oldCoordinates[0];
        const newFirst = lineString.getCoordinates()[0];
        if (oldFirst[0] !== newFirst[0] || oldFirst[1] !== newFirst[1]) {
            const coordinates = lineString.getCoordinates();
            coordinates.unshift(oldFirst);
            lineString.setCoordinates(coordinates);
        }

        this.triggerChanged(true, true);
    }

    public triggerChangedEndpoint()
    {
        // Make sure only the last point can be modified
        const lineString: LineString = this.mainFeature.getGeometry();
        const oldCoordinates = this.oldCoordinates;
        const newCoordinates = lineString.getCoordinates();

        if (oldCoordinates.length !== newCoordinates.length) {
            lineString.setCoordinates(this.oldCoordinates);
            return;
        }

        for (let i = 0; i < oldCoordinates.length - 1; i++) {
            if (oldCoordinates[i][0] !== newCoordinates[i][0] || oldCoordinates[i][1] !== newCoordinates[i][1]) {
                lineString.setCoordinates(this.oldCoordinates);
                return;
            }
        }

        this.triggerChanged(true, false);
    }

    updateAfterIntermediateMove(coordinate: olCoordinate): void {
        const lineString: LineString = this.mainFeature.getGeometry();
        const coordinates = lineString.getCoordinates();
        coordinates[0] = coordinate;
        lineString.setCoordinates(coordinates);

        this.triggerChanged(false, false);
    }

    public triggerChanged(addAction: boolean, ensureFocused: boolean)
    {
        if (addAction) {
            this.getRouteCollection().userInterface.actionHistory.addAction(new RouteSideRoadCoordinateMutationAction(this, this.oldCoordinates, ensureFocused));
        }
        this.oldCoordinates = this.getCoordinates().slice();
    }

    public getCoordinates(): olCoordinate[]
    {
        return this.mainFeature.getGeometry().getCoordinates();
    }

    public setCoordinates(coordinates: olCoordinate[], ensureFocused: boolean)
    {
        this.mainFeature.getGeometry().setCoordinates(coordinates);
        this.triggerChanged(false, false);

        if (ensureFocused && !this._hasFocus) {
            // When removing side road points, we may end up with a side road consisting of a single point. In that case,
            // we always want it to be focused such that it does not become a stray empty side road
            this.intermediate.intermediates.routeSideRoadsUtil.focusSideRoad(this);
        }
    }

    public getLength(): number {
        return getLength(new LineString(this.getCoordinates()));
    }

    serialize(): Serialization {
        const coordinates = [];
        for (const coordinate of this.getCoordinates()) {
            coordinates.push(toLonLat(coordinate));
        }
        return {
            coordinates: coordinates,
        };
    }

    static unserialize(serialized: Serialization, intermediate: RouteIntermediate): RouteSideRoad {
        const routeSideRoad = new RouteSideRoad(intermediate);

        const coordinates: olCoordinate[] = [];
        for (const coordinate of serialized.coordinates) {
            coordinates.push(fromLonLat(coordinate));
        }
        routeSideRoad.setCoordinates(coordinates, false);

        return routeSideRoad;
    }

    public drawOnPdf(doc: jsPDF, cutout: Cutout<any, any, any>)
    {
        Route.drawLineOnPdf(
            doc,
            cutout,
            this.getCoordinates(),
            this.intermediate.intermediates.route.getColor(),
            null,
            false,
        );
    }
}
