import Cutout from "./Cutout";
import UserInterface from "./UserInterface";
import {stringToUnit8array, uint8arrayToString, unreactive} from "../Util/functions";
import Route from "./Route";
import Location from "./Location";
import UserError from "../Util/UserError";
import pako from "pako";
import $ from "jquery";

export type Serialization = Record<string, any>;

export type LinkData = {url: string, json: string};

export default class Serializer {

    public static getBaseLink(): string {
        return [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
    }

    createCutoutLink(cutout: Cutout<any, any, any>) : LinkData {
        return this.createLink([cutout], [], [], cutout.userInterface);
    }

    createRouteLink(route: Route) : LinkData {
        return this.createLink([], [route], [], route.routeCollection.userInterface);
    }

    createLocationLink(location: Location) : LinkData {
        return this.createLink([], [], [location], location.locationCollection.userInterface);
    }

    createWorkspaceLink(userInterface: UserInterface): LinkData {
        return this.createLink(
            userInterface.getCutouts(),
            userInterface.getRouteCollection().getRoutes(),
            userInterface.getLocationCollection().getLocations(),
            userInterface,
            unreactive(userInterface.reactiveData.userInterfaceOptions),
        );
    }

    private createLink(
        cutouts: Cutout<any, any, any>[],
        routes: Route[],
        locations: Location[],
        userInterface: UserInterface,
        userInterfaceOptions = null
    ): LinkData {
        const exportString = this.export(cutouts, routes, locations, userInterface, userInterfaceOptions);

        const deflatedString = uint8arrayToString(pako.deflate(exportString));

        return {
            url: Serializer.getBaseLink() + '?workspace=' + encodeURIComponent(btoa(deflatedString)),
            json: exportString,
        };
    }

    importFromLink(linkData: string, userInterface: UserInterface): Promise<void> {
        let inflatedString;
        try {
            const deflatedString = atob(linkData);

            inflatedString = pako.inflate(stringToUnit8array(deflatedString));
            if (inflatedString === undefined) {
                throw '';
            }
        } catch (e) {
            console.log(e);
            throw new UserError('De opgegeven URL is ongeldig, probeer het nog een keer');
        }

        return this.import(uint8arrayToString(inflatedString), userInterface);
    }

    importFromShortLinkKey(shortLinkKey: string, userInterface: UserInterface): Promise<void> {
        return new Promise((resolve, reject) => {

            $.post('server/shortlinks.php?request=fetch', {
                short_link_key: shortLinkKey,
            })
                .done(result => {
                    if (result === '0') {
                        alert('Er is iets misgegaan bij het laden van de werkruimte');
                        reject();
                        return;
                    }

                    result = JSON.parse(result);
                    if (!result.workspace) {
                        alert('Deze werkruimte bestaat niet (meer)');
                        resolve();
                        return;
                    }

                    this.import(result.workspace, userInterface).then(resolve);
                })
                .fail(() => {
                    alert('Er is iets misgegaan bij het laden van de werkruimte');
                    reject();
                });
        });
    }

    serializeWorkspace(userInterface: UserInterface): Serialization {
        return this.serialize(
            userInterface.getCutouts(),
            userInterface.getRouteCollection().getRoutes(),
            userInterface.getLocationCollection().getLocations(),
            userInterface,
            unreactive(userInterface.reactiveData.userInterfaceOptions),
        );
    }

    private export(
        cutouts: Cutout<any, any, any>[],
        routes: Route[],
        locations: Location[],
        userInterface: UserInterface,
        userInterfaceOptions
    ): string {
        return JSON.stringify(this.serialize(cutouts, routes, locations, userInterface, userInterfaceOptions));
    }

    private import(serializedString: string, userInterface: UserInterface): Promise<void> {
        return this.unserialize(JSON.parse(serializedString), userInterface);
    }

    private serialize(
        cutouts: Cutout<any, any, any>[],
        routes: Route[],
        locations: Location[],
        userInterface: UserInterface,
        userInterfaceOptions
    ): Serialization {
        const serialized = {
            serializationVersion: 2,
            cutouts: [],
            routes: [],
            locations: [],
            userInterfaceOptions: userInterfaceOptions,
        };

        for(const cutout of cutouts) {
            serialized.cutouts.push(cutout.serialize());
        }

        for(const route of routes) {
            serialized.routes.push(route.serialize());
        }

        for(const location of locations) {
            serialized.locations.push(location.serialize());
        }

        return serialized;
    }

    unserialize(serialized: Serialization, userInterface: UserInterface): Promise<void> {
        const promises: Promise<void>[] = [];
        const cutouts: Cutout<any, any, any>[] = [];
        if(serialized.cutouts !== undefined) {
            for(const cutoutSerialized of serialized.cutouts) {
                const promise = Cutout.unserialize(cutoutSerialized, userInterface)
                    .then((cutout) => {
                        cutouts.push(cutout);
                    })
                ;

                promises.push(promise);
            }
        }

        const routes: Route[] = [];
        if (serialized.routes !== undefined) {
            for (const routeSerialized of serialized.routes) {
                routes.push(Route.unserialize(routeSerialized, userInterface.getRouteCollection()));
            }
        }

        const locations: Location[] = [];
        if (serialized.locations !== undefined) {
            for (const locationSerialized of serialized.locations) {
                locations.push(Location.unserialize(locationSerialized, userInterface.getLocationCollection()));
            }
        }

        return Promise.all(promises).then(() => {
            userInterface.setFromUnserialize(cutouts, routes, locations, serialized.userInterfaceOptions || {});
        });
    }


}
