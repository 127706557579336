import Action from "./Action";
import Route from "../Main/Route";
import {Serialization} from "../Main/Serializer";

export default class RouteChangeColorAction implements Action {

    private readonly oldColor: string;

    constructor(private route: Route, private newColor: string) {
        this.oldColor = this.route.getColor();
    }

    public apply() {
        this.route.setColor(this.newColor);
    }

    public revert() {
        this.route.setColor(this.oldColor);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldColor: this.oldColor,
            newColor: this.newColor,
        };
    }
}
