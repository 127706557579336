import Cutout from "../Main/Cutout";
import Action from "./Action";
import UserInterface from "../Main/UserInterface";
import {Serialization} from "../Main/Serializer";

export default class CutoutSortAction implements Action {

    private readonly oldIndex: number;

    constructor(
        readonly cutout: Cutout<any, any, any>,
        readonly userInterface: UserInterface,
        readonly newIndex: number,
    ) {
        this.oldIndex = this.userInterface.getCutouts().indexOf(cutout);
    }

    public apply() {
        this.userInterface.moveCutoutInList(this.cutout, this.newIndex);
    }

    public revert() {
        this.userInterface.moveCutoutInList(this.cutout, this.oldIndex);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldIndex: this.oldIndex,
            newIndex: this.newIndex,
        };
    }
}
