import Action from "./Action";
import Cutout from "../Main/Cutout";
import Projection from "../Projection/Projection";
import Coordinate from "../Coordinates/Coordinate";
import MapImageProvider from "../Projection/MapImageProvider";
import Grid from "../Main/Grid";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutProjectionAction<C extends Coordinate> implements Action {

    private readonly oldProjection: Projection<C, MapImageProvider>;
    private readonly oldGrid: Grid<Coordinate> = null;
    private readonly newGrid: Grid<Coordinate> = null;

    constructor(private cutout: Cutout<any, any, any>, private newProjection: Projection<C, MapImageProvider>) {
        this.oldProjection = this.cutout.getProjection();

        if (!this.newProjection.getMapImageProvider().isCompatibleWithCoordinateSystem(cutout.getGrid().coordinateSystem)) {
            this.oldGrid = cutout.getGrid();
            this.newGrid = new Grid(this.newProjection.getMapImageProvider().getDefaultCoordinateSystem());
        }
    }

    public apply() {
        this.cutout.setProjection(this.newProjection);
        this.oldProjection.detach();

        if(this.newGrid) {
            this.cutout.setGrid(this.newGrid);
            this.oldGrid.detach();
        }

        if (this.newProjection.getMapImageProvider().isCompatibleWithCoordinateSystem(this.oldProjection.coordinateSystem)) {
            this.newProjection.setCoordinateSystem(this.oldProjection.coordinateSystem);
        }
    }

    public revert() {
        this.cutout.setProjection(this.oldProjection);
        this.newProjection.detach();

        if(this.newGrid) {
            this.cutout.setGrid(this.oldGrid);
            this.newGrid.detach();
        }
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldProjection: this.oldProjection.getMapImageProvider().name,
            newProjection: this.newProjection.getMapImageProvider().name,
            oldGrid: this.oldGrid ? {
                coordinateSystem: this.oldGrid.coordinateSystem.code,
                gridSpec: this.oldGrid.getCustomGridSpec()
            } : null,
            newGrid: this.newGrid ? {
                coordinateSystem: this.newGrid.coordinateSystem.code,
                gridSpec: this.newGrid.getCustomGridSpec()
            } : null,
        };
    }
}
