import Action from "./Action";
import Cutout from "../Main/Cutout";
import LuchtfotoProjection from "../Projection/Specialized/LuchtfotoProjection";
import {Serialization} from "../Main/Serializer";

export default class ChangeLuchtfotoProjectionDrawLabelsAction implements Action {

    private readonly oldDrawLabels: boolean;

    constructor(private cutout: Cutout<any, any, any>, private newDrawLabels: boolean) {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof LuchtfotoProjection)) {
            throw new Error('Can only change draw labels for Luchtfoto projection');
        }
        this.oldDrawLabels = projection.getDrawLabels();
    }

    public apply() {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof LuchtfotoProjection)) {
            throw new Error('Can only set draw labels for Luchtfoto projection');
        }
        projection.setDrawLabels(this.newDrawLabels);
    }

    public revert() {
        const projection = this.cutout.getProjection();
        if(!(projection instanceof LuchtfotoProjection)) {
            throw new Error('Can only set draw labels for Luchtfoto projection');
        }
        projection.setDrawLabels(this.oldDrawLabels);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldDrawLabels: this.oldDrawLabels,
            newDrawLabels: this.newDrawLabels,
        };
    }
}
