import Action from "./Action";
import Location from "../Main/Location";
import {MarkerType} from "../Main/Map";
import {Serialization} from "../Main/Serializer";

export default class LocationChangeMarkerTypeAction implements Action {

    private readonly oldMarkerType: MarkerType;

    constructor(private location: Location, private newMarkerType: MarkerType) {
        this.oldMarkerType = this.location.getMarkerType();
    }

    public apply() {
        this.location.setMarkerType(this.newMarkerType);
    }

    public revert() {
        this.location.setMarkerType(this.oldMarkerType);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            oldMarkerType: this.oldMarkerType,
            newMarkerType: this.newMarkerType,
        };
    }
}
