
export function createSvgEl<K extends keyof SVGElementTagNameMap>(type: K, attr): SVGElementTagNameMap[K] {
    let el = document.createElementNS("http://www.w3.org/2000/svg", type);

    if (attr) {
        Object.keys(attr).forEach(function(key) {
            const value = attr[key];
            if (key != key.toLowerCase() && ['viewBox', 'lengthAdjust', 'textLength'].indexOf(key) === -1) {
                key = key.replace(/[A-Z]/g, m => "-" + m.toLowerCase());
            }
            el.setAttributeNS(null, key, value);
        });
    }
    return el;
}
