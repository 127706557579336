import {presentDownload, presentDownloadRaw} from "../Util/functions";
import RouteTechnique from "./RouteTechnique";
import {Svg2Roughjs} from "svg2roughjs";
import {Options} from "roughjs/bin/core";
import UserError from "../Util/UserError";
import {createSvgEl} from "./Util/Svg";

export type RouteImageFormat = 'png' | 'svg';

export default abstract class ImageGeneratingTechnique<ConfigType> extends RouteTechnique<ConfigType>
{
    protected readonly requiresIntermediates: boolean;

    protected abstract generateSvg(): SVGSVGElement;

    protected abstract getRoughjsConfig(): Options;

    download(): void {
        if (this.requiresIntermediates && this.route.getIntermediates().getIntermediatesCount() === 0) {
            throw new UserError('Voeg om deze routetechniek te genereren eerst beslispunten toe aan de route.');
        }

        const config = this.config;

        let svgPromise;
        if (config.useRoughJs) {
            svgPromise = this.generateRoughJsSvg();
        } else {
            svgPromise = Promise.resolve(this.generateSvg());
        }

        if (config.format === 'svg') {
            svgPromise.then((svg) => {
                this.downloadSvg(svg);
            });
        } else if (config.format === 'png') {
            svgPromise.then((svg) => {
                this.downloadPng(svg);
            });
        } else {
            throw new Error('Invalid format');
        }
    }

    protected drawDoubleArrow(
        svg: SVGSVGElement,
        from: [number, number],
        to: [number, number],
        svgOptions: Record<string, any>,
        config: Partial<{
            arrowWidth: number,
            tipLength: number,
            tipAngle: number,
            tipWidth: number,
            closedBase: boolean,
        }> = {}
    ) {
        const distance = Math.sqrt((to[0] - from[0]) ** 2 + (to[1] - from[1]) ** 2);

        config = Object.assign({
            arrowWidth: distance / 10,
            tipAngle: 45,
            closedBase: true,
        }, config || {});

        config.tipLength = config.tipLength || 5 * Math.sqrt(2) * config.arrowWidth;
        config.tipWidth = config.tipWidth || Math.sqrt(2) * config.arrowWidth;

        const tipUnitX = (to[0] - from[0]) / distance;
        const tipUnitY = (to[1] - from[1]) / distance;
        const perpUnitX = -tipUnitY;
        const perpUnitY = tipUnitX;

        const tanTipAngle = Math.tan(config.tipAngle / 180 * Math.PI);
        const cosTipAngle = Math.cos(config.tipAngle / 180 * Math.PI);
        const sinTipAngle = Math.sin(config.tipAngle / 180 * Math.PI);

        // pointDefs = list of [perpendicular, parallel] components as if arrow points north
        //
        //  E
        //  \
        //   \ E'
        //    \
        //     \
        //   B  \
        //   |\  \
        //   | C,^D
        //   |
        //   |
        //  _|
        // 0 A
        const pointDefs = [
            [
                // A
                config.arrowWidth,
                0
            ],
            [
                // B: Apply trigonometry to triangle with hypotenuse EE' (= tan term) and remainder of DE' minus length of BC (= sin term)
                config.arrowWidth,
                distance - config.arrowWidth / tanTipAngle - config.tipWidth / sinTipAngle
            ],
            [
                // C: Projection of tipWidth from D
                sinTipAngle * config.tipLength - cosTipAngle * config.tipWidth,
                distance - cosTipAngle * config.tipLength - sinTipAngle * config.tipWidth
            ],
            [
                // D: Projection of tipLength from E
                sinTipAngle * config.tipLength,
                distance - cosTipAngle * config.tipLength
            ],
            [
                // E
                0,
                distance
            ],
        ];

        for (const [perpendicular, parallel] of pointDefs.slice(0, pointDefs.length - 1).reverse()) {
            pointDefs.push([-perpendicular, parallel]);
        }

        const points = [];

        for (const [perpendicular, parallel] of pointDefs) {
            points.push([
                from[0] + perpendicular * perpUnitX + parallel * tipUnitX,
                from[1] + perpendicular * perpUnitY + parallel * tipUnitY,
            ]);
        }

        if (config.closedBase) {
            points.push(points[0]);
        }

        svgOptions.points = points.join(' ');

        svg.appendChild(createSvgEl('polyline', svgOptions));
    }

    public downloadSvg(svg: SVGSVGElement)
    {
        if (svg.getAttribute('width').match(/^\d+$/)) {
            svg.setAttribute('width', svg.getAttribute('width') + 'mm')
        }
        if (svg.getAttribute('height').match(/^\d+$/)) {
            svg.setAttribute('height', svg.getAttribute('height') + 'mm')
        }

        let source = (new XMLSerializer()).serializeToString(svg);

        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        presentDownload(this.route.getName() + ' - ' + this.constructor.TECHNIQUE_TITLE + '.svg', source, 'image/svg+xml');
    }

    public downloadPng(svg: SVGSVGElement)
    {
        const MM_PER_INCH = 25.4;
        const dpmm = 300 / MM_PER_INCH;

        let width = <any>svg.getAttribute('width');
        if (width.match(/^\d+$/)) {
            width = +width * dpmm;
        } else if(width.endsWith('px')) {
            svg.setAttribute('width', '' + (+width.replace('px', '')));
            width = +svg.getAttribute('width');
        }
        let height = <any>svg.getAttribute('height');
        if (height.match(/^\d+$/)) {
            height = +height * dpmm;
        } else if(height.endsWith('px')) {
            svg.setAttribute('height', '' + (+height.replace('px', '')));
            height = +svg.getAttribute('height');
        }

        const source = (new XMLSerializer()).serializeToString(svg);

        const blob = new Blob([source], {type: 'image/svg+xml'});
        const objectUrl = URL.createObjectURL(blob);
        let img = document.createElement('img');
        img.addEventListener('load', () => {
            URL.revokeObjectURL(objectUrl);

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            if (!this.config.transparent) {
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, width, height);
            }
            ctx.drawImage(img, 0, 0, width, height);

            const png = canvas.toDataURL();

            presentDownloadRaw(this.route.getName() + ' - ' + this.constructor.TECHNIQUE_TITLE + '.png', png);

        }, {once: true});
        img.src = objectUrl;
    }

    private generateRoughJsSvg(): Promise<SVGSVGElement> {
        const svg = this.generateSvg();

        const roughjsSvg = createSvgEl('svg', {});

        const config = this.getRoughjsConfig();

        const svg2roughjs = new Svg2Roughjs(roughjsSvg);
        svg2roughjs.svg = svg;
        svg2roughjs.seed = config.seed;
        svg2roughjs.roughConfig = config;
        return svg2roughjs.sketch().then(() => {
            if (svg.getAttribute('width').endsWith('px')) {
                roughjsSvg.setAttribute('width', roughjsSvg.getAttribute('width') + 'px')
            }
            if (svg.getAttribute('height').endsWith('px')) {
                roughjsSvg.setAttribute('height', roughjsSvg.getAttribute('height') + 'px')
            }

            // Integer sizes are interpreted as mm, so we need to ensure trailing zero decimals are retained
            if (svg.getAttribute('width') === roughjsSvg.getAttribute('width') + '.0') {
                roughjsSvg.setAttribute('width', roughjsSvg.getAttribute('width') + '.0');
            }
            if (svg.getAttribute('height') === roughjsSvg.getAttribute('height') + '.0') {
                roughjsSvg.setAttribute('height', roughjsSvg.getAttribute('height') + '.0');
            }

            roughjsSvg.setAttribute('viewBox', '0 0 ' + roughjsSvg.getAttribute('width').replace('px', '').replace('mm', '') + ' ' + roughjsSvg.getAttribute('height').replace('px', '').replace('mm', ''))

            return roughjsSvg;
        });
    }
}
