// We use __plattekaart_initialized to be sure side effects are not bound twice. Essentially this should never happen
// in the first place, however on iPad it appears that when navigating back from a downloaded .xlsx file, the page
// throws an error if not guarded like this.

import ErrorLogger from "./Util/ErrorLogger";
if (!window.__plattekaart_initialized) {
    ErrorLogger.bindToWindow();
} else {
    console.log('Caught reinitialization');
}

// Boot the system
import './Main/Boot';
import UserInterface from "./Main/UserInterface";
import 'bootstrap';

// Build the user interface
if (!window.__plattekaart_initialized) {
    window.__plattekaart_initialized = true;
    const userInterface = new UserInterface();
    ErrorLogger.setUserInterface(userInterface);
}

// require('./sandbox');
