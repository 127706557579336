import {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";
import GeodeticLineTechnique from "./GeodeticLineTechnique";

type OleaatConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    displayScale: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
    rotation: number,
    displayNorthArrow: boolean,
    drawSideRoads: boolean,
};

export default class Oleaat extends GeodeticLineTechnique<OleaatConfig>
{
    public static readonly TECHNIQUE_NAME = 'oleaat';
    public static readonly TECHNIQUE_TITLE = 'Oleaat (RD)';
    protected readonly TECHNIQUE_TEXT_TITLE = 'Oleaat';

    protected readonly requiresIntermediates = false;

    protected getDefaultConfig(): OleaatConfig {
        return {
            format: 'png',
            transparent: false,
            displayScale: true,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
            rotation: randomInt(0, 360),
            displayNorthArrow: false,
            drawSideRoads: false,
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 1.5,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const projectedCoordinates = this.getProjectedCoordinates(this.config.drawSideRoads);

        const dim = this.computeDimensioning(projectedCoordinates);

        const svg = createSvgEl('svg', {
            width: dim.widthPx + 'px',
            height: dim.heightPx + 'px',
            viewBox: "0 0 " + dim.width + " " + dim.height,
        });

        this.drawRoute(svg, dim, projectedCoordinates);

        if (this.config.displayScale) {
            this.drawScale(svg, dim);
        }

        if (this.config.displayNorthArrow) {
            this.drawNorthArrow(svg, dim);
        }

        this.route.routeCollection.userInterface.toasts.addRouteTechniqueScaleToast(this.TECHNIQUE_TEXT_TITLE, dim.width);

        return svg;
    }
}
