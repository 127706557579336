<template>
  <div
      class="modal"
      tabindex="-1"
      role="dialog"
      v-bind:id="'route_settings_modal_' + routeRP.id"
      data-bs-backdrop="false"
      data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Route instellingen {{ routeRP.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs" role="tablist" v-bind:id="'rsm_' + routeRP.id + '_tabbar-tablist'">
            <li class="nav-item">
              <a
                  class="nav-link active"
                  v-bind:id="'rsm_' + routeRP.id + '_tabbar_general-tab'"
                  role="tab"
                  data-bs-toggle="tab"
                  v-bind:aria-controls="'rsm_' + routeRP.id + '_tabbar_general'"
                  v-bind:data-bs-target="'#rsm_' + routeRP.id + '_tabbar_general'"
                  aria-selected="true"
              >Algemeen</a>
            </li>
          </ul>
          <div class="tab-content">
            <div
                class="tab-pane show active"
                v-bind:id="'rsm_' + routeRP.id + '_tabbar_general'"
                role="tabpanel"
                v-bind:aria-labelledby="'rsm_' + routeRP.id + 'tabbar_general-tab'"
            >
              <div class="form-group">
                <label v-bind:for="'rsm_' + routeRP.id + '_name'" class="form-label">Route naam</label>
                <input type="text" class="form-control" v-bind:id="'rsm_' + routeRP.id + '_name'" placeholder="Typ een naam..." v-bind:value="routeRP.name">
              </div>

              <div class="form-group">
                <label v-bind:id="'rsm_' + routeRP.id + '_color_label'" class="form-label">Kleur</label>

                <div class="input-group">
                  <div class="form-control d-flex flex-column justify-content-center cursor-pointer" v-bind:id="'rsm_' + routeRP.id + '_color_value'">
                    <div class="color-value" v-bind:style="'background-color: ' + routeRP.color"></div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      v-bind:id="'rsm_' + routeRP.id + '_color_toggle'"
                  >
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <button
                        v-for="color of colors"
                        class="dropdown-item color-option"
                        type="button"
                        v-bind:style="'background-color: ' + color"
                        v-on:click="changeColor(color)"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label v-bind:for="'rsm_' + routeRP.id + '_line_style_type'" class="form-label">Lijn stijl</label>
                <select class="form-select" v-bind:id="'rsm_' + routeRP.id + '_line_style_type'">
                  <option
                      v-for="[type, label] in [['solid', 'Continu'], ['dashed', 'Gestreept'], ['dotted', 'Gestipt']]"
                      :key="type"
                      v-bind:value="type"
                      v-bind:selected="routeRP.lineStyleType === type"
                  >{{ label }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Container from "../Main/Container";
import $ from "jquery";
import Route from "../Main/Route";
import RouteChangeNameAction from "../ActionHistory/RouteChangeNameAction";
import RouteChangeColorAction from "../ActionHistory/RouteChangeColorAction";
import RouteChangeLineStyleTypeAction from "../ActionHistory/RouteChangeLineStyleTypeAction";
import {bsModal} from "../Util/functions";
import {Dropdown} from "bootstrap";

export default {
  props: {
    listenId: String,
    route: Route,
  },
  data () {
    $(() => {
      const route = this.route;

      $('#' + this.listenId).on('click', () => {
        bsModal('#route_settings_modal_' + route.id).show();
      });

      $('#rsm_' + route.id + '_name').on('change keyup input blur', function() {
        const newName = $(this).val();
        if(newName !== route.name) {
          route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeNameAction(route, newName));
        }
      });

      const $toggle = $('#rsm_' + route.id + '_color_toggle');
      let colorOpen = false;
      $toggle.parent()[0].addEventListener('show.bs.dropdown', () => colorOpen = true);
      $toggle.parent()[0].addEventListener('hidden.bs.dropdown', () => colorOpen = false);
      $('#rsm_' + route.id + '_color_label, #rsm_' + route.id + '_color_value').on('click', () => {
        if (!colorOpen) {
          setTimeout(() => Dropdown.getOrCreateInstance($toggle[0]).show());
        }
      });

      $('#rsm_' + route.id + '_line_style_type').on('change', function() {
        const newVal = $(this).val();
        const oldLineStyleType = route.lineStyleType;
        if(newVal !== oldLineStyleType) {
          route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeLineStyleTypeAction(route, newVal));
        }
      });

    });

    return {
      container: Container,
      routeRP: this.route.reactiveProps,
      colors: this.route.routeCollection.userInterface.colors,
    };
  },
  watch: {

  },
  computed: {

  },
  methods: {
    changeColor(newColor) {
      if(newColor !== this.route.color) {
        this.route.routeCollection.userInterface.actionHistory.addAction(new RouteChangeColorAction(this.route, newColor));
      }
    }
  }
};
</script>

<style scoped>
.color-option {
  height: 1rem;
}

.color-value {
  height: .25rem;
}
</style>
