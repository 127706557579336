import {IntermediatesMeta} from "../Main/RouteIntermediates";
import Route from "../Main/Route";
import {reactive} from "vue";
import {Serialization} from "../Main/Serializer";
import Container from "../Main/Container";
import UserError from "../Util/UserError";
import RouteIntermediate from "../Main/RouteIntermediate";
import RouteSideRoad from "../Main/RouteSideRoad";

export type RouteTechniqueReactiveProps<ConfigType> = {
    name: string,
    title: string,
    config: ConfigType,
};

export default abstract class RouteTechnique<ConfigType>
{
    public static readonly TECHNIQUE_NAME: string;
    public static readonly TECHNIQUE_TITLE: string;

    protected config: ConfigType;

    public readonly reactiveProps: RouteTechniqueReactiveProps<ConfigType>;

    protected abstract getDefaultConfig(): ConfigType;

    protected constructor(
        public readonly route: Route,
    ) {
        this.config = this.getDefaultConfig();

        this.reactiveProps = reactive({
            name: this.constructor.TECHNIQUE_NAME,
            title: this.constructor.TECHNIQUE_TITLE,
            config: this.config,
        });
    }

    public abstract download(): void;

    protected getIntermediatesMeta(): IntermediatesMeta {
        return this.route.getIntermediates().computeIntermediatesMeta();
    }

    public getConfig(): ConfigType {
        return this.config;
    }

    public setConfig(key: string, value): void {
        this.config[key] = value;
        this.updateReactiveProps();
    }

    public serialize(): Serialization {
        return {
            name: this.constructor.TECHNIQUE_NAME,
            config: Object.assign({}, this.config),
        };
    }

    static unserialize(serialized: Serialization, route: Route): RouteTechnique<any> {
        const prototype = Container.allRouteTechniques()[serialized.name];
        const routeTechnique = new prototype(route);

        for (const [key, value] of Object.entries(serialized.config)) {
            routeTechnique.setConfig(key, value);
        }
        return routeTechnique;
    }

    protected updateReactiveProps(): void {
        this.reactiveProps.config = Object.assign({}, this.config);
    }

    protected computeSideRoadsLeftRight() {
        const intermediatesMeta = this.route.getIntermediates().computeIntermediateMeta();

        return this.route.getIntermediates().getIntermediatesList()
            .map((intermediate: RouteIntermediate, i: number): {left: number, right: number} => {
                if (intermediatesMeta[i].pathIncomingBearing === null) {
                    throw new UserError('Deze routetechniek kan niet gemaakt worden met een route die op een beslispunt begint. Pas de route aan zodat het eerste beslispunt een inkomende weg heeft.');
                }
                if (intermediatesMeta[i].pathInitialBearing === null) {
                    throw new UserError('Deze routetechniek kan niet gemaakt worden met een route die op een beslispunt eindigt. Pas de route aan zodat het laatste beslispunt een uitgaande weg heeft.');
                }

                const from = (intermediatesMeta[i].pathIncomingBearing + 180) % 360;
                const to = intermediatesMeta[i].pathInitialBearing;

                const min = Math.min(from, to);
                const max = Math.max(from, to);

                let inside = 0, outside = 0, stray = 0;

                intermediate.getSideRoads().forEach((sideRoad: RouteSideRoad) => {
                    const sideRoadCoordinates = sideRoad.getCoordinates();
                    const bearing = Route.computeBearing(intermediate.getOlCoordinate(), sideRoadCoordinates[1]);

                    const isInside = min <= bearing && bearing <= max;
                    if (isInside) {
                        inside++;
                    } else {
                        outside++;
                    }

                    if (sideRoadCoordinates.length > 1) {
                        const endBearing = Route.computeBearing(intermediate.getOlCoordinate(), sideRoadCoordinates[sideRoadCoordinates.length - 1]);
                        if (isInside !== (min <= endBearing && endBearing <= max)) {
                            stray++;
                        }
                    }
                });

                let right, left;
                if (to < from) {
                    right = inside;
                    left = outside;
                } else {
                    right = outside;
                    left = inside;
                }

                return {
                    left: left,
                    right: right,
                    stray: stray,
                };
            });
    }
}
