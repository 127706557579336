import Action from "./Action";
import Route from "../Main/Route";
import RouteTechnique from "../RouteTechniques/RouteTechnique";
import Container from "../Main/Container";
import {Serialization} from "../Main/Serializer";

export default class RouteIntermediatesChangeRouteTechnique implements Action {

    private readonly newRouteTechnique: RouteTechnique<any>;
    private readonly oldRouteTechnique: RouteTechnique<any>;

    constructor(private route: Route, newRouteTechniqueName: number) {
        this.oldRouteTechnique = route.getIntermediates().getRouteTechnique();

        const prototype = Container.allRouteTechniques()[newRouteTechniqueName];
        this.newRouteTechnique = new prototype(route);

        if (this.oldRouteTechnique) {
            for (const [key, value] of Object.entries(this.oldRouteTechnique.getConfig())) {
                if (typeof this.newRouteTechnique.reactiveProps.config[key] !== 'undefined') {
                    this.newRouteTechnique.setConfig(key, value);
                }
            }
        }
    }

    public apply() {
        this.route.getIntermediates().setRouteTechnique(this.newRouteTechnique);
    }

    public revert() {
        this.route.getIntermediates().setRouteTechnique(this.oldRouteTechnique);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            route: this.route.id,
            oldRouteTechnique: this.oldRouteTechnique?.constructor?.TECHNIQUE_NAME,
            newRouteTechnique: this.newRouteTechnique?.constructor?.TECHNIQUE_NAME,
        };
    }
}
