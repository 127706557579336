import WmtsProjection, {WmtsProjectionReactiveProps} from "../WmtsProjection";
import LuchtfotoMip from "./LuchtfotoMip";
import {Serialization} from "../../Main/Serializer";
import Coordinate from "../../Coordinates/Coordinate";
import {jsPDF} from "jspdf";
import Paper from "../../Util/Paper";
import Cache from "../../Util/Cache";
import Container from "../../Main/Container";

type _LuchtfotoProjectionReactiveProps = {
    type: 'LuchtfotoProjection',
    drawLabels: boolean,
}
export type LuchtfotoProjectionReactiveProps = WmtsProjectionReactiveProps & _LuchtfotoProjectionReactiveProps;

export default class LuchtfotoProjection extends WmtsProjection<Coordinate> {

    reactiveProps: LuchtfotoProjectionReactiveProps;

    constructor(wmtsName: string, scale: number = null, tileMatrixId: string = null, private drawLabels: boolean = true) {
        super(wmtsName, scale, tileMatrixId, <_LuchtfotoProjectionReactiveProps>{
            type: 'LuchtfotoProjection',
            drawLabels: drawLabels,
        });
    }

    clone(): LuchtfotoProjection {
        return new LuchtfotoProjection(
            this.mapImageProvider.name,
            this.getScale(),
            this.getTileMatrixId(),
            this.drawLabels,
        );
    }

    serialize(): Serialization {
        return {
            type: 'luchtfoto',
            mip: this.mapImageProvider.name,
            scale: this.scale,
            tileMatrixId: this.getTileMatrixId(),
            drawLabels: this.drawLabels,
        };
    }

    static unserialize(serialized: Serialization): LuchtfotoProjection {
        const projection = new LuchtfotoProjection(serialized.mip, serialized.scale);
        if(serialized.tileMatrixId) {
            projection.setTileMatrixId(serialized.tileMatrixId);
        }
        if(typeof serialized.drawLabels !== 'undefined') {
            projection.setDrawLabels(serialized.drawLabels);
        }
        return projection;
    }

    getDrawLabels(): boolean {
        return this.drawLabels;
    }

    setDrawLabels(newDrawLabels: boolean): void {
        this.reactiveProps.drawLabels = this.drawLabels = newDrawLabels;
    }

    projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void) | null): Promise<void> {
        if (!this.drawLabels) {
            return super.projectToPdf(doc, paper, cache, progressCallback);
        }

        const mip = <LuchtfotoMip>Container.mapImageProvider('nl_luchtfoto_lufolabels_wmts');

        return mip.fetchCapabilities().then(() => {
            let hasTileMatrix = false;
            for (const tileMatrix of mip.getTileMatrixList()) {
                if (tileMatrix.identifier === this.getTileMatrixId()) {
                    hasTileMatrix = true;
                    break;
                }
            }

            if (!hasTileMatrix) {
                return super.projectToPdf(doc, paper, cache, progressCallback);
            }

            let total = 0;

            return super.projectToPdf(doc, paper, cache, (evt) => {
                total = evt.total;

                progressCallback({
                    done: evt.done,
                    total: 2 * evt.total,
                })
            }).then(() => {
                const labelProjection = new WmtsProjection('nl_luchtfoto_lufolabels_wmts', this.scale, this.getTileMatrixId());
                return labelProjection.initialize().then(() => {
                    labelProjection.setAnchor(this.anchor);
                    labelProjection.attach(this.cutout);
                    return labelProjection.projectToPdf(doc, paper, cache, (evt) => {
                        progressCallback({
                            done: total + evt.done,
                            total: total + evt.total,
                        });
                    });
                });
            });
        });
    }
}
