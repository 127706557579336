import Coordinate from "../Coordinates/Coordinate";
import Wms, {WmsParams} from "./Wms";
import Cutout from "../Main/Cutout";
import Cache from "../Util/Cache";
import {Point} from "../Util/Math";
import {jsPDF} from "jspdf";
import Paper from "../Util/Paper";
import Container from "../Main/Container";
import Projection, {ProjectionReactiveProps} from "./Projection";
import {GridSpec} from "../Main/Grid";
import {Serialization} from "../Main/Serializer";
import {throttledParallelEachPromise} from "../Util/functions";

const MM_PER_INCH = 25.4;

type _WmsProjectionReactiveProps = {
    type: 'WmsProjection',
    dpi: number,
}
export type WmsProjectionReactiveProps = ProjectionReactiveProps & _WmsProjectionReactiveProps;

export default class WmsProjection<C extends Coordinate> extends Projection<C, Wms> {

    reactiveProps: WmsProjectionReactiveProps;

    private dpi: number;

    static createAndInitialize(wmsName: string, scale: number = null): Promise<WmsProjection<Coordinate>> {
        return new Promise<WmsProjection<Coordinate>>((resolve, reject) => {
            const projection = new WmsProjection(wmsName, scale);
            return projection.initialize().then(() => {
                resolve(projection);
            });
        });
    }

    constructor(wmsName: string, scale: number = null) {
        const wms = Container.wms(wmsName);

        const dpi = wms.getDefaultDpi() || 300;

        super(wms, scale, <_WmsProjectionReactiveProps>{
            type: 'WmsProjection',
            dpi: dpi,
        });

        this.dpi = dpi;
    }

    initialize(): Promise<void> {
        return this.mapImageProvider.fetchCapabilities().then(() => {
            if(this.scale === null) {
                this.reactiveProps.scale = this.scale = this.mapImageProvider.getDefaultScale();
            }
        });
    }

    clone(): WmsProjection<C> {
        return new WmsProjection(
            this.mapImageProvider.name,
            this.getScale(),
        );
    }

    serialize(): Serialization {
        return {
            type: 'wms',
            mip: this.mapImageProvider.name,
            scale: this.scale,
            dpi: this.dpi,
        };
    }

    static unserialize(serialized: Serialization): WmsProjection<Coordinate> {
        const projection = new WmsProjection(serialized.mip, serialized.scale);
        if(serialized.dpi) {
            projection.setDpi(serialized.dpi);
        }
        return projection;
    }

    attach(cutout: Cutout<any, C, any>) {
        super.attach(cutout);

        // Preload capabilities upon attaching
        this.initialize();
    }

    getMipDrawnGrid(): GridSpec | null {
        return this.mapImageProvider.getDrawnGrid();
    }

    getDpi(): number {
        return this.dpi;
    }

    setDpi(newDpi: number) {
        this.reactiveProps.dpi = this.dpi = newDpi;
    }

    getWmsUrl(coords: C[], params: WmsParams = {}) {
        return this.mapImageProvider.mapUrl(Object.assign({}, params, {
            bbox: coords[3].getX() + ',' + coords[3].getY() + ',' + coords[1].getX() + ',' + coords[1].getY(),
        }));
    }

    isWithinSuggestedScaleRange(): Promise<any> {
        // WMS 1.3.0, Section 7.2.4.6.9 Scale denominators:
        //   "(...), the common pixel size is defined to be 0,28 mm × 0,28 mm."
        // (http://portal.opengeospatial.org/files/?artifact_id=14416)

        return this.mapImageProvider.getSuggestedScaleRange().then((suggestedScaleRange) => {
            const ptPerMm = this.getDpi() / 25.4;

            const effectiveScale = this.getScale() / ptPerMm / 0.28;

            return {
                isWithin: () => {
                    if(suggestedScaleRange.min !== null && effectiveScale < suggestedScaleRange.min) {
                        return false;
                    }

                    if(suggestedScaleRange.max !== null && effectiveScale > suggestedScaleRange.max) {
                        return false;
                    }

                    return true;
                },
                minMaxScale: () => {
                    let ret = {min: null, max: null};

                    if(suggestedScaleRange.min !== null) {
                        ret.min = suggestedScaleRange.min * ptPerMm * 0.28;
                    }
                    if(suggestedScaleRange.max !== null) {
                        ret.max = suggestedScaleRange.max * ptPerMm * 0.28;
                    }

                    return ret;
                },
                minMaxDpi: () => {
                    let ret = {min: null, max: null};

                    if(suggestedScaleRange.min !== null) {
                        ret.max = this.getScale() / suggestedScaleRange.min / 0.28 * 25.4;
                    }
                    if(suggestedScaleRange.max !== null) {
                        ret.min = this.getScale() / suggestedScaleRange.max / 0.28 * 25.4;
                    }

                    return ret;
                },
            };
        });
    }

    projectToPdf(doc: jsPDF, paper: Paper, cache: Cache, progressCallback: ((evt) => void)|null): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // real mm: mm in physical world
            // paper mm: mm on paper map
            // tile: one WMS image download
            // px: pixels in WMS tile download
            // unit: unit of measurement of projection coordinate system (e.g., meters)
            const scale = this.getScale();
            const dpi = this.getDpi();
            const realMmPerPaperMm = scale;

            const realMmPerUnit = 1000;
            const targetPxPerTile = 500;
            const paperMmPerUnit = realMmPerUnit / realMmPerPaperMm;

            const pxPerPaperMm = Math.ceil(dpi / MM_PER_INCH);
            const pxPerUnit = pxPerPaperMm * paperMmPerUnit;

            const targetUnitsPerTile = targetPxPerTile / pxPerUnit;
            const targetUnitsPerTileOrder = 10 ** Math.floor(Math.log10(targetUnitsPerTile));
            const unitsPerTile = Math.round(targetUnitsPerTile / targetUnitsPerTileOrder) * targetUnitsPerTileOrder;

            const pxPerTile = Math.round(pxPerUnit * unitsPerTile);
            const paperMmPerTile = pxPerTile / pxPerPaperMm;

            const toPaperCoord = this.paperCoordinateConversion();

            const p = this.cutout.mapPolygonProjection;
            const minX = Math.floor(Math.min(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX())/unitsPerTile)*unitsPerTile;
            const maxX = Math.ceil(Math.max(p[0].getX(), p[1].getX(), p[2].getX(), p[3].getX())/unitsPerTile)*unitsPerTile;
            const minY = Math.floor(Math.min(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY())/unitsPerTile)*unitsPerTile;
            const maxY = Math.ceil(Math.max(p[0].getY(), p[1].getY(), p[2].getY(), p[3].getY())/unitsPerTile)*unitsPerTile;

            let done = 0;
            const total = Math.floor((maxX - minX) / unitsPerTile) * Math.floor((maxY - minY) / unitsPerTile);
            const self = this;

            if(total > 100) {
                if(!confirm('De te downloaden kaart is van een groot formaat. Download niet onnodig grote kaarten, dit kan een hoge belasting geven op de bronservers. Wil je doorgaan?')) {
                    reject();
                    return;
                }
            }

            return throttledParallelEachPromise(25, function*() {

                for(let x=minX; x<maxX; x+= unitsPerTile) {
                    for(let y=minY; y<maxY; y+= unitsPerTile) {
                        const imagePromise: Promise<HTMLImageElement> = self.downloadPrintImage(cache, [
                            self.coordinateSystem.make(x, y+unitsPerTile),
                            self.coordinateSystem.make(x+unitsPerTile, y+unitsPerTile),
                            self.coordinateSystem.make(x+unitsPerTile, y),
                            self.coordinateSystem.make(x, y),
                        ], {
                            width: pxPerTile.toString(),
                            height: pxPerTile.toString(),
                        });

                        const paperCoord = toPaperCoord.convert(self.coordinateSystem.make(x, y+unitsPerTile));

                        yield imagePromise.then((img) => {
                            doc.addImage(img, 'PNG', paperCoord.getX(), paperCoord.getY(), paperMmPerTile, paperMmPerTile);

                            done++;
                            if(progressCallback) {
                                progressCallback({
                                    done: done,
                                    total: total,
                                })
                            }
                        });
                    }
                }

            }).then(resolve, reject);

        });
    }

    private downloadPrintImage(cache: Cache, coords: C[], params: WmsParams = {}): Promise<HTMLImageElement> {
        const url = this.getWmsUrl(coords, params);

        return cache.fetch(url, () => {
            return new Promise((resolve, reject) => {

                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.onload = function (e) {
                    const blob = xhr.response;

                    if (blob === undefined) {
                        reject();
                        return;
                    }

                    const fr = new FileReader();
                    fr.onload = function(e) {
                        // @ts-ignore
                        resolve(fr.result);
                    };
                    fr.readAsDataURL(blob);
                };
                xhr.send(null);
            });
        }).then((result) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.src = result;
                img.onload = function () {
                    resolve(img);
                };
            });
        });
    }

}
