import Action from "./Action";
import Cutout from "../Main/Cutout";
import Paper from "../Util/Paper";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutPaperAction implements Action {

    private readonly oldPaper: Paper;

    constructor(private cutout: Cutout<any, any, any>, private newPaper: Paper) {
        this.oldPaper = this.cutout.getPaper();
    }

    public apply() {
        this.newPaper.check();
        this.cutout.setPaper(this.newPaper);
    }

    public revert() {
        this.oldPaper.check();
        this.cutout.setPaper(this.oldPaper);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            oldPaper: this.oldPaper.name,
            newPaper: this.newPaper.name,
        };
    }
}
