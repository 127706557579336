import UserError from "../Util/UserError";
import ImageGeneratingTechnique, {RouteImageFormat} from "./ImageGeneratingTechnique";
import {Options} from "roughjs/bin/core";
import {randomInt} from "../Util/Math";
import {createSvgEl} from "./Util/Svg";

type BolletjePijltjeConvention = 'incoming-relative' | 'compass';

export const BOLPI_CONVENTIONS = [
    {name: 'incoming-relative', label: 'Richting t.o.v. aankomstrichting'},
    {name: 'compass', label: 'Kompaskoers'},
];

type BolletjePijltjeConfig = {
    format: RouteImageFormat,
    transparent: boolean,
    useRoughJs: boolean,
    roughJsSeed: number,
    bolpiConvention: BolletjePijltjeConvention,
};

export default class BolletjePijltje extends ImageGeneratingTechnique<BolletjePijltjeConfig>
{
    public static readonly TECHNIQUE_NAME = 'bolletje_pijltje';
    public static readonly TECHNIQUE_TITLE = 'Bolletje Pijltje Route';

    protected readonly requiresIntermediates = true;

    protected getDefaultConfig(): BolletjePijltjeConfig {
        return {
            format: 'png',
            transparent: false,
            useRoughJs: false,
            roughJsSeed: randomInt(0, 2**31),
            bolpiConvention: 'incoming-relative',
        };
    }

    protected getRoughjsConfig(): Options {
        return {
            roughness: 0.5,
            seed: this.config.roughJsSeed,
        };
    }

    protected generateSvg(): SVGSVGElement
    {
        const intermediatesMeta = this.getIntermediatesMeta();

        const boxSize = 20; // mm
        const maxPaperWidth = 210 - 12; // mm
        const maxBoxesOnLine = Math.floor(maxPaperWidth / boxSize);

        const lines = Math.ceil(intermediatesMeta.intermediateMetas.length / maxBoxesOnLine);
        const cols = Math.min(maxBoxesOnLine, intermediatesMeta.intermediateMetas.length);

        const svg = createSvgEl('svg', {
            width: (cols * boxSize),
            height: (lines * boxSize),
            viewBox: "0 0 " + (cols * boxSize) + " " + (lines * boxSize),
        });

        for (let i = 0; i < intermediatesMeta.intermediateMetas.length; i++) {
            const intermediate = intermediatesMeta.intermediateMetas[i];

            if (this.config.bolpiConvention === 'incoming-relative' && intermediate.pathIncomingBearing === null) {
                throw new UserError('Het eerste beslispunt in de route mag voor deze routetechniek niet aan het begin van de route liggen.');
            }

            if (intermediate.pathInitialBearing === null) {
                throw new UserError('Het laatste beslispunt in de route mag voor deze routetechniek niet aan het einde van de route liggen.');
            }

            const direction = this.config.bolpiConvention === 'incoming-relative' ? intermediate.pathInitialBearing - intermediate.pathIncomingBearing : intermediate.pathInitialBearing;
            const angle = 90 - direction;

            const tipUnitX = Math.cos(angle / 180 * Math.PI);
            const tipUnitY = -Math.sin(angle / 180 * Math.PI);
            const flankDeltaDeg = 10;

            const gridX = i % maxBoxesOnLine;
            const gridY = Math.floor(i / maxBoxesOnLine);

            const cx = (gridX + 0.5) * boxSize;
            const cy = (gridY + 0.5) * boxSize;

            svg.appendChild(createSvgEl('circle', {
                cx: cx,
                cy: cy,
                r: 4,
                stroke: '#000000',
                fill: 'none',
                strokeWidth: 0.5,
            }));

            svg.appendChild(createSvgEl('line', {
                x1: cx + tipUnitX * 4,
                y1: cy + tipUnitY * 4,
                x2: cx + tipUnitX * 8,
                y2: cy + tipUnitY * 8,
                stroke: '#000000',
                strokeWidth: 0.5,
            }));

            svg.appendChild(createSvgEl('polyline', {
                points: [
                    (cx + Math.cos((angle - flankDeltaDeg) / 180 * Math.PI) * 7) + ',' + (cy - Math.sin((angle - flankDeltaDeg) / 180 * Math.PI) * 7),
                    (cx + tipUnitX * 8) + ',' + (cy + tipUnitY * 8),
                    (cx + Math.cos((angle + flankDeltaDeg) / 180 * Math.PI) * 7) + ',' + (cy - Math.sin((angle + flankDeltaDeg) / 180 * Math.PI) * 7)
                ].join(' '),
                stroke: '#000000',
                fill: 'none',
                strokeWidth: 0.5,
            }));

            const text = createSvgEl('text', {
                x: cx,
                y: cy,
                textAnchor: 'middle',
                dominantBaseline: 'central',
            });
            text.textContent = '' + intermediate.intermediateNumber;
            text.style.font = '4px sans-serif';
            svg.appendChild(text);
        }

        return svg;
    }
}
