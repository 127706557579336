<template>
  <!-- tabindex is for 'esc' key binding -->
  <div class="control-pane control-pane-search-bar d-none" id="control-pane-search-bar" tabindex="0">
    <div class="control-pane-content">
      <div class="input-group mb-3">
        <input
            type="text"
            class="form-control form-control-sm"
            id="search_bar_terms"
            placeholder="Cöordinaat, plaats of maps url..."
        />

        <button class="btn btn-sm btn-primary" type="button" v-on:click="search(true)" title="Zoeken">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </button>

        <div class="btn btn-sm btn-outline-primary" id="search-bar-geolocate" v-on:click="geolocate" title="Mijn locatie"><div>&#9678;</div></div>

        <button class="btn btn-sm btn-outline-secondary" type="button" v-on:click="closeSearchBar" title="Sluiten">&times;</button>
      </div>

      <div class="list-group">
        <div
            v-for="result of formattedCoordinateResults"
            class="list-group-item list-group-item-action cursor-pointer"
            v-on:click="showCoordinateResult(result)"
        >
          <span class="me-1">{{ result.formatted }}</span>
          <span class="small text-muted">{{ result.name }}</span>
          <span class="float-end">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>

        <div
            v-for="result of nominatimResults"
            class="list-group-item list-group-item-action cursor-pointer"
            v-on:click="showNominatimResult(result)"
        >
          <span>{{ result.display_name }}</span>
          <span class="float-end">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="align-baseline bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
        </div>

        <div
          v-if="searchedNominatim === false"
          class="list-group-item"
        >
          <i class="small text-muted">'Enter' of klik op zoek-knop om plaatsen te doorzoeken</i>
        </div>

        <div
            v-if="searchedNominatim && formattedCoordinateResults.length === 0 && nominatimResults.length === 0"
            class="list-group-item"
        >
          <i class="small text-muted">Geen resultaten gevonden.</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import $ from "jquery";
import UserInterface from "../Main/UserInterface";
import CoordinateConverter from "../Util/CoordinateConverter";
import Coordinate from "../Coordinates/Coordinate";
import CoordinateParseResult from "../Util/CoordinateParseResult";
import Container from "../Main/Container";
import Nominatim from "../Util/Nominatim";
import WGS84 from "../Coordinates/WGS84";
import {fromLonLat, toLonLat} from "ol/proj";
import {isMobile} from "../Util/functions";
import debounce from 'lodash.debounce';

export default {
  props: {
    userInterface: UserInterface,
    listenId: String,
  },
  data () {
    const self = this;

    $(() => {
      const $controlPane = $('#control-pane-search-bar');

      $('#' + this.listenId).on('click', () => {
        $controlPane.removeClass('d-none');
        $('#search_bar_terms').focus()[0].select();
      });

      $('#search_bar_terms').on('change keyup input blur', debounce((evt) => {
        if (!evt.originalEvent) {
          return;
        }

        if (evt.originalEvent.type === 'keyup') {
          if (evt.originalEvent.key === 'Enter' || evt.originalEvent.which === 13 || evt.originalEvent.code === 'Enter') {
            this.search(true);

            evt.originalEvent.preventDefault();
            return;
          }
        }

        this.search(false, evt.originalEvent.type === 'blur');
      }, 200));

      $controlPane.on('keydown', (evt) => {
        if (evt.originalEvent.which === 27 || evt.originalEvent.code === 'Escape') {
          this.closeSearchBar();

          evt.originalEvent.preventDefault();
          return;
        }
      });
    });

    return {
      coordinateResults: <CoordinateParseResult<Coordinate>[]>[],
      searchedNominatim: null,
      nominatimResults: [],
      currentSearchTerms: null,
      currentSearchedBoundingBox: null,
    };
  },
  watch: {
  },
  computed: {
    formattedCoordinateResults: function() {
      const results = [];
      for (const coordinateResult of this.coordinateResults) {
        const coordinate = coordinateResult.coordinate;
        const name = CoordinateConverter.getCoordinateSystem(coordinate.code).name;
        const preferredFormats = Container.getPreferredCoordinateFormats();
        const formats = coordinate.formats();

        let showFormatName;
        if(preferredFormats.hasOwnProperty(coordinate.code) && formats.hasOwnProperty(preferredFormats[coordinate.code])) {
          showFormatName = preferredFormats[coordinate.code];
        } else {
          showFormatName = coordinate.defaultFormat();
        }

        results.push({
          coordinate: coordinate,
          formatted: formats[showFormatName](),
          name: name,
        });
      }
      return results;
    }
  },
  methods: {
    search: function(searchNominatim: boolean, ignoreMoveOnly: boolean = false) {
      const newTerms = $('#search_bar_terms').val().trim();

      const boundingPolygon = this.userInterface.getMap().getBoundingPolygon();
      const boundingBox = [boundingPolygon[0].lng, boundingPolygon[0].lat, boundingPolygon[2].lng, boundingPolygon[2].lat];
      const boundingBoxText = boundingBox.join(',');

      if (
          newTerms === this.currentSearchTerms
          && (ignoreMoveOnly || boundingBoxText === this.currentSearchedBoundingBox)
          && (!searchNominatim || this.searchedNominatim)
      ) {
        return;
      }

      const coordinateResults: CoordinateParseResult<Coordinate>[] = [];
      for (const coordinateSystem of CoordinateConverter.getAllCoordinateSystems()) {
        const result = coordinateSystem.parse(newTerms);
        if (result !== null) {
          coordinateResults.push(result);
        }
      }

      const urlResult = this.extractCoordinateFromUrl(newTerms);
      if (urlResult) {
        coordinateResults.push(urlResult);
      }

      this.coordinateResults = coordinateResults;
      this.searchedNominatim = false;
      this.nominatimResults = [];
      this.currentSearchTerms = newTerms;
      this.currentSearchedBoundingBox = boundingBoxText;

      if (newTerms.length > 2 && searchNominatim) {
        Nominatim.search(newTerms, boundingBox).then((results: Array<any>) => {
          results.sort((a, b) => b.importance - a.importance);
          this.nominatimResults = [];

          for (const result of results) {
            if (!result.lon || !result.lat) {
              continue;
            }

            let extent = null;
            if (result.boundingbox) {
              const lowerLeft = fromLonLat([result.boundingbox[2], result.boundingbox[0]]);
              const upperRight = fromLonLat([result.boundingbox[3], result.boundingbox[1]]);
              extent = [lowerLeft[0], lowerLeft[1], upperRight[0], upperRight[1]];
            }

            const coordinate = new WGS84(parseFloat(result.lon), parseFloat(result.lat));
            this.nominatimResults.push({
              coordinate: coordinate,
              extent: extent,
              display_name: result.display_name || coordinate.formats()[coordinate.defaultFormat()],
            });
          }

          this.searchedNominatim = true;
        });
      }
    },
    extractCoordinateFromUrl: function (value: string): CoordinateParseResult<Coordinate>|null {
      const decimalRegex = /([\-]?\d+(?:\.\d+)?)/;
      const matchers = [
        {
          // Google Maps (Marker)
          regex: new RegExp('google.*/maps/place/([^/]+)', 'g'),
          lngIndex: null,
          latIndex: null,
          coordIndex: 1,
        },
        {
          // Google Maps (Position)
          regex: new RegExp('google.*/@' + decimalRegex.source + ',' + decimalRegex.source, 'g'),
          lngIndex: 2,
          latIndex: 1,
          coordIndex: null,
        },
        {
          // Bing Maps (Position)
          regex: new RegExp('bing.*maps.*cp=' + decimalRegex.source + '~' + decimalRegex.source, 'g'),
          lngIndex: 2,
          latIndex: 1,
          coordIndex: null,
        },
        {
          // GeoHack
          regex: new RegExp('geohack.*params=([\\d_]+[NS][\\d_]+[WE])', 'g'),
          lngIndex: null,
          latIndex: null,
          coordIndex: 1,
        },
        {
          // Plattekaart
          regex: new RegExp('plattekaart.*c=' + decimalRegex.source + ',' + decimalRegex.source, 'g'),
          lngIndex: 2,
          latIndex: 1,
          coordIndex: null,
        },
      ];
      const wgsSystem = CoordinateConverter.getCoordinateSystem('EPSG:4326');

      for (const matcher of matchers) {
        for (const match of decodeURI(value).matchAll(matcher.regex)) {
          if (matcher.coordIndex !== null) {
            const coordinateParseResult = wgsSystem.parse(match[matcher.coordIndex]);

            if (coordinateParseResult) {
              return coordinateParseResult;
            }
          } else {
            const coordinate = WGS84.fromStrings(match[matcher.lngIndex], match[matcher.latIndex]);

            if (coordinate) {
              return new CoordinateParseResult<WGS84>(coordinate, 'possible');
            }
          }
        }
      }
    },
    geolocate: function () {
      let loaded = false;
      setTimeout(() => {
        if (!loaded) {
          document.querySelector('#search-bar-geolocate > div').classList.add('animate-size');
        }
      }, 100);

      const loadedFn = () => {
        loaded = true;
        document.querySelector('#search-bar-geolocate > div').classList.remove('animate-size');
      };

      this.userInterface.getMap().geolocate().then((geolocation) => {
        loadedFn();

        const coordinate = toLonLat(geolocation.getPosition());
        $('#search_bar_terms').val([
            coordinate[1] < 0 ? 'S' : 'N',
            Math.abs(coordinate[1]),
            ' ',
            coordinate[0] < 0 ? 'W' : 'E',
            Math.abs(coordinate[0]),
        ].join(''));
        this.search(false);
        this.showCoordinateResult(this.formattedCoordinateResults[0]);
      }).catch(loadedFn);
    },
    closeSearchBar: function() {
      $('#search_bar_terms').blur();
      $('#control-pane-search-bar').addClass('d-none');
    },
    showCoordinateResult: function(result) {
      this.userInterface.openCoordinatePanelForCoordinate(result.coordinate, result.formatted);
      this.userInterface.getMap().fitToCoordinate(result.coordinate);

      if (isMobile()) {
        this.closeSearchBar();
      }
    },
    showNominatimResult: function(result) {
      this.userInterface.openCoordinatePanelForCoordinate(result.coordinate, result.display_name);
      if (result.extent) {
        this.userInterface.getMap().fitToExtent(result.extent);
      } else {
        this.userInterface.getMap().fitToCoordinate(result.coordinate);
      }

      if (isMobile()) {
        this.closeSearchBar();
      }
    },
  }
};
</script>

<style scoped>
#control-pane-search-bar {
  position: absolute;
  top: 10px;
  z-index: 10;
  width: 100%;
  min-height: 100%;
}
</style>
