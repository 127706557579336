import {randomInt} from "./Math";

export class RandomNumberGenerator {
    private state: number;

    private static readonly a = 37769685;
    private static readonly m = 2 ** 31;

    constructor(seed: number|null = null) {
        if (seed !== null) {
            this.state = seed;
        } else {
            this.state = randomInt(0, RandomNumberGenerator.m);
        }
    }

    max(): number {
        return RandomNumberGenerator.m - 1;
    }

    next(): number {
        // https://en.wikipedia.org/wiki/Linear_congruential_generator
        // https://www.ams.org/journals/mcom/1999-68-225/S0025-5718-99-00996-5/S0025-5718-99-00996-5.pdf
        // https://www.iro.umontreal.ca/~lecuyer/myftp/papers/latrules99Errata.pdf

        return this.state = (RandomNumberGenerator.m - 1) & (Math.imul(RandomNumberGenerator.a, this.state));
    }

    nextBetween(min: number, maxExcl: number): number {
        return Math.floor(min + this.nextFloat() * (maxExcl - min));
    }

    nextFloat(): number {
        return this.next() / RandomNumberGenerator.m;
    }
}
