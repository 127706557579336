import Action from "./Action";
import Cutout from "../Main/Cutout";
import {Serialization} from "../Main/Serializer";

export default class ChangeCutoutManualGridAction implements Action {

    private readonly oldValue: number;

    constructor(private cutout: Cutout<any, any, any>, private key: 'base_x'|'delta_x'|'mul_x'|'add_x'|'base_y'|'delta_y'|'mul_y'|'add_y', private newValue: number) {
        const grid = this.cutout.getGrid();
        this.oldValue = grid.getCustomGridSpec()[this.key];
    }

    public apply() {
        const grid = this.cutout.getGrid();
        grid.setCustomGridSpecValue(this.key, this.newValue);
    }

    public revert() {
        const grid = this.cutout.getGrid();
        grid.setCustomGridSpecValue(this.key, this.oldValue);
    }

    public merge(newAction: Action): boolean {
        if(!(newAction instanceof ChangeCutoutManualGridAction) || newAction.key !== this.key || newAction.oldValue !== this.newValue) {
            return false;
        }

        if((this.newValue > this.oldValue) !== (newAction.newValue > newAction.oldValue)) {
            return false;
        }

        this.newValue = newAction.newValue;
        this.apply();

        return true;
    }

    public serializeForDebug(): Serialization {
        return {
            cutout: this.cutout.id,
            key: this.key,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }
}
