import Action from "./Action";
import Location from "../Main/Location";
import {Serialization} from "../Main/Serializer";

export default class UpdateLocationOptionAction<Type> implements Action {

    private static readonly options = {
        draw_marker: ['getDrawMarker', 'setDrawMarker'],
        draw_name: ['getDrawName', 'setDrawName'],
    };

    private readonly oldValue: Type;

    constructor(
        private location: Location,
        private key: string,
        private newValue: Type
    ) {
        this.oldValue = this.location[UpdateLocationOptionAction.options[key][0]]();
    }

    public apply() {
        this.location[UpdateLocationOptionAction.options[this.key][1]](this.newValue);
    }

    public revert() {
        this.location[UpdateLocationOptionAction.options[this.key][1]](this.oldValue);
    }

    public merge(newAction: Action): boolean {
        return false;
    }

    public serializeForDebug(): Serialization {
        return {
            location: this.location.id,
            key: this.key,
            oldValue: this.oldValue,
            newValue: this.newValue,
        };
    }
}
